/*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v8.4.14,
* Autoprefixer: v10.4.7
* Browsers: last 4 version
*/

.NotFoundTitle {
    font-size: 6rem;
}

.notFound {
    font-size: 5rem;
    font-weight: 800;
}

.bruh {
    font-size: 4rem;
    color: $white;
    text-align: center;
}

.notFound {
    position: relative;
    top: 10rem;
}

.Btn-404-Me-Main {
    width: 18rem;
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    top: 7rem;
    padding: 1.5em 3em;
    background: $color-3;
    border: none;
    border-radius: .5rem;
    color: $color-2;
    font-size: 1rem;
    font-weight: 700;
    letter-spacing: .2rem;
    text-align: center;
    margin: 0 auto;
    outline: none;
    cursor: pointer;
    -webkit-transition: .2s ease;
    -o-transition: .2s ease;
    transition: .2s ease;
    -webkit-box-shadow: -6px -6px 14px $color-1,
        -6px -6px 10px $color-1,
        6px 6px 8px rgba(255, 255, 255, .075),
        6px 6px 10px rgba(0, 0, 0, .15);
    box-shadow: -6px -6px 14px $color-1,
        -6px -6px 10px $color-1,
        6px 6px 8px rgba(255, 255, 255, .075),
        6px 6px 10px rgba(0, 0, 0, .15);
}

.Btn-404-Me-Main:hover {
    color: $white;
    background: $color-1;
    -webkit-box-shadow: -2px -2px 6px $color-3,
        -2px -2px 4px $color-3,
        2px 2px 2px rgba(255, 255, 255, .05),
        2px 2px 4px rgba(0, 0, 0, .1);
    box-shadow: -2px -2px 6px $color-3,
        -2px -2px 4px $color-3,
        2px 2px 2px rgba(255, 255, 255, .05),
        2px 2px 4px rgba(0, 0, 0, .1);
}

.Btn-J404Me-Main:active {
    -webkit-box-shadow: inset -2px -2px 6px $color-3,
        inset -2px -2px 4px $color-3,
        inset 2px 2px 2px rgba(255, 255, 255, .075),
        inset 2px 2px 4px rgba(0, 0, 0, .15);
    box-shadow: inset -2px -2px 6px $color-3,
        inset -2px -2px 4px $color-3,
        inset 2px 2px 2px rgba(255, 255, 255, .075),
        inset 2px 2px 4px rgba(0, 0, 0, .15);
}