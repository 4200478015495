/*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v8.4.14,
* Autoprefixer: v10.4.7
* Browsers: last 4 version
*/

$BREAKPOINT-SMALL-MOBILE-PORT: "only screen and (orientation: portrait) and (min-width: 300px)";
$BREAKPOINT-SMART-MOBILE-PORT: "only screen and (orientation: portrait) and (min-width: 400px)";

$BREAKPOINT-TABLET-PORT: "only screen and (orientation: portrait) and (min-width: 600px)";

$BREAKPOINT-SMALL-MOBILE-LAND: "only screen and (orientation: landscape) and (min-width: 600px) and (max-height: 312px)";
$BREAKPOINT-SMART-MOBILE-LAND: "only screen and (orientation: landscape) and (min-width: 600px) and (max-height: 412px)";

$BREAKPOINT-TABLET-LAND: "only screen and (orientation: landscape) and (min-width: 975px)";
$BREAKPOINT-LAPTOP: "only screen and (min-width: 1200px)";
$BREAKPOINT-DESK: "only screen and (min-width: 1600px)";

@media #{$BREAKPOINT-SMALL-MOBILE-PORT} {
    .GalerieTitle{
        color: $color-3;
    }

    .Gallery {
        left: 0%;
        background-color: $transparent-1;
        -webkit-column-count: 1;
        -moz-column-count: 1;
        column-count: 1;
        -webkit-column-width: 33%;
        -moz-column-width: 33%;
        column-width: 33%;
        padding: 0 12px;
    
        }
    
        .illu {
            min-width: 11rem;
            max-width: 100%;
        }
    
        .gallery .illu {
            -webkit-transition: all 350ms ease;
            -o-transition: all 350ms ease;
            transition: all 350ms ease;
            cursor: pointer;
            margin-bottom: 12px;
        }
    
        .illu:hover {
            -webkit-filter: contrast(120%) opacity(0.9);
            filter: contrast(120%) opacity(0.9);
        }
    
        .ContentGalerie {
            position: relative;
            top: 5rem;
        }

        .model {
            width: 100%;
            height: 100vh;
            position: fixed;
            top: -15%;
            left: 5%;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-pack: center;
            -ms-flex-pack: center;
            justify-content: center;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            background-color: rgba(0, 0, 0, 0.441);
            -webkit-transition: opacity 0.4s ease, visibility 0.4s ease, -webkit-transform 0.4s ease-in-out;
            transition: opacity 0.4s ease, visibility 0.4s ease, -webkit-transform 0.4s ease-in-out;
            -o-transition: opacity 0.4s ease, visibility 0.4s ease, transform 0.4s ease-in-out;
            transition: opacity 0.4s ease, visibility 0.4s ease, transform 0.4s ease-in-out;
            transition: opacity 0.4s ease, visibility 0.4s ease, transform 0.4s ease-in-out, -webkit-transform 0.4s ease-in-out;
            visibility: hidden;
            opacity: 0;
            -webkit-transform: scale(0);
            -ms-transform: scale(0);
            transform: scale(0);
            overflow: hidden;
            z-index: 999;
        }

        .model.open {
            visibility: visible;
            opacity: 1;
            -webkit-transform: scale(1);
            -ms-transform: scale(1);
            transform: scale(1);
            transition: 0.4s ease;
        }

        .model .ImgShow {
            position: relative;
            left: 3rem !important;
            width: 20rem;
            height: auto;
            max-height: 100%;
            display: block;
            line-height: 0;
            -webkit-box-sizing: border-box;
            box-sizing: border-box;
            padding: 20px 0px 20px;
            margin: 0 auto;

        }

        .model.open .btnClose {
            position: fixed;
            top: 20rem;
            right: 3rem;
            width: 2rem;
            height: 2rem;
            padding: 5px;
            background-color: $transparent-2;
            border-radius: 0.5rem 0.5rem 0.5rem 0.5rem;
            cursor: pointer;
        }
        
        .model.open .btnClose:hover {
            background: $color-2;
            border-radius: 5rem 5rem 5rem 5rem;
            border: 0.2rem solid #a4cbf2;
            -webkit-box-shadow: 0px 0px 50px -5px #50BBFD;
            box-shadow: 0px 0px 50px 5px #50BBFD;
            transform: scale(1.4) rotate(180deg);
            transition: 0.4s ease;
        }

        .contact-btn-Page-Galerie {
            position: relative;
            top: 8rem;
        }

        .Btn-Contact-Me-Page-Galerie {

            position: relative;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-pack: center;
            -ms-flex-pack: center;
            justify-content: center;
            top: 6rem;
            left: -7rem;
            width: 10rem;
            padding: 1.5em 1em;
            background: $color-1;
            border: none;
            border-radius: .5rem;
            color: $color-2;
            font-size: 1rem;
            font-weight: 700;
            letter-spacing: .2rem;
            text-align: center;
            margin: 0 Auto;
            outline: none;
            cursor: pointer;
            -webkit-transition: .2s ease;
            -o-transition: .2s ease;
            transition: .2s ease;
            -webkit-box-shadow: -3px -3px 14px $color-3,
                -3px 3px 10px $color-3,
                3px 3px 8px rgba(255, 255, 255, .075),
                3px 3px 10px rgba(0, 0, 0, .15);
            box-shadow: -3px -3px 14px $color-3,
                -3px -3px 8px $color-3,
                3px 3px 8px rgba(255, 255, 255, .075),
                3px 3px 10px rgba(0, 0, 0, .15);
            transition: 0.4s ease;
            z-index: 10030;
        }

        .Btn-Contact-Me-Page-Galerie:hover {
            color: $white;
            background: $color-3;
            transform: scale(1.1);
            -webkit-box-shadow: -2px -2px 14px $color-1,
                2px -2px 10px $color-1,
                2px 2px 8px rgba(255, 255, 255, .075),
                2px 2px 10px rgba(0, 0, 0, .15);
            box-shadow: -2px -2px 14px $color-1,
                -2px -2px 8px $color-1,
                2px 2px 8px rgba(255, 255, 255, .075),
                2px 2px 10px rgba(0, 0, 0, .15);
            transition: 0.4s ease-in-out;
            -webkit-transition: .4s ease-in-out;
            -o-transition: .4s ease-in-out;
        }

        .Btn-Contact-Me-Page-Galerie:active {
            -webkit-box-shadow: -2px -2px 14px $color-1,
                -2px -2px 10px $color-1,
                2px 2px 8px rgba(255, 255, 255, .075),
                2px 2px 10px rgba(0, 0, 0, .15);
            box-shadow: -2px -2px 14px $color-1,
                -2px -2px 8px $color-1,
                2px 2px 8px rgba(255, 255, 255, .075),
                2px 2px 10px rgba(0, 0, 0, .15);
            transition: 0.4s ease;
        }
}

@media #{$BREAKPOINT-SMART-MOBILE-PORT} {
    .GalerieTitle {
        color: $color-3;
    }

    .Gallery {
        left: 0%;
        background-color: $transparent-1;
        -webkit-column-count: 1;
        -moz-column-count: 1;
        column-count: 1;
        -webkit-column-width: 33%;
        -moz-column-width: 33%;
        column-width: 33%;
        padding: 0 12px;

    }

    .illu {
        min-width: 11rem;
        max-width: 100%;
    }

    .gallery .illu {
        -webkit-transition: all 350ms ease;
        -o-transition: all 350ms ease;
        transition: all 350ms ease;
        cursor: pointer;
        margin-bottom: 12px;
    }

    .illu:hover {
        -webkit-filter: contrast(120%) opacity(0.9);
        filter: contrast(120%) opacity(0.9);
    }

    .ContentGalerie {
        position: relative;
        top: 5rem;
    }

    .model {
        width: 100%;
        height: 100vh;
        position: fixed;
        top: -10% !important;
        left: 5%;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        background-color: rgba(0, 0, 0, 0.441);
        -webkit-transition: opacity 0.4s ease, visibility 0.4s ease, -webkit-transform 0.4s ease-in-out;
        transition: opacity 0.4s ease, visibility 0.4s ease, -webkit-transform 0.4s ease-in-out;
        -o-transition: opacity 0.4s ease, visibility 0.4s ease, transform 0.4s ease-in-out;
        transition: opacity 0.4s ease, visibility 0.4s ease, transform 0.4s ease-in-out;
        transition: opacity 0.4s ease, visibility 0.4s ease, transform 0.4s ease-in-out, -webkit-transform 0.4s ease-in-out;
        visibility: hidden;
        opacity: 0;
        -webkit-transform: scale(0);
        -ms-transform: scale(0);
        transform: scale(0);
        overflow: hidden;
        z-index: 999;
    }

    .model.open {
        visibility: visible;
        opacity: 1;
        -webkit-transform: scale(1);
        -ms-transform: scale(1);
        transform: scale(1);
        transition: 0.4s ease;
    }

    .model .ImgShow {
        position: relative;
        left: 10%;
        width: 25rem;
        height: auto;
        max-height: 100%;
        display: block;
        line-height: 0;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        padding: 20px 0px 20px;
        margin: 0 auto;

    }

    .model.open .btnClose {
        position: fixed;
        top: 20rem;
        right: 3rem;
        width: 2rem;
        height: 2rem;
        padding: 5px;
        background-color: $transparent-2;
        border-radius: 0.5rem 0.5rem 0.5rem 0.5rem;
        cursor: pointer;
    }

    .model.open .btnClose:hover {
        background: $color-2;
        border-radius: 5rem 5rem 5rem 5rem;
        border: 0.2rem solid #a4cbf2;
        -webkit-box-shadow: 0px 0px 50px -5px #50BBFD;
        box-shadow: 0px 0px 50px 5px #50BBFD;
        transform: scale(1.4) rotate(180deg);
        transition: 0.4s ease;
    }

    .contact-btn-Page-Galerie {
        position: relative;
        top: 8rem;
    }

    .Btn-Contact-Me-Page-Galerie {

        position: relative;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        top: 6.5rem !important;
        left: -7.5rem !important;
        width: 10rem;
        padding: 1.5em 1em;
        background: $color-1;
        border: none;
        border-radius: .5rem;
        color: $color-2;
        font-size: 1rem;
        font-weight: 700;
        letter-spacing: .2rem;
        text-align: center;
        margin: 0 Auto;
        outline: none;
        cursor: pointer;
        -webkit-transition: .2s ease;
        -o-transition: .2s ease;
        transition: .2s ease;
        -webkit-box-shadow: -3px -3px 14px $color-3,
            -3px 3px 10px $color-3,
            3px 3px 8px rgba(255, 255, 255, .075),
            3px 3px 10px rgba(0, 0, 0, .15);
        box-shadow: -3px -3px 14px $color-3,
            -3px -3px 8px $color-3,
            3px 3px 8px rgba(255, 255, 255, .075),
            3px 3px 10px rgba(0, 0, 0, .15);
        transition: 0.4s ease;
        z-index: 10030;
    }

    .Btn-Contact-Me-Page-Galerie:hover {
        color: $white;
        background: $color-3;
        transform: scale(1.1);
        -webkit-box-shadow: -2px -2px 14px $color-1,
            2px -2px 10px $color-1,
            2px 2px 8px rgba(255, 255, 255, .075),
            2px 2px 10px rgba(0, 0, 0, .15);
        box-shadow: -2px -2px 14px $color-1,
            -2px -2px 8px $color-1,
            2px 2px 8px rgba(255, 255, 255, .075),
            2px 2px 10px rgba(0, 0, 0, .15);
        transition: 0.4s ease-in-out;
        -webkit-transition: .4s ease-in-out;
        -o-transition: .4s ease-in-out;
    }

    .Btn-Contact-Me-Page-Galerie:active {
        -webkit-box-shadow: -2px -2px 14px $color-1,
            -2px -2px 10px $color-1,
            2px 2px 8px rgba(255, 255, 255, .075),
            2px 2px 10px rgba(0, 0, 0, .15);
        box-shadow: -2px -2px 14px $color-1,
            -2px -2px 8px $color-1,
            2px 2px 8px rgba(255, 255, 255, .075),
            2px 2px 10px rgba(0, 0, 0, .15);
        transition: 0.4s ease;
    }
}

@media #{$BREAKPOINT-SMART-MOBILE-LAND} {
    .GalerieTitle {
        color: $color-3;
    }

    .Gallery {
        left: 0%;
        background-color: $transparent-1;
        -webkit-column-count: 1;
        -moz-column-count: 1;
        column-count: 1;
        -webkit-column-width: 33%;
        -moz-column-width: 33%;
        column-width: 33%;
        padding: 0 12px;

    }

    .illu {
        min-width: 11rem;
        max-width: 100%;
    }

    .gallery .illu {
        -webkit-transition: all 350ms ease;
        -o-transition: all 350ms ease;
        transition: all 350ms ease;
        cursor: pointer;
        margin-bottom: 12px;
    }

    .illu:hover {
        -webkit-filter: contrast(120%) opacity(0.9);
        filter: contrast(120%) opacity(0.9);
    }

    .ContentGalerie {
        position: relative;
        top: 5rem;
    }

    .model {
        width: 100%;
        height: 100vh;
        position: fixed;
        top: 0%;
        left: 5%;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        background-color: rgba(0, 0, 0, 0.441);
        -webkit-transition: opacity 0.4s ease, visibility 0.4s ease, -webkit-transform 0.4s ease-in-out;
        transition: opacity 0.4s ease, visibility 0.4s ease, -webkit-transform 0.4s ease-in-out;
        -o-transition: opacity 0.4s ease, visibility 0.4s ease, transform 0.4s ease-in-out;
        transition: opacity 0.4s ease, visibility 0.4s ease, transform 0.4s ease-in-out;
        transition: opacity 0.4s ease, visibility 0.4s ease, transform 0.4s ease-in-out, -webkit-transform 0.4s ease-in-out;
        visibility: hidden;
        opacity: 0;
        -webkit-transform: scale(0);
        -ms-transform: scale(0);
        transform: scale(0);
        overflow: hidden;
        z-index: 999;
    }

    .model.open {
        visibility: visible;
        opacity: 1;
        -webkit-transform: scale(1);
        -ms-transform: scale(1);
        transform: scale(1);
        transition: 0.4s ease;
    }

    .model .ImgShow {
        width: auto;
        height: auto;
        max-height: 100%;
        display: block;
        line-height: 0;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        padding: 20px 0px 20px;
        margin: 0 auto;

    }

    .model.open .btnClose {
        position: fixed;
        top: 4rem;
        right: 8rem;
        width: 2rem;
        height: 2rem;
        padding: 5px;
        background-color: $transparent-2;
        border-radius: 0.5rem 0.5rem 0.5rem 0.5rem;
        cursor: pointer;
    }

    .model.open .btnClose:hover {
        background: $color-2;
        border-radius: 5rem 5rem 5rem 5rem;
        border: 0.2rem solid #a4cbf2;
        -webkit-box-shadow: 0px 0px 50px -5px #50BBFD;
        box-shadow: 0px 0px 50px 5px #50BBFD;
        transform: scale(1.4) rotate(180deg);
        transition: 0.4s ease;
    }

    .contact-btn-Page-Galerie {
        position: relative;
        top: 8rem;
    }

    .Btn-Contact-Me-Page-Galerie {
        position: relative;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        top: 0rem;
        left: -10.5rem;
        width: 10rem;
        padding: 1.5em 1em;
        background: $color-1;
        border: none;
        border-radius: .5rem;
        color: $color-2;
        font-size: 1rem;
        font-weight: 700;
        letter-spacing: .2rem;
        text-align: center;
        margin: 0 Auto;
        outline: none;
        cursor: pointer;
        -webkit-transition: .2s ease;
        -o-transition: .2s ease;
        transition: .2s ease;
        -webkit-box-shadow: -3px -3px 14px $color-3,
            -3px 3px 10px $color-3,
            3px 3px 8px rgba(255, 255, 255, .075),
            3px 3px 10px rgba(0, 0, 0, .15);
        box-shadow: -3px -3px 14px $color-3,
            -3px -3px 8px $color-3,
            3px 3px 8px rgba(255, 255, 255, .075),
            3px 3px 10px rgba(0, 0, 0, .15);
        transition: 0.4s ease;
        z-index: 10030;
    }

    .Btn-Contact-Me-Page-Galerie:hover {
        color: $white;
        background: $color-3;
        transform: scale(1.1);
        -webkit-box-shadow: -2px -2px 14px $color-1,
            2px -2px 10px $color-1,
            2px 2px 8px rgba(255, 255, 255, .075),
            2px 2px 10px rgba(0, 0, 0, .15);
        box-shadow: -2px -2px 14px $color-1,
            -2px -2px 8px $color-1,
            2px 2px 8px rgba(255, 255, 255, .075),
            2px 2px 10px rgba(0, 0, 0, .15);
        transition: 0.4s ease-in-out;
        -webkit-transition: .4s ease-in-out;
        -o-transition: .4s ease-in-out;
    }

    .Btn-Contact-Me-Page-Galerie:active {
        -webkit-box-shadow: -2px -2px 14px $color-1,
            -2px -2px 10px $color-1,
            2px 2px 8px rgba(255, 255, 255, .075),
            2px 2px 10px rgba(0, 0, 0, .15);
        box-shadow: -2px -2px 14px $color-1,
            -2px -2px 8px $color-1,
            2px 2px 8px rgba(255, 255, 255, .075),
            2px 2px 10px rgba(0, 0, 0, .15);
        transition: 0.4s ease;
    }
}

@media #{$BREAKPOINT-SMALL-MOBILE-LAND} {
    .GalerieTitle {
        color: $color-3;
    }

    .Gallery {
        left: 0%;
        background-color: $transparent-1;
        -webkit-column-count: 1;
        -moz-column-count: 1;
        column-count: 1;
        -webkit-column-width: 33%;
        -moz-column-width: 33%;
        column-width: 33%;
        padding: 0 12px;

    }

    .illu {
        min-width: 11rem;
        max-width: 100%;
    }

    .gallery .illu {
        -webkit-transition: all 350ms ease;
        -o-transition: all 350ms ease;
        transition: all 350ms ease;
        cursor: pointer;
        margin-bottom: 12px;
    }

    .illu:hover {
        -webkit-filter: contrast(120%) opacity(0.9);
        filter: contrast(120%) opacity(0.9);
    }

    .ContentGalerie {
        position: relative;
        top: 5rem;
    }

    .model {
        width: 100%;
        height: 100vh;
        position: fixed;
        top: 0%;
        left: 5%;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        background-color: rgba(0, 0, 0, 0.441);
        -webkit-transition: opacity 0.4s ease, visibility 0.4s ease, -webkit-transform 0.4s ease-in-out;
        transition: opacity 0.4s ease, visibility 0.4s ease, -webkit-transform 0.4s ease-in-out;
        -o-transition: opacity 0.4s ease, visibility 0.4s ease, transform 0.4s ease-in-out;
        transition: opacity 0.4s ease, visibility 0.4s ease, transform 0.4s ease-in-out;
        transition: opacity 0.4s ease, visibility 0.4s ease, transform 0.4s ease-in-out, -webkit-transform 0.4s ease-in-out;
        visibility: hidden;
        opacity: 0;
        -webkit-transform: scale(0);
        -ms-transform: scale(0);
        transform: scale(0);
        overflow: hidden;
        z-index: 999;
    }

    .model.open {
        visibility: visible;
        opacity: 1;
        -webkit-transform: scale(1);
        -ms-transform: scale(1);
        transform: scale(1);
        transition: 0.4s ease;
    }

    .model .ImgShow {
        width: auto;
        height: auto;
        max-height: 100%;
        display: block;
        line-height: 0;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        padding: 20px 0px 20px;
        margin: 0 auto;

    }

    .model.open .btnClose {
        position: fixed;
        top: 2rem;
        right: 8rem;
        width: 2rem;
        height: 2rem;
        padding: 5px;
        background-color: $transparent-2;
        border-radius: 0.5rem 0.5rem 0.5rem 0.5rem;
        cursor: pointer;
    }

    .model.open .btnClose:hover {
        background: $color-2;
        border-radius: 5rem 5rem 5rem 5rem;
        border: 0.2rem solid #a4cbf2;
        -webkit-box-shadow: 0px 0px 50px -5px #50BBFD;
        box-shadow: 0px 0px 50px 5px #50BBFD;
        transform: scale(1.4) rotate(180deg);
        transition: 0.4s ease;
    }

    .contact-btn-Page-Galerie {
        position: relative;
        top: 8rem;
    }

    .Btn-Contact-Me-Page-Galerie {

        position: relative;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        top: -2rem;
        left: -11rem;
        width: 10rem;
        padding: 1.5em 1em;
        background: $color-1;
        border: none;
        border-radius: .5rem;
        color: $color-2;
        font-size: 1rem;
        font-weight: 700;
        letter-spacing: .2rem;
        text-align: center;
        margin: 0 Auto;
        outline: none;
        cursor: pointer;
        -webkit-transition: .2s ease;
        -o-transition: .2s ease;
        transition: .2s ease;
        -webkit-box-shadow: -3px -3px 14px $color-3,
            -3px 3px 10px $color-3,
            3px 3px 8px rgba(255, 255, 255, .075),
            3px 3px 10px rgba(0, 0, 0, .15);
        box-shadow: -3px -3px 14px $color-3,
            -3px -3px 8px $color-3,
            3px 3px 8px rgba(255, 255, 255, .075),
            3px 3px 10px rgba(0, 0, 0, .15);
        transition: 0.4s ease;
        z-index: 10030;
    }

    .Btn-Contact-Me-Page-Galerie:hover {
        color: $white;
        background: $color-3;
        transform: scale(1.1);
        -webkit-box-shadow: -2px -2px 14px $color-1,
            2px -2px 10px $color-1,
            2px 2px 8px rgba(255, 255, 255, .075),
            2px 2px 10px rgba(0, 0, 0, .15);
        box-shadow: -2px -2px 14px $color-1,
            -2px -2px 8px $color-1,
            2px 2px 8px rgba(255, 255, 255, .075),
            2px 2px 10px rgba(0, 0, 0, .15);
        transition: 0.4s ease-in-out;
        -webkit-transition: .4s ease-in-out;
        -o-transition: .4s ease-in-out;
    }

    .Btn-Contact-Me-Page-Galerie:active {
        -webkit-box-shadow: -2px -2px 14px $color-1,
            -2px -2px 10px $color-1,
            2px 2px 8px rgba(255, 255, 255, .075),
            2px 2px 10px rgba(0, 0, 0, .15);
        box-shadow: -2px -2px 14px $color-1,
            -2px -2px 8px $color-1,
            2px 2px 8px rgba(255, 255, 255, .075),
            2px 2px 10px rgba(0, 0, 0, .15);
        transition: 0.4s ease;
    }
}

@media #{$BREAKPOINT-TABLET-PORT} {
    .GalerieTitle {
        color: $color-3;
    }

    .Gallery {
        left: 0%;
        background-color: $transparent-1;
        -webkit-column-count: 2;
        -moz-column-count: 2;
        column-count: 2;
        -webkit-column-width: 33%;
        -moz-column-width: 33%;
        column-width: 33%;
        padding: 0 12px;

    }

    .illu {
        min-width: 18rem;
        max-width: 100%;
    }

    .gallery .illu {
        -webkit-transition: all 350ms ease;
        -o-transition: all 350ms ease;
        transition: all 350ms ease;
        cursor: pointer;
        margin-bottom: 12px;
    }

    .illu:hover {
        -webkit-filter: contrast(120%) opacity(0.9);
        filter: contrast(120%) opacity(0.9);
    }

    .ContentGalerie {
        position: relative;
        top: 5rem;
    }

    .model {
        width: 100%;
        height: 100vh;
        position: fixed;
        top: 0%;
        left: 5%;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        background-color: rgba(0, 0, 0, 0.441);
        -webkit-transition: opacity 0.4s ease, visibility 0.4s ease, -webkit-transform 0.4s ease-in-out;
        transition: opacity 0.4s ease, visibility 0.4s ease, -webkit-transform 0.4s ease-in-out;
        -o-transition: opacity 0.4s ease, visibility 0.4s ease, transform 0.4s ease-in-out;
        transition: opacity 0.4s ease, visibility 0.4s ease, transform 0.4s ease-in-out;
        transition: opacity 0.4s ease, visibility 0.4s ease, transform 0.4s ease-in-out, -webkit-transform 0.4s ease-in-out;
        visibility: hidden;
        opacity: 0;
        -webkit-transform: scale(0);
        -ms-transform: scale(0);
        transform: scale(0);
        overflow: hidden;
        z-index: 999;
    }

    .model.open {
        visibility: visible;
        opacity: 1;
        -webkit-transform: scale(1);
        -ms-transform: scale(1);
        transform: scale(1);
        transition: 0.4s ease;
    }

    .model img {
        width: auto;
        max-width: 100%;
        height: auto;
        max-height: 100%;
        display: block;
        line-height: 0;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        padding: 20px 0px 20px;
        margin: 0 auto;

    }

    .model.open .btnClose {
        position: fixed;
        top: 12rem;
        right: 3rem;
        width: 2rem;
        height: 2rem;
        padding: 5px;
        background-color: $transparent-2;
        border-radius: 0.5rem 0.5rem 0.5rem 0.5rem;
        cursor: pointer;
    }
    
    .model.open .btnClose:hover {
        background: $color-2;
        border-radius: 5rem 5rem 5rem 5rem;
        border: 0.2rem solid #a4cbf2;
        -webkit-box-shadow: 0px 0px 50px -5px #50BBFD;
        box-shadow: 0px 0px 50px 5px #50BBFD;
        transform: scale(1.4) rotate(180deg);
        transition: 0.4s ease;
    }

    .contact-btn-Page-Galerie {
        position: relative;
        top: 8rem;
    }

    .Btn-Contact-Me-Page-Galerie {

        position: relative;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        top: 10rem;
        left: -20rem;
        width: 10rem;
        padding: 1.5em 1em;
        background: $color-1;
        border: none;
        border-radius: .5rem;
        color: $color-2;
        font-size: 1rem;
        font-weight: 700;
        letter-spacing: .2rem;
        text-align: center;
        margin: 0 Auto;
        outline: none;
        cursor: pointer;
        -webkit-transition: .2s ease;
        -o-transition: .2s ease;
        transition: .2s ease;
        -webkit-box-shadow: -3px -3px 14px $color-3,
            -3px 3px 10px $color-3,
            3px 3px 8px rgba(255, 255, 255, .075),
            3px 3px 10px rgba(0, 0, 0, .15);
        box-shadow: -3px -3px 14px $color-3,
            -3px -3px 8px $color-3,
            3px 3px 8px rgba(255, 255, 255, .075),
            3px 3px 10px rgba(0, 0, 0, .15);
        transition: 0.4s ease;
        z-index: 10030;
    }

    .Btn-Contact-Me-Page-Galerie:hover {
        color: $white;
        background: $color-3;
        transform: scale(1.1);
        -webkit-box-shadow: -2px -2px 14px $color-1,
            2px -2px 10px $color-1,
            2px 2px 8px rgba(255, 255, 255, .075),
            2px 2px 10px rgba(0, 0, 0, .15);
        box-shadow: -2px -2px 14px $color-1,
            -2px -2px 8px $color-1,
            2px 2px 8px rgba(255, 255, 255, .075),
            2px 2px 10px rgba(0, 0, 0, .15);
        transition: 0.4s ease-in-out;
        -webkit-transition: .4s ease-in-out;
        -o-transition: .4s ease-in-out;
    }

    .Btn-Contact-Me-Page-Galerie:active {
        -webkit-box-shadow: -2px -2px 14px $color-1,
            -2px -2px 10px $color-1,
            2px 2px 8px rgba(255, 255, 255, .075),
            2px 2px 10px rgba(0, 0, 0, .15);
        box-shadow: -2px -2px 14px $color-1,
            -2px -2px 8px $color-1,
            2px 2px 8px rgba(255, 255, 255, .075),
            2px 2px 10px rgba(0, 0, 0, .15);
        transition: 0.4s ease;
    }
}

@media #{$BREAKPOINT-TABLET-LAND} {
    .GalerieTitle {
        color: $color-3;
    }

    .Gallery {
        left: 0%;
        background-color: $transparent-1;
        -webkit-column-count: 3;
        -moz-column-count: 3;
        column-count: 3;
        -webkit-column-width: 33%;
        -moz-column-width: 33%;
        column-width: 33%;
        padding: 0 12px;

    }

    .illu {
        min-width: 18rem;
        max-width: 100%;
    }

    .gallery .illu {
        -webkit-transition: all 350ms ease;
        -o-transition: all 350ms ease;
        transition: all 350ms ease;
        cursor: pointer;
        margin-bottom: 12px;
    }

    .illu:hover {
        -webkit-filter: contrast(120%) opacity(0.9);
        filter: contrast(120%) opacity(0.9);
    }

    .ContentGalerie {
        position: relative;
        top: 5rem;
    }

    .model {
        width: 100%;
        height: 100vh;
        position: fixed;
        top: 0%;
        left: 3%;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        background-color: rgba(0, 0, 0, 0.441);
        -webkit-transition: opacity 0.4s ease, visibility 0.4s ease, -webkit-transform 0.4s ease-in-out;
        transition: opacity 0.4s ease, visibility 0.4s ease, -webkit-transform 0.4s ease-in-out;
        -o-transition: opacity 0.4s ease, visibility 0.4s ease, transform 0.4s ease-in-out;
        transition: opacity 0.4s ease, visibility 0.4s ease, transform 0.4s ease-in-out;
        transition: opacity 0.4s ease, visibility 0.4s ease, transform 0.4s ease-in-out, -webkit-transform 0.4s ease-in-out;
        visibility: hidden;
        opacity: 0;
        -webkit-transform: scale(0);
        -ms-transform: scale(0);
        transform: scale(0);
        overflow: hidden;
        z-index: 999;
    }

    .model.open {
        visibility: visible;
        opacity: 1;
        -webkit-transform: scale(1);
        -ms-transform: scale(1);
        transform: scale(1);
        transition: 0.4s ease;
    }

    .model img {
        width: auto;
        max-width: 100%;
        height: auto;
        max-height: 100%;
        display: block;
        line-height: 0;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        padding: 20px 0px 20px;
        margin: 0 auto;

    }

    .model.open .btnClose {
        position: fixed;
        top: 12rem;
        right: 5rem;
        width: 2rem;
        height: 2rem;
        padding: 5px;
        background-color: $transparent-2;
        border-radius: 0.5rem 0.5rem 0.5rem 0.5rem;
        cursor: pointer;
        }
    
    .model.open .btnClose:hover {
        background: $color-2;
        border-radius: 5rem 5rem 5rem 5rem;
        border: 0.2rem solid #a4cbf2;
        -webkit-box-shadow: 0px 0px 50px -5px #50BBFD;
        box-shadow: 0px 0px 50px 5px #50BBFD;
        transform: scale(1.4) rotate(180deg);
        transition: 0.4s ease;
    }
    
    .contact-btn-Page-Galerie {
        position: relative;
        top: 8rem;
    }

    .Btn-Contact-Me-Page-Galerie {

        position: relative;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        top: 10rem;
        left: -30rem;
        width: 10rem;
        padding: 1.5em 1em;
        background: $color-1;
        border: none;
        border-radius: .5rem;
        color: $color-2;
        font-size: 1rem;
        font-weight: 700;
        letter-spacing: .2rem;
        text-align: center;
        margin: 0 Auto;
        outline: none;
        cursor: pointer;
        -webkit-transition: .2s ease;
        -o-transition: .2s ease;
        transition: .2s ease;
        -webkit-box-shadow: -3px -3px 14px $color-3,
            -3px 3px 10px $color-3,
            3px 3px 8px rgba(255, 255, 255, .075),
            3px 3px 10px rgba(0, 0, 0, .15);
        box-shadow: -3px -3px 14px $color-3,
            -3px -3px 8px $color-3,
            3px 3px 8px rgba(255, 255, 255, .075),
            3px 3px 10px rgba(0, 0, 0, .15);
        transition: 0.4s ease;
        z-index: 10030;
    }

    .Btn-Contact-Me-Page-Galerie:hover {
        color: $white;
        background: $color-3;
        transform: scale(1.1);
        -webkit-box-shadow: -2px -2px 14px $color-1,
            2px -2px 10px $color-1,
            2px 2px 8px rgba(255, 255, 255, .075),
            2px 2px 10px rgba(0, 0, 0, .15);
        box-shadow: -2px -2px 14px $color-1,
            -2px -2px 8px $color-1,
            2px 2px 8px rgba(255, 255, 255, .075),
            2px 2px 10px rgba(0, 0, 0, .15);
        transition: 0.4s ease-in-out;
        -webkit-transition: .4s ease-in-out;
        -o-transition: .4s ease-in-out;
    }

    .Btn-Contact-Me-Page-Galerie:active {
        -webkit-box-shadow: -2px -2px 14px $color-1,
            -2px -2px 10px $color-1,
            2px 2px 8px rgba(255, 255, 255, .075),
            2px 2px 10px rgba(0, 0, 0, .15);
        box-shadow: -2px -2px 14px $color-1,
            -2px -2px 8px $color-1,
            2px 2px 8px rgba(255, 255, 255, .075),
            2px 2px 10px rgba(0, 0, 0, .15);
        transition: 0.4s ease;
    }
}

@media #{$BREAKPOINT-DESK} {
    .GalerieTitle {
        color: $color-3;
    }

    .Gallery {
        left: 0%;
        background-color: $transparent-1;
        -webkit-column-count: 3;
        -moz-column-count: 3;
        column-count: 3;
        -webkit-column-width: 33%;
        -moz-column-width: 33%;
        column-width: 33%;
        padding: 0 12px;

    }

    .illu {
        min-width: 18rem;
        max-width: 100%;
    }

    .gallery .illu {
        -webkit-transition: all 350ms ease;
        -o-transition: all 350ms ease;
        transition: all 350ms ease;
        cursor: pointer;
        margin-bottom: 12px;
    }

    .illu:hover {
        -webkit-filter: contrast(120%) opacity(0.9);
        filter: contrast(120%) opacity(0.9);
    }

    .ContentGalerie {
        position: relative;
        top: 5rem;
    }

    .model {
        width: 100%;
        height: 100vh;
        position: fixed;
        top: 0%;
        left: 0;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        background-color: rgba(0, 0, 0, 0.441);
        -webkit-transition: opacity 0.4s ease, visibility 0.4s ease, -webkit-transform 0.4s ease-in-out;
        transition: opacity 0.4s ease, visibility 0.4s ease, -webkit-transform 0.4s ease-in-out;
        -o-transition: opacity 0.4s ease, visibility 0.4s ease, transform 0.4s ease-in-out;
        transition: opacity 0.4s ease, visibility 0.4s ease, transform 0.4s ease-in-out;
        transition: opacity 0.4s ease, visibility 0.4s ease, transform 0.4s ease-in-out, -webkit-transform 0.4s ease-in-out;
        visibility: hidden;
        opacity: 0;
        -webkit-transform: scale(0);
        -ms-transform: scale(0);
        transform: scale(0);
        overflow: hidden;
        z-index: 999;
    }

    .model.open {
        visibility: visible;
        opacity: 1;
        -webkit-transform: scale(1);
        -ms-transform: scale(1);
        transform: scale(1);
        transition: 0.4s ease;
    }

    .model img {
        width: auto;
        max-width: 100%;
        height: auto;
        max-height: 100%;
        display: block;
        line-height: 0;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        padding: 20px 0px 20px;
        margin: 0 auto;

    }

    .model.open .btnClose {
        position: fixed;
        top: 12rem;
        right: 10rem;
        width: 3rem;
        height: 3rem;
        padding: 5px;
        background-color: $transparent-2;
        border-radius: 0.5rem 0.5rem 0.5rem 0.5rem;
        cursor: pointer;
    }

    .model.open .btnClose:hover {
        background: $color-2;
        border-radius: 5rem 5rem 5rem 5rem;
        border: 0.2rem solid #a4cbf2;
        -webkit-box-shadow: 0px 0px 50px -5px #50BBFD;
        box-shadow: 0px 0px 50px 5px #50BBFD;
        transform: scale(1.4) rotate(180deg);
        transition: 0.4s ease;
    }
    .contact-btn-Page-Galerie {
        position: relative;
        top: 8rem;
    }

    .Btn-Contact-Me-Page-Galerie {

        position: relative;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        top: 10rem;
        left: -40rem;
        width: 10rem;
        padding: 1.5em 1em;
        background: $color-1;
        border: none;
        border-radius: .5rem;
        color: $color-2;
        font-size: 1rem;
        font-weight: 700;
        letter-spacing: .2rem;
        text-align: center;
        margin: 0 Auto;
        outline: none;
        cursor: pointer;
        -webkit-transition: .2s ease;
        -o-transition: .2s ease;
        transition: .2s ease;
        -webkit-box-shadow: -3px -3px 14px $color-3,
            -3px 3px 10px $color-3,
            3px 3px 8px rgba(255, 255, 255, .075),
            3px 3px 10px rgba(0, 0, 0, .15);
        box-shadow: -3px -3px 14px $color-3,
            -3px -3px 8px $color-3,
            3px 3px 8px rgba(255, 255, 255, .075),
            3px 3px 10px rgba(0, 0, 0, .15);
        transition: 0.4s ease;
        z-index: 10030;
    }

    .Btn-Contact-Me-Page-Galerie:hover {
        color: $white;
        background: $color-3;
        transform: scale(1.1);
        -webkit-box-shadow: -2px -2px 14px $color-1,
            2px -2px 10px $color-1,
            2px 2px 8px rgba(255, 255, 255, .075),
            2px 2px 10px rgba(0, 0, 0, .15);
        box-shadow: -2px -2px 14px $color-1,
            -2px -2px 8px $color-1,
            2px 2px 8px rgba(255, 255, 255, .075),
            2px 2px 10px rgba(0, 0, 0, .15);
        transition: 0.4s ease-in-out;
        -webkit-transition: .4s ease-in-out;
        -o-transition: .4s ease-in-out;
    }

    .Btn-Contact-Me-Page-Galerie:active {
        -webkit-box-shadow: -2px -2px 14px $color-1,
            -2px -2px 10px $color-1,
            2px 2px 8px rgba(255, 255, 255, .075),
            2px 2px 10px rgba(0, 0, 0, .15);
        box-shadow: -2px -2px 14px $color-1,
            -2px -2px 8px $color-1,
            2px 2px 8px rgba(255, 255, 255, .075),
            2px 2px 10px rgba(0, 0, 0, .15);
        transition: 0.4s ease;
    }
}
