/*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v8.4.14,
* Autoprefixer: v10.4.7
* Browsers: last 4 version
*/

H2 {
    color: $color-3;
    position: relative;
    top: 8rem;
}
h3 {
    text-align: center;
    color: $color-3;
}

p {
    text-align: center;
    color: $white;
}

.ContentMentions {
    position: relative;
    top: 10rem;
    background: $transparent-1;
}