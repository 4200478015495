/*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v8.4.14,
* Autoprefixer: v10.4.7
* Browsers: last 4 version
*/

$BREAKPOINT-MOBILE-LAND: "only screen and (min-width: 200px)";
$BREAKPOINT-TABLET: "only screen and (min-width: 600px)";
$BREAKPOINT-TABLET-LAND: "only screen and (min-width: 975px)";
$BREAKPOINT-DESK: "only screen and (min-width: 1280px)";

@media #{$BREAKPOINT-MOBILE-LAND} {

/*         START Nav Onglet        */
    .container {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        position: relative;
        top: 0rem;
        margin-top: 0.5rem;
        width: 100%;
        height: 100%;
        margin: 100px auto 0;
        word-break: break-all;
        left: 0rem;
    }

    .bloc-tabs {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-pack: distribute;
        justify-content: space-around;
        height: 2.5rem;
        width: 100%;
    }

    .tabs {
        padding: 2px;
        text-align: center;
        width: 2rem;
        color: $color-1;
        cursor: pointer;
        -webkit-box-sizing: content-box;
        box-sizing: content-box;
        position: relative;
        outline: none;
        font-size: 1rem;
    }

    .tabs:hover {
        font-size: 1rem;
        width: 2rem;
        border-radius: 50% 0% 0% 0% / 50% 0% 50% 0%;
        -webkit-transform: translateY(-10px);
        -ms-transform: translateY(-10px);
        transform: translateY(-10px);
        -webkit-transition: 0.4s ease;
        -o-transition: 0.4s ease;
        transition: 0.4s ease;
        -webkit-animation-duration: 0.4s ease;
        animation-duration: 0.4s ease;

    }

    .active-tabs {
        background: $color-3;
        border: 0.1rem solid $color-1;
        width: 4rem;
        font-size: 1.2rem;
        color: $color-2;
        border-radius: 50% 50% 0% 0% / 50% 50% 10% 10%;
        -webkit-transition: 0.4s ease;
        -o-transition: 0.4s ease;
        transition: 0.4s ease;
    }

    .active-tabs:hover {
        background: $transparent-3;
        border: 0.1rem solid $color-3;
        width: 4rem;
        font-size: 0.6rem;
        color: $white;
        border-radius: 50% 50% 50% 50% / 50% 50% 20% 20%;
        -webkit-transition: 0.4s ease;
        -o-transition: 0.4s ease;
        transition: 0.4s ease;
    }

    .active-tabs::before {
        content: "";
        display: block;
        position: absolute;
        top: -4px;
        left: 50%;
        -webkit-transform: translateX(-50%);
        -ms-transform: translateX(-50%);
        transform: translateX(-50%);
        width: calc(100% + 2px);
        height: 1px;
        border-radius: 50% 0% 50% 0% / 50% 0% 50% 0%;
    }

    .content-tabs {
        -webkit-box-flex: 1;
        -ms-flex-positive: 1;
        flex-grow: 1;
    }

    /*         END Nav Onglet        */
    /*         START Contenu onglet        */

    .content {
        border-left: 0.01rem solid $color-1;
        border-top: 0.01rem solid $color-1;
        padding: 20px;
        width: 100%;
        height: 100%;

        /*Margin-right de quoi pourquoi /?\*/
        margin-right: 1rem;
        /* */

        display: none;
        color: $white;
        background-position: center bottom;
        background-size: 100% auto;
        background-repeat: no-repeat;
        position: relative;
        top: 0rem;
    }

    .Title-Onglet {
        position: relative;
        top: 0rem;
        font-size: 1.4rem;
        padding: 0px 0 5px 0px;
    }

    .trait1 {
        width: 75px;
        height: 2px;
        margin-bottom: 5px;
        color: $color-1;
    }

    .trait2 {
        width: 25px;
        height: 2px;
        margin-bottom: 5px;
        color: $color-3;
    }

    .trait1:hover {
        width: 90rem;
        -webkit-transition: 0.5s ease-in-out;
        -o-transition: 0.5s ease-in-out;
        transition: 0.5s ease-in-out;
    }

    .trait2:hover {
        width: 90rem;
        -webkit-transition: 0.5s ease-in-out;
        -o-transition: 0.5s ease-in-out;
        transition: 0.5s ease-in-out;
    }

    .active-content {
        display: block;
        background: $transparent-1;
    }

    /* START ONGLET PORTRAIT */

    .ImgPortait {
        float: none;
        width: 22rem;
        margin: 0 auto;
    }

    .pitchPortrait {
        width: auto;
        position: relative;
        top: 2rem;
        text-align: center;
        font-size: 1.4rem;
        line-height: 3rem;
        padding: 0rem;
    }

    .pitchPortraitLast {
        width: auto;
        position: relative;
        top: 2rem;
        text-align: center;
        font-size: 1.4rem;
        line-height: 3rem;
        padding: 0rem;
    }

    .pitchBleu {
        color: $color-3;
        font-size: 1.4rem;
    }

    .pitchPortraitNb {
        margin-top: 2rem;
        color: #50bbfd;
        font-size: 1.2rem;
        line-height: 2rem;
        position: relative;
        text-align: center;
        top: 5rem;
        width: 80%;
        margin: 0 auto;
    }

    .ContentLinkPortrait {
        position: relative;
        top: 2rem;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        line-height: 2rem;
    }

    .LinkPortrait {
        color: $color-3;
    }

    .LinkPortrait:hover {
        color: $color-1;
        -webkit-transform: scale(1.1);
        -ms-transform: scale(1.1);
        transform: scale(1.1);
        -webkit-transition: 0.4s ease;
        -o-transition: 0.4s ease;
        transition: 0.4s ease;
        ;
    }

    .ThumbSiteSection {
        position: relative;
        top: 6rem;
        height: 100%;
    }

    .siteWeb {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -ms-flex-pack: distribute;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        position: relative;
        top: 5rem;
    }

    .ThumbContent {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        margin-bottom: 5rem;
    }

    .ThumbImgSite {
        width: 10rem;
    }

    .TitleThumb {
        position: relative;
        top: -12rem;
        font-size: 1.2rem;
        color: $color-3;
        margin-top: 2rem;
        margin-bottom: 1rem;
    }

    .ParaThumb {
        position: relative;
        top: -1rem;
        width: 15rem;
        margin: 0 auto;
        text-align: center;
        line-height: 2rem;
        margin-bottom: 1rem;
        font-size: 1rem;
    }

    .Linkthumb {
        color: $color-3;
        font-size: 1.2rem;
    }

    .Linkthumb:hover {
        color: $color-1;
        -webkit-transform: scale(1.1);
        -ms-transform: scale(1.1);
        transform: scale(1.1);
        -webkit-transition: 0.4s ease;
        -o-transition: 0.4s ease;
        transition: 0.4s ease;
    }

    .contact-btn {
        position: relative;
        top: 5rem;
    }

    .Btn-Contact-Me {
        width: 18rem !important;
        position: relative;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        top: -2rem;
        left: 0rem;
        padding: 1.5em 3em;
        background: #a4cbf2;
        border: none;
        border-radius: 0.5rem;
        color: $color-2;
        font-size: 1rem;
        font-weight: 700;
        letter-spacing: 0.2rem;
        text-align: center;
        margin: 0 Auto;
        outline: none;
        cursor: pointer;
        -webkit-transition: 0.2s ease;
        -o-transition: 0.2s ease;
        transition: 0.2s ease;
        -webkit-box-shadow: -5px -5px 14px $color-3,
            -5px 5px 10px $color-1,
            5px 5px 8px rgba(255, 255, 255, .075),
            5px 5px 1px rgba(0, 0, 0, .15);
        box-shadow: -6px -6px 14px $color-3,
            -5px -5px 10px $color-1,
            5px 5px 8px rgba(255, 255, 255, .075),
            5px 5px 1px rgba(0, 0, 0, .15);
    }

    .Btn-Contact-Me:hover {
        color: $white;
        background: $color-3;
        transform: scale(1.1);
        -webkit-box-shadow: -5px -5px 6px $color-1,
            -5px -5px 4px $color-1,
            5px 5px 2px rgba(255, 255, 255, .05),
            5px 5px 4px rgba(0, 0, 0, .1);
        box-shadow: -5px -5px 6px $color-1,
            -2px -2px 4px $color-1,
            5px 5px 4px rgba(255, 255, 255, .05),
            5px 5px 4px rgba(0, 0, 0, .1);
        transition: 0.4s ease-in-out;
        -webkit-transition: .4s ease-in-out;
        -o-transition: .4s ease-in-out;
    }

    .Btn-Contact-Me:active {
        -webkit-box-shadow: inset -2px -2px 6px $color-1,
            inset -2px -2px 4px $color-1,
            inset 2px 2px 2px rgba(255, 255, 255, .075),
            inset 2px 2px 4px rgba(0, 0, 0, .15);
        box-shadow: inset -2px -2px 6px $color-1,
            inset -2px -2px 4px $color-1,
            inset 2px 2px 2px rgba(255, 255, 255, .075),
            inset 2px 2px 4px rgba(0, 0, 0, .15);
    }

    .Btn-Contact-Me-Main {
        position: relative;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        top: 7rem;
        width: 10rem;
        padding: 1.5em 1em;
        background: $color-1;
        border: none;
        border-radius: .5rem;
        color: $color-2;
        font-size: 1rem;
        font-weight: 700;
        letter-spacing: .2rem;
        text-align: center;
        margin: 0 Auto;
        outline: none;
        cursor: pointer;
        -webkit-transition: .2s ease;
        -o-transition: .2s ease;
        transition: .2s ease;
        -webkit-box-shadow: -3px -3px 14px $color-1,
            -3px -3px 10px $color-1,
            3px 3px 8px rgba(255, 255, 255, .075),
            3px 3px 10px rgba(0, 0, 0, .15);
        box-shadow: -3px -3px 14px $color-1,
            -3px -3px 10px $color-1,
            3px 3px 8px rgba(255, 255, 255, .075),
            3px 3px 10px rgba(0, 0, 0, .15);
    }

    .Btn-Contact-Me-Main:hover {
        background: $color-3;
        transform: scale(1.1);
        -webkit-box-shadow: -2px -2px 6px $color-1,
            -2px -2px 4px $color-1,
            2px 2px 2px rgba(255, 255, 255, .05),
            2px 2px 4px rgba(0, 0, 0, .1);
        box-shadow: -2px -2px 6px $color-1,
            -2px -2px 4px $color-1,
            2px 2px 2px rgba(255, 255, 255, .05),
            2px 2px 4px rgba(0, 0, 0, .1);
            transition: 0.4s ease-in-out;
            -webkit-transition: .4s ease-in-out;
            -o-transition: .4s ease-in-out;
    }

    .Btn-Contact-Me-Main:active {
        -webkit-box-shadow: inset -2px -2px 6px $color-1,
            inset -2px -2px 4px $color-1,
            inset 2px 2px 2px rgba(255, 255, 255, .075),
            inset 2px 2px 4px rgba(0, 0, 0, .15);
        box-shadow: inset -2px -2px 6px $color-1,
            inset -2px -2px 4px $color-1,
            inset 2px 2px 2px rgba(255, 255, 255, .075),
            inset 2px 2px 4px rgba(0, 0, 0, .15);
    }


    /* END ONGLET PORTRAIT */

    /* START ONGLET ILLUSTRATIONS */

    .Software {
        position: relative;
        top: 4rem;
        list-style-type: none;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        text-align: center;

        li {
            margin: 1rem;
        }

        .LinkSoftware {
            color: $color-3;
        }
    }

    .contact-btn-galerie {
        position: relative;
        top: 10rem;
    }

    .Btn-Contact-Me-Galerie {
        width: 10rem;
        position: relative;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        top: 0rem;
        left: 0rem;
        padding: 1.5em 1em;
        background: $color-3;
        border: none;
        border-radius: 0.5rem;
        color: $color-2;
        font-size: 1rem;
        font-weight: 700;
        letter-spacing: 0.2rem;
        text-align: center;
        margin: 0 Auto;
        outline: none;
        cursor: pointer;
        -webkit-transition: 0.2s ease;
        -o-transition: 0.2s ease;
        transition: 0.2s ease;
        -webkit-box-shadow: -3px -3px 14px $color-1,
            -3px -3px 10px $color-3,
            3px 3px 8px rgba(255, 255, 255, .075),
            3px 3px 1px rgba(0, 0, 0, .15);
        box-shadow: -3px -3px 14px $color-1,
            -3px -3px 10px $color-3,
            3px 3px 8px rgba(255, 255, 255, .075),
            3px 3px 1px rgba(0, 0, 0, .15);
    }

    .Btn-Contact-Me-Galerie:hover {
        color: $white;
        background: $color-1;
        transform: scale(1.1);
        -webkit-box-shadow: -2px -2px 6px $color-3,
            -2px -2px 4px $color-3,
            2px 2px 2px rgba(255, 255, 255, .05),
            2px 2px 4px rgba(0, 0, 0, .1);
        box-shadow: -2px -2px 6px $color-3,
            -2px -2px 4px $color-3,
            2px 2px 2px rgba(255, 255, 255, .05),
            2px 2px 4px rgba(0, 0, 0, .1);
        transition: 0.4s ease-in-out;
        -webkit-transition: .4s ease-in-out;
        -o-transition: .4s ease-in-out;
    }

    .Btn-Contact-Me-Galerie:active {
        -webkit-box-shadow: inset -2px -2px 6px $color-3,
            inset -2px -2px 4px $color-3,
            inset 2px 2px 2px rgba(255, 255, 255, .075),
            inset 2px 2px 4px rgba(0, 0, 0, .15);
        box-shadow: inset -2px -2px 6px $color-3,
            inset -2px -2px 4px $color-3,
            inset 2px 2px 2px rgba(255, 255, 255, .075),
            inset 2px 2px 4px rgba(0, 0, 0, .15);
    }

    .Btn-Galerie-Me-Main {
        position: relative;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        top: 0rem;
        width: 10rem;
        padding: 1.5em 1em;
        background: $color-1;
        border: none;
        border-radius: .5rem;
        color: $color-2;
        font-size: 1rem;
        font-weight: 700;
        letter-spacing: .2rem;
        text-align: center;
        margin: 0 Auto;
        outline: none;
        cursor: pointer;
        -webkit-transition: .2s ease;
        -o-transition: .2s ease;
        transition: .2s ease;
        -webkit-box-shadow: -3px -3px 14px $color-1,
            -3px -3px 10px $color-1,
            3px 3px 8px rgba(255, 255, 255, .075),
            3px 3px 10px rgba(0, 0, 0, .15);
        box-shadow: -3px -3px 14px $color-1,
            -3px -3px 10px $color-1,
            3px 3px 8px rgba(255, 255, 255, .075),
            3px 3px 10px rgba(0, 0, 0, .15);
    }

    .Btn-Galerie-Me-Main:hover {
        color: $white;
        background: $color-3;
        transform: scale(1.1);
        -webkit-box-shadow: -2px -2px 6px $color-1,
            -2px -2px 4px $color-1,
            2px 2px 2px rgba(255, 255, 255, .05),
            2px 2px 4px rgba(0, 0, 0, .1);
        box-shadow: -2px -2px 6px $color-1,
            -2px -2px 4px $color-1,
            2px 2px 2px rgba(255, 255, 255, .05),
            2px 2px 4px rgba(0, 0, 0, .1);
        transition: 0.4s ease-in-out;
        -webkit-transition: .4s ease-in-out;
        -o-transition: .4s ease-in-out;
    }

    .Btn-Galerie-Me-Main:active {
        -webkit-box-shadow: inset -2px -2px 6px $color-1,
            inset -2px -2px 4px $color-1,
            inset 2px 2px 2px rgba(255, 255, 255, .075),
            inset 2px 2px 4px rgba(0, 0, 0, .15);
        box-shadow: inset -2px -2px 6px $color-1,
            inset -2px -2px 4px $color-1,
            inset 2px 2px 2px rgba(255, 255, 255, .075),
            inset 2px 2px 4px rgba(0, 0, 0, .15);
    }

    /* END ONGLET ILLUSTRATIONS */

    /* START ONGLET VIDEOS */

    // iframe:first-child {
    //     width: 80% !important;
    //     height: 100% !important;
    // }

    // iframe:last-child {
    //     width: 55% !important;
    //     height: 55% !important;
    // }

    .WhatUseVideos {
        position: relative;
        top: 0rem;
    }

    .contact-btn-Videos {
        position: relative;
        top: 10rem;
    }

    .Btn-Contact-Me-Videos {
        width: 10rem;
        position: relative;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        top: 0rem;
        left: 0rem;
        padding: 1.5em 1em;
        background: $color-3;
        border: none;
        border-radius: 0.5rem;
        color: $color-2;
        font-size: 1rem;
        font-weight: 700;
        letter-spacing: 0.2rem;
        text-align: center;
        margin: 0 Auto;
        outline: none;
        cursor: pointer;
        -webkit-transition: 0.2s ease;
        -o-transition: 0.2s ease;
        transition: 0.2s ease;
        -webkit-box-shadow: -3px -3px 14px $color-1,
            -3px -3px 10px $color-3,
            3px 3px 8px rgba(255, 255, 255, .075),
            3px 3px 1px rgba(0, 0, 0, .15);
        box-shadow: -3px -3px 14px $color-1,
            -3px -3px 10px $color-3,
            3px 3px 8px rgba(255, 255, 255, .075),
            3px 3px 1px rgba(0, 0, 0, .15);
    }

    .Btn-Contact-Me-Videos:hover {
        color: $white;
        background: $color-1;
        transform: scale(1.1);
        -webkit-box-shadow: -2px -2px 6px $color-3,
            -2px -2px 4px $color-3,
            2px 2px 2px rgba(255, 255, 255, .05),
            2px 2px 4px rgba(0, 0, 0, .1);
        box-shadow: -2px -2px 6px $color-3,
            -2px -2px 4px $color-3,
            2px 2px 2px rgba(255, 255, 255, .05),
            2px 2px 4px rgba(0, 0, 0, .1);
        transition: 0.4s ease-in-out;
        -webkit-transition: .4s ease-in-out;
        -o-transition: .4s ease-in-out;
    }

    .Btn-Contact-Me-Videos:active {
        -webkit-box-shadow: inset -2px -2px 6px $color-3,
            inset -2px -2px 4px $color-3,
            inset 2px 2px 2px rgba(255, 255, 255, .075),
            inset 2px 2px 4px rgba(0, 0, 0, .15);
        box-shadow: inset -2px -2px 6px $color-3,
            inset -2px -2px 4px $color-3,
            inset 2px 2px 2px rgba(255, 255, 255, .075),
            inset 2px 2px 4px rgba(0, 0, 0, .15);
    }

    .Btn-Videos-Me-Main {
        position: relative;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        top: 0rem;
        width: 10rem;
        padding: 1.5em 1em;
        background: $color-1;
        border: none;
        border-radius: .5rem;
        color: $color-2;
        font-size: 1rem;
        font-weight: 700;
        letter-spacing: .2rem;
        text-align: center;
        margin: 0 Auto;
        outline: none;
        cursor: pointer;
        -webkit-transition: .2s ease;
        -o-transition: .2s ease;
        transition: .2s ease;
        -webkit-box-shadow: -3px -3px 14px $color-1,
            -3px -3px 10px $color-1,
            3px 3px 8px rgba(255, 255, 255, .075),
            3px 3px 10px rgba(0, 0, 0, .15);
        box-shadow: -6px -6px 14px $color-1,
            -3px -3px 10px $color-1,
            3px 3px 8px rgba(255, 255, 255, .075),
            3px 3px 10px rgba(0, 0, 0, .15);
    }

    .Btn-Videos-Me-Main:hover {
        color: $white;
        background: $color-3;
        transform: scale(1.1);
        -webkit-box-shadow: -2px -2px 6px $color-1,
            -2px -2px 4px $color-1,
            2px 2px 2px rgba(255, 255, 255, .05),
            2px 2px 4px rgba(0, 0, 0, .1);
        box-shadow: -2px -2px 6px $color-1,
            -2px -2px 4px $color-1,
            2px 2px 2px rgba(255, 255, 255, .05),
            2px 2px 4px rgba(0, 0, 0, .1);
        transition: 0.4s ease-in-out;
        -webkit-transition: .4s ease-in-out;
        -o-transition: .4s ease-in-out;
    }

    .Btn-Videos-Me-Main:active {
        -webkit-box-shadow: inset -2px -2px 6px $color-1,
            inset -2px -2px 4px $color-1,
            inset 2px 2px 2px rgba(255, 255, 255, .075),
            inset 2px 2px 4px rgba(0, 0, 0, .15);
        box-shadow: inset -2px -2px 6px $color-1,
            inset -2px -2px 4px $color-1,
            inset 2px 2px 2px rgba(255, 255, 255, .075),
            inset 2px 2px 4px rgba(0, 0, 0, .15);
    }



    /* END ONGLET VIDEOS */

    /* START ONGLET JAMS */

    .overlay__text {
        top: 1rem !important;
        height: 5rem;
    }

    .WhatUsejams {
        position: relative;
        top: -6rem;
    }

    .pitchSoft {
        position: relative;
        top: 2rem;
    }

    .Magix {
        color: $color-3  !important;
    }

    .Magix:hover {
        color: $color-1;
        -webkit-transform: scale(1.1);
        -ms-transform: scale(1.1);
        transform: scale(1.1);
        -webkit-transition: 0.4s ease;
        -o-transition: 0.4s ease;
        transition: 0.4s ease;
        ;
    }

    .contact-btn-Videos {
        position: relative;
        top: 10rem;
    }

    .Btn-Contact-Me-Jams {
        width: 10rem;
        position: relative;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        top: 10rem;
        left: 0rem;
        padding: 1.5em 1em;
        background: $color-3;
        border: none;
        border-radius: 0.5rem;
        color: $color-2;
        font-size: 1rem;
        font-weight: 700;
        letter-spacing: 0.2rem;
        text-align: center;
        margin: 0 Auto;
        outline: none;
        cursor: pointer;
        -webkit-transition: 0.2s ease;
        -o-transition: 0.2s ease;
        transition: 0.2s ease;
        -webkit-box-shadow: -3px -3px 14px $color-1,
            -3px -3px 10px $color-3,
            3px 3px 8px rgba(255, 255, 255, .075),
            3px 3px 1px rgba(0, 0, 0, .15);
        box-shadow: -3px -3px 14px $color-1,
            -3px -3px 10px $color-3,
            3px 3px 8px rgba(255, 255, 255, .075),
            3px 3px 1px rgba(0, 0, 0, .15);
    }

    .Btn-Contact-Me-Jams:hover {
        color: $white;
        background: $color-1;
        transform: scale(1.1);
        -webkit-box-shadow: -2px -2px 6px $color-3,
            -2px -2px 4px $color-3,
            2px 2px 2px rgba(255, 255, 255, .05),
            2px 2px 4px rgba(0, 0, 0, .1);
        box-shadow: -2px -2px 6px $color-3,
            -2px -2px 4px $color-3,
            2px 2px 2px rgba(255, 255, 255, .05),
            2px 2px 4px rgba(0, 0, 0, .1);
        transition: 0.4s ease-in-out;
        -webkit-transition: .4s ease-in-out;
        -o-transition: .4s ease-in-out;
    }

    .Btn-Contact-Me-Jams:active {
        -webkit-box-shadow: inset -2px -2px 6px $color-3,
            inset -2px -2px 4px $color-3,
            inset 2px 2px 2px rgba(255, 255, 255, .075),
            inset 2px 2px 4px rgba(0, 0, 0, .15);
        box-shadow: inset -2px -2px 6px $color-3,
            inset -2px -2px 4px $color-3,
            inset 2px 2px 2px rgba(255, 255, 255, .075),
            inset 2px 2px 4px rgba(0, 0, 0, .15);
    }

    .Btn-Jams-Me-Main {
        position: relative;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        top: 0rem;
        width: 10rem;
        padding: 1.5em 1em;
        background: $color-1;
        border: none;
        border-radius: .5rem;
        color: $color-2;
        font-size: 1rem;
        font-weight: 700;
        letter-spacing: .2rem;
        text-align: center;
        margin: 0 Auto;
        outline: none;
        cursor: pointer;
        -webkit-transition: .2s ease;
        -o-transition: .2s ease;
        transition: .2s ease;
        -webkit-box-shadow: -3px -3px 14px $color-1,
            -3px -3px 10px $color-1,
            px 3px 8px rgba(255, 255, 255, .075),
            3px 3px 10px rgba(0, 0, 0, .15);
        box-shadow: -3px -3px 14px $color-1,
            -3px -3px 10px $color-1,
            3px 3px 8px rgba(255, 255, 255, .075),
            3px 3px 10px rgba(0, 0, 0, .15);
    }

    .Btn-Jams-Me-Main:hover {
        color: $white;
        background: $color-3;
        transform: scale(1.1);
        -webkit-box-shadow: -2px -2px 6px $color-1,
            -2px -2px 4px $color-1,
            2px 2px 2px rgba(255, 255, 255, .05),
            2px 2px 4px rgba(0, 0, 0, .1);
        box-shadow: -2px -2px 6px $color-1,
            -2px -2px 4px $color-1,
            2px 2px 2px rgba(255, 255, 255, .05),
            2px 2px 4px rgba(0, 0, 0, .1);
        transition: 0.4s ease-in-out;
        -webkit-transition: .4s ease-in-out;
        -o-transition: .4s ease-in-out;
    }

    .Btn-Jams-Me-Main:active {
        -webkit-box-shadow: inset -2px -2px 6px $color-1,
            inset -2px -2px 4px $color-1,
            inset 2px 2px 2px rgba(255, 255, 255, .075),
            inset 2px 2px 4px rgba(0, 0, 0, .15);
        box-shadow: inset -2px -2px 6px $color-1,
            inset -2px -2px 4px $color-1,
            inset 2px 2px 2px rgba(255, 255, 255, .075),
            inset 2px 2px 4px rgba(0, 0, 0, .15);
    }

    /* END ONGLET JAMS */
   
}

@media #{$BREAKPOINT-TABLET} {

/*         START Nav Onglet        */
    .container {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        position: relative;
        top: 0rem;
        margin-top: 0.5rem;
        width: 100%;
        height: 100%;
        margin: 100px auto 0;
        word-break: break-all;
        left: 0rem;
    }

    .bloc-tabs {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-pack: distribute;
        justify-content: space-around;
        height: 2.5rem;
        width: 100%;
    }

    .tabs {
        padding: 2px;
        text-align: center;
        width: 5rem;
        color: $color-1;
        cursor: pointer;
        -webkit-box-sizing: content-box;
        box-sizing: content-box;
        position: relative;
        outline: none;
        font-size: 1rem;
    }

    .tabs:hover {
        font-size: 1rem;
        width: 5rem;
        border-radius: 50% 0% 0% 0% / 50% 0% 50% 0%;
        -webkit-transform: translateY(-10px);
        -ms-transform: translateY(-10px);
        transform: translateY(-10px);
        -webkit-transition: 0.4s ease;
        -o-transition: 0.4s ease;
        transition: 0.4s ease;
        -webkit-animation-duration: 0.4s ease;
        animation-duration: 0.4s ease;

    }

    .active-tabs {
        background: $color-3;
        border: 0.1rem solid $color-1;
        width: 4rem;
        font-size: 1.2rem;
        color: $color-2;
        border-radius: 50% 50% 0% 0% / 50% 50% 10% 10%;
        -webkit-transition: 0.4s ease;
        -o-transition: 0.4s ease;
        transition: 0.4s ease;
    }

    .active-tabs:hover {
        background: $transparent-3;
        border: 0.1rem solid $color-3;
        width: 4rem;
        font-size: 0.6rem;
        color: $white;
        border-radius: 50% 50% 50% 50% / 50% 50% 20% 20%;
        -webkit-transition: 0.4s ease;
        -o-transition: 0.4s ease;
        transition: 0.4s ease;
    }

    .active-tabs::before {
        content: "";
        display: block;
        position: absolute;
        top: -4px;
        left: 50%;
        -webkit-transform: translateX(-50%);
        -ms-transform: translateX(-50%);
        transform: translateX(-50%);
        width: calc(100% + 2px);
        height: 1px;
        border-radius: 50% 0% 50% 0% / 50% 0% 50% 0%;
    }

    .content-tabs {
        -webkit-box-flex: 1;
        -ms-flex-positive: 1;
        flex-grow: 1;
    }

    /*         END Nav Onglet        */
    /*         START Contenu onglet        */

    .content {
        border-left: 0.01rem solid $color-1;
        border-top: 0.01rem solid $color-1;
        padding: 20px;
        width: 100%;
        height: 100%;
        background: $transparent-1;

        /*Margin-right de quoi pourquoi /?\*/
        margin-right: 1rem;
        /* */

        display: none;
        color: $white;
        background-position: center bottom;
        background-size: 100% auto;
        background-repeat: no-repeat;
        position: relative;
        top: 0rem;
    }

    .Title-Onglet {
        position: relative;
        top: 0rem;
        font-size: 1.4rem;
        padding: 0px 0 5px 0px;
    }

    .trait1 {
        width: 150px;
        height: 4px;
        margin-bottom: 5px;
        color: $color-1;
        background: $color-1;
    }

    .trait2 {
        width: 50px;
        height: 2px;
        margin-bottom: 5px;
        color: $color-3;
    }

    .trait1:hover {
        width: 90rem;
        -webkit-transition: 0.5s ease-in-out;
        -o-transition: 0.5s ease-in-out;
        transition: 0.5s ease-in-out;
    }

    .trait2:hover {
        width: 90rem;
        -webkit-transition: 0.5s ease-in-out;
        -o-transition: 0.5s ease-in-out;
        transition: 0.5s ease-in-out;
    }

    .active-content {
        display: block;
        background: $transparent-1;
    }

    /* START ONGLET PORTRAIT */

    .ImgPortait {
        float: left;
        width: 18rem;
        margin: 0 auto;
        padding: 1rem;
    }

    .pitchPortrait {
        position: relative;
        top: 2rem;
        text-align: Center;
        font-size: 1.4rem;
        line-height: 3rem;
        padding: 1rem;
        width: 100%;
    }

    .pitchPortraitLast {
        position: relative;
        top: 2rem;
        text-align: center;
        font-size: 1.4rem;
        line-height: 3rem;
        padding: 1rem;
        width: 100%;
    }

    .pitchBleu {
        color: $color-3;
        font-size: 1.6rem;
    }

    .pitchPortraitNb {
        margin-top: 2rem;
        color: #50bbfd;
        font-size: 1.2rem;
        line-height: 2rem;
        position: relative;
        top: 5rem;
        width: 80%;
        margin: 0 auto;
    }

    .ContentLinkPortrait {
        position: relative;
        top: 2rem;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        line-height: 2rem;
    }

    .LinkPortrait {
        color: $color-3;
    }

    .LinkPortrait:hover {
        color: $color-1;
        -webkit-transform: scale(1.1);
        -ms-transform: scale(1.1);
        transform: scale(1.1);
        -webkit-transition: 0.4s ease;
        -o-transition: 0.4s ease;
        transition: 0.4s ease;
        ;
    }

    .ThumbSiteSection {
        position: relative;
        top: 8rem;
    }

    .siteWeb {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -ms-flex-pack: distribute;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        position: relative;
        top: 5rem;
    }

    .ThumbContent {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        margin-bottom: 5rem;
    }

    .ThumbImgSite {
        width: 10rem;
    }

    .TitleThumb {
        position: relative;
        top: -12rem;
        font-size: 1.2rem;
        color: $color-3;
        margin-top: 2rem;
        margin-bottom: 1rem;
    }

    .ParaThumb {
        position: relative;
        top: -1rem;
        width: auto;
        text-align: left;
        line-height: 2rem;
        margin-bottom: 1rem;
        font-size: 1rem;
    }

    .Linkthumb {
        color: $color-3;
        font-size: 1.2rem;
    }

    .Linkthumb:hover {
        color: $color-1;
        -webkit-transform: scale(1.1);
        -ms-transform: scale(1.1);
        transform: scale(1.1);
        -webkit-transition: 0.4s ease;
        -o-transition: 0.4s ease;
        transition: 0.4s ease;
    }

    .contact-btn {
        position: relative;
        top: 5rem;
    }

    .Btn-Contact-Me {
        width: 18rem !important;
        position: relative;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        top: -2rem;
        left: 0rem;
        padding: 1.5em 3em;
        background: #a4cbf2;
        border: none;
        border-radius: 0.5rem;
        color: $color-2;
        font-size: 1rem;
        font-weight: 700;
        letter-spacing: 0.2rem;
        text-align: center;
        margin: 0 Auto;
        outline: none;
        cursor: pointer;
        -webkit-transition: 0.2s ease;
        -o-transition: 0.2s ease;
        transition: 0.2s ease;
        -webkit-box-shadow: -3px -3px 14px $color-3,
            -3px -3px 10px $color-1,
            3px 3px 8px rgba(255, 255, 255, .075),
            3px 3px 1px rgba(0, 0, 0, .15);
        box-shadow: -3px -3px 14px $color-3,
            -3px -3px 10px $color-1,
            3px 3px 8px rgba(255, 255, 255, .075),
            3px 3px 1px rgba(0, 0, 0, .15);
    }

    .Btn-Contact-Me:hover {
        color: $white;
        background: $color-3;
        transform: scale(1.1);
        -webkit-box-shadow: -2px -2px 6px $color-1,
            -2px -2px 4px $color-1,
            2px 2px 2px rgba(255, 255, 255, .05),
            2px 2px 4px rgba(0, 0, 0, .1);
        box-shadow: -2px -2px 6px $color-1,
            -2px -2px 4px $color-1,
            2px 2px 2px rgba(255, 255, 255, .05),
            2px 2px 4px rgba(0, 0, 0, .1);
        transition: 0.4s ease-in-out;
        -webkit-transition: .4s ease-in-out;
        -o-transition: .4s ease-in-out;
    }

    .Btn-Contact-Me:active {
        -webkit-box-shadow: inset -2px -2px 6px $color-1,
            inset -2px -2px 4px $color-1,
            inset 2px 2px 2px rgba(255, 255, 255, .075),
            inset 2px 2px 4px rgba(0, 0, 0, .15);
        box-shadow: inset -2px -2px 6px $color-1,
            inset -2px -2px 4px $color-1,
            inset 2px 2px 2px rgba(255, 255, 255, .075),
            inset 2px 2px 4px rgba(0, 0, 0, .15);
    }

    .Btn-Contact-Me-Main {
        position: relative;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        top: 7rem;
        width: 10rem;
        padding: 1.5em 1em;
        background: $color-1;
        border: none;
        border-radius: .5rem;
        color: $color-2;
        font-size: 1rem;
        font-weight: 700;
        letter-spacing: .2rem;
        text-align: center;
        margin: 0 Auto;
        outline: none;
        cursor: pointer;
        -webkit-transition: .2s ease;
        -o-transition: .2s ease;
        transition: .2s ease;
        -webkit-box-shadow: -3px -3px 14px $color-1,
            -3x -3px 10px $color-1,
            3px 3px 8px rgba(255, 255, 255, .075),
            3px 3px 10px rgba(0, 0, 0, .15);
        box-shadow: -3px -3px 14px $color-1,
            -3px -3px 10px $color-1,
            3px 3px 8px rgba(255, 255, 255, .075),
            3px 2px 10px rgba(0, 0, 0, .15);
    }

    .Btn-Contact-Me-Main:hover {
        color: $white;
        background: $color-3;
        transform: scale(1.1);
        -webkit-box-shadow: -2px -2px 6px $color-1,
            -2px -2px 4px $color-1,
            2px 2px 2px rgba(255, 255, 255, .05),
            2px 2px 4px rgba(0, 0, 0, .1);
        box-shadow: -2px -2px 6px $color-1,
            -2px -2px 4px $color-1,
            2px 2px 2px rgba(255, 255, 255, .05),
            2px 2px 4px rgba(0, 0, 0, .1);
        transition: 0.4s ease-in-out;
        -webkit-transition: .4s ease-in-out;
        -o-transition: .4s ease-in-out;
    }

    .Btn-Contact-Me-Main:active {
        -webkit-box-shadow: inset -2px -2px 6px $color-1,
            inset -2px -2px 4px $color-1,
            inset 2px 2px 2px rgba(255, 255, 255, .075),
            inset 2px 2px 4px rgba(0, 0, 0, .15);
        box-shadow: inset -2px -2px 6px $color-1,
            inset -2px -2px 4px $color-1,
            inset 2px 2px 2px rgba(255, 255, 255, .075),
            inset 2px 2px 4px rgba(0, 0, 0, .15);
    }


    /* END ONGLET PORTRAIT */

    /* START ONGLET ILLUSTRATIONS */

    .Software {
        position: relative;
        top: 4rem;
        list-style-type: none;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        text-align: center;

        li {
            margin: 1rem;
        }
    }

    .contact-btn-galerie {
        position: relative;
        top: 10rem;
    }

    .Btn-Contact-Me-Galerie {
        width: 10rem;
        position: relative;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        top: 0rem;
        left: 0rem;
        padding: 1.5em 1em;
        background: $color-3;
        border: none;
        border-radius: 0.5rem;
        color: $color-2;
        font-size: 1rem;
        font-weight: 700;
        letter-spacing: 0.2rem;
        text-align: center;
        margin: 0 Auto;
        outline: none;
        cursor: pointer;
        -webkit-transition: 0.2s ease;
        -o-transition: 0.2s ease;
        transition: 0.2s ease;
        -webkit-box-shadow: -3px -3px 14px $color-1,
            -3px -3px 10px $color-3,
            3px 3px 8px rgba(255, 255, 255, .075),
            3px 3px 1px rgba(0, 0, 0, .15);
        box-shadow: -3px -3px 14px $color-1,
            -3px -3px 10px $color-3,
            3px 3px 8px rgba(255, 255, 255, .075),
            3px 3px 1px rgba(0, 0, 0, .15);
    }

    .Btn-Contact-Me-Galerie:hover {
        color: $white;
        background: $color-1;
        transform: scale(1.1);
        -webkit-box-shadow: -2px -2px 6px $color-3,
            -2px -2px 4px $color-3,
            2px 2px 2px rgba(255, 255, 255, .05),
            2px 2px 4px rgba(0, 0, 0, .1);
        box-shadow: -2px -2px 6px $color-3,
            -2px -2px 4px $color-3,
            2px 2px 2px rgba(255, 255, 255, .05),
            2px 2px 4px rgba(0, 0, 0, .1);
        transition: 0.4s ease-in-out;
        -webkit-transition: .4s ease-in-out;
        -o-transition: .4s ease-in-out;
    }

    .Btn-Contact-Me-Galerie:active {
        -webkit-box-shadow: inset -2px -2px 6px $color-3,
            inset -2px -2px 4px $color-3,
            inset 2px 2px 2px rgba(255, 255, 255, .075),
            inset 2px 2px 4px rgba(0, 0, 0, .15);
        box-shadow: inset -2px -2px 6px $color-3,
            inset -2px -2px 4px $color-3,
            inset 2px 2px 2px rgba(255, 255, 255, .075),
            inset 2px 2px 4px rgba(0, 0, 0, .15);
    }

    .Btn-Galerie-Me-Main {
        position: relative;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        top: 0rem;
        width: 10rem;
        padding: 1.5em 1em;
        background: $color-1;
        border: none;
        border-radius: .5rem;
        color: $color-2;
        font-size: 1rem;
        font-weight: 700;
        letter-spacing: .2rem;
        text-align: center;
        margin: 0 Auto;
        outline: none;
        cursor: pointer;
        -webkit-transition: .2s ease;
        -o-transition: .2s ease;
        transition: .2s ease;
        -webkit-box-shadow: -3px -3px 14px $color-1,
            -3px -3px 10px $color-1,
            3px 3px 8px rgba(255, 255, 255, .075),
            3px 3px 10px rgba(0, 0, 0, .15);
        box-shadow: -3px -3px 14px $color-1,
            -3px -3px 10px $color-1,
            3px 3px 8px rgba(255, 255, 255, .075),
            3px 3px 10px rgba(0, 0, 0, .15);
    }

    .Btn-Galerie-Me-Main:hover {
        color: $white;
        background: $color-3;
        transform: scale(1.1);
        -webkit-box-shadow: -2px -2px 6px $color-1,
            -2px -2px 4px $color-1,
            2px 2px 2px rgba(255, 255, 255, .05),
            2px 2px 4px rgba(0, 0, 0, .1);
        box-shadow: -2px -2px 6px $color-1,
            -2px -2px 4px $color-1,
            2px 2px 2px rgba(255, 255, 255, .05),
            2px 2px 4px rgba(0, 0, 0, .1);
        transition: 0.4s ease-in-out;
        -webkit-transition: .4s ease-in-out;
        -o-transition: .4s ease-in-out;
    }

    .Btn-Galerie-Me-Main:active {
        -webkit-box-shadow: inset -2px -2px 6px $color-1,
            inset -2px -2px 4px $color-1,
            inset 2px 2px 2px rgba(255, 255, 255, .075),
            inset 2px 2px 4px rgba(0, 0, 0, .15);
        box-shadow: inset -2px -2px 6px $color-1,
            inset -2px -2px 4px $color-1,
            inset 2px 2px 2px rgba(255, 255, 255, .075),
            inset 2px 2px 4px rgba(0, 0, 0, .15);
    }

    /* END ONGLET ILLUSTRATIONS */

    /* START ONGLET VIDEOS */

    .WhatUseVideos {
        position: relative;
        top: 0rem;
    }

    .contact-btn-Videos {
        position: relative;
        top: 10rem;
    }

    .Btn-Contact-Me-Videos {
        width: 10rem;
        position: relative;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        top: 0rem;
        left: 0rem;
        padding: 1.5em 1em;
        background: $color-3;
        border: none;
        border-radius: 0.5rem;
        color: $color-2;
        font-size: 1rem;
        font-weight: 700;
        letter-spacing: 0.2rem;
        text-align: center;
        margin: 0 Auto;
        outline: none;
        cursor: pointer;
        -webkit-transition: 0.2s ease;
        -o-transition: 0.2s ease;
        transition: 0.2s ease;
        -webkit-box-shadow: -3px -3px 14px $color-1,
            -3px -3px 10px $color-3,
            3px 3px 8px rgba(255, 255, 255, .075),
            3px 3px 1px rgba(0, 0, 0, .15);
        box-shadow: -3px -3px 14px $color-1,
            -3px -3px 10px $color-3,
            3px 3px 8px rgba(255, 255, 255, .075),
            3px 3px 1px rgba(0, 0, 0, .15);
    }

    .Btn-Contact-Me-Videos:hover {
        color: $white;
        background: $color-1;
        transform: scale(1.1);
        -webkit-box-shadow: -2px -2px 6px $color-3,
            -2px -2px 4px $color-3,
            2px 2px 2px rgba(255, 255, 255, .05),
            2px 2px 4px rgba(0, 0, 0, .1);
        box-shadow: -2px -2px 6px $color-3,
            -2px -2px 4px $color-3,
            2px 2px 2px rgba(255, 255, 255, .05),
            2px 2px 4px rgba(0, 0, 0, .1);
        transition: 0.4s ease-in-out;
        -webkit-transition: .4s ease-in-out;
        -o-transition: .4s ease-in-out;
    }

    .Btn-Contact-Me-Videos:active {
        -webkit-box-shadow: inset -2px -2px 6px $color-3,
            inset -2px -2px 4px $color-3,
            inset 2px 2px 2px rgba(255, 255, 255, .075),
            inset 2px 2px 4px rgba(0, 0, 0, .15);
        box-shadow: inset -2px -2px 6px $color-3,
            inset -2px -2px 4px $color-3,
            inset 2px 2px 2px rgba(255, 255, 255, .075),
            inset 2px 2px 4px rgba(0, 0, 0, .15);
    }

    .Btn-Videos-Me-Main {
        position: relative;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        top: 0rem;
        width: 10rem;
        padding: 1.5em 1em;
        background: $color-1;
        border: none;
        border-radius: .5rem;
        color: $color-2;
        font-size: 1rem;
        font-weight: 700;
        letter-spacing: .2rem;
        text-align: center;
        margin: 0 Auto;
        outline: none;
        cursor: pointer;
        -webkit-transition: .2s ease;
        -o-transition: .2s ease;
        transition: .2s ease;
        -webkit-box-shadow: -3px -3px 14px $color-1,
            -3px -3px 10px $color-1,
            3px 3px 8px rgba(255, 255, 255, .075),
            3px 3px 10px rgba(0, 0, 0, .15);
        box-shadow: -3px -3px 14px $color-1,
            -3px -3px 10px $color-1,
            3px 3px 8px rgba(255, 255, 255, .075),
            3px 3px 10px rgba(0, 0, 0, .15);
    }

    .Btn-Videos-Me-Main:hover {
        color: $white;
        background: $color-3;
        transform: scale(1.1);
        -webkit-box-shadow: -2px -2px 6px $color-1,
            -2px -2px 4px $color-1,
            2px 2px 2px rgba(255, 255, 255, .05),
            2px 2px 4px rgba(0, 0, 0, .1);
        box-shadow: -2px -2px 6px $color-1,
            -2px -2px 4px $color-1,
            2px 2px 2px rgba(255, 255, 255, .05),
            2px 2px 4px rgba(0, 0, 0, .1);
        transition: 0.4s ease-in-out;
        -webkit-transition: .4s ease-in-out;
        -o-transition: .4s ease-in-out;
    }

    .Btn-Videos-Me-Main:active {
        -webkit-box-shadow: inset -2px -2px 6px $color-1,
            inset -2px -2px 4px $color-1,
            inset 2px 2px 2px rgba(255, 255, 255, .075),
            inset 2px 2px 4px rgba(0, 0, 0, .15);
        box-shadow: inset -2px -2px 6px $color-1,
            inset -2px -2px 4px $color-1,
            inset 2px 2px 2px rgba(255, 255, 255, .075),
            inset 2px 2px 4px rgba(0, 0, 0, .15);
    }



    /* END ONGLET VIDEOS */

    /* START ONGLET JAMS */

    .overlay__text {
        top: 1rem !important;
        height: 5rem;
    }

    .WhatUsejams {
        position: relative;
        top: 2rem;
    }

    .pitchSoft {
        position: relative;
        top: 0rem;
    }

    .Magix {
        color: $color-3  !important;
    }

    .Magix:hover {
        color: $color-1;
        -webkit-transform: scale(1.1);
        -ms-transform: scale(1.1);
        transform: scale(1.1);
        -webkit-transition: 0.4s ease;
        -o-transition: 0.4s ease;
        transition: 0.4s ease;
        ;
    }

    .contact-btn-Jams {
        position: relative;
        top: 10rem;
    }

    .Btn-Contact-Me-Jams {
        width: 10rem;
        position: relative;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        top: 0rem;
        left: 0rem;
        padding: 1.5em 1em;
        background: $color-3;
        border: none;
        border-radius: 0.5rem;
        color: $color-2;
        font-size: 1rem;
        font-weight: 700;
        letter-spacing: 0.2rem;
        text-align: center;
        margin: 0 Auto;
        outline: none;
        cursor: pointer;
        -webkit-transition: 0.2s ease;
        -o-transition: 0.2s ease;
        transition: 0.2s ease;
        -webkit-box-shadow: -3px -3px 14px $color-1,
            -3px -3px 10px $color-3,
            3px 3px 8px rgba(255, 255, 255, .075),
            3px 3px 1px rgba(0, 0, 0, .15);
        box-shadow: -3px -3px 14px $color-1,
            -3px -3px 10px $color-3,
            3px 3px 8px rgba(255, 255, 255, .075),
            3px 3px 1px rgba(0, 0, 0, .15);
    }

    .Btn-Contact-Me-Jams:hover {
        color: $white;
        background: $color-1;
        transform: scale(1.1);
        -webkit-box-shadow: -2px -2px 6px $color-3,
            -2px -2px 4px $color-3,
            2px 2px 2px rgba(255, 255, 255, .05),
            2px 2px 4px rgba(0, 0, 0, .1);
        box-shadow: -2px -2px 6px $color-3,
            -2px -2px 4px $color-3,
            2px 2px 2px rgba(255, 255, 255, .05),
            2px 2px 4px rgba(0, 0, 0, .1);
        transition: 0.4s ease-in-out;
        -webkit-transition: .4s ease-in-out;
        -o-transition: .4s ease-in-out;
    }

    .Btn-Contact-Me-Jams:active {
        -webkit-box-shadow: inset -2px -2px 6px $color-3,
            inset -2px -2px 4px $color-3,
            inset 2px 2px 2px rgba(255, 255, 255, .075),
            inset 2px 2px 4px rgba(0, 0, 0, .15);
        box-shadow: inset -2px -2px 6px $color-3,
            inset -2px -2px 4px $color-3,
            inset 2px 2px 2px rgba(255, 255, 255, .075),
            inset 2px 2px 4px rgba(0, 0, 0, .15);
    }

    .Btn-Jams-Me-Main {
        position: relative;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        top: 0rem;
        width: 10rem;
        padding: 1.5em 1em;
        background: $color-1;
        border: none;
        border-radius: .5rem;
        color: $color-2;
        font-size: 1rem;
        font-weight: 700;
        letter-spacing: .2rem;
        text-align: center;
        margin: 0 Auto;
        outline: none;
        cursor: pointer;
        -webkit-transition: .2s ease;
        -o-transition: .2s ease;
        transition: .2s ease;
        -webkit-box-shadow: -3px -3px 14px $color-1,
            -3px -3px 10px $color-1,
            3px 3px 8px rgba(255, 255, 255, .075),
            3px 3px 10px rgba(0, 0, 0, .15);
        box-shadow: -3px -3px 14px $color-1,
            -3px -3px 10px $color-1,
            3px 3px 8px rgba(255, 255, 255, .075),
            3px 3px 10px rgba(0, 0, 0, .15);
    }

    .Btn-Jams-Me-Main:hover {
        color: $white;
        background: $color-3;
        transform: scale(1.1);
        -webkit-box-shadow: -2px -2px 6px $color-1,
            -2px -2px 4px $color-1,
            2px 2px 2px rgba(255, 255, 255, .05),
            2px 2px 4px rgba(0, 0, 0, .1);
        box-shadow: -2px -2px 6px $color-1,
            -2px -2px 4px $color-1,
            2px 2px 2px rgba(255, 255, 255, .05),
            2px 2px 4px rgba(0, 0, 0, .1);
        transition: 0.4s ease-in-out;
        -webkit-transition: .4s ease-in-out;
        -o-transition: .4s ease-in-out;
    }

    .Btn-Jams-Me-Main:active {
        -webkit-box-shadow: inset -2px -2px 6px $color-1,
            inset -2px -2px 4px $color-1,
            inset 2px 2px 2px rgba(255, 255, 255, .075),
            inset 2px 2px 4px rgba(0, 0, 0, .15);
        box-shadow: inset -2px -2px 6px $color-1,
            inset -2px -2px 4px $color-1,
            inset 2px 2px 2px rgba(255, 255, 255, .075),
            inset 2px 2px 4px rgba(0, 0, 0, .15);
    }

    /* END ONGLET JAMS */

}

@media #{$BREAKPOINT-TABLET-LAND} {

    /*         START Nav Onglet        */
        .container {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-orient: vertical;
            -webkit-box-direction: normal;
            -ms-flex-direction: column;
            flex-direction: column;
            position: relative;
            top: 0rem;
            margin-top: 0.5rem;
            width: 100%;
            height: 100%;
            margin: 100px auto 0;
            word-break: break-all;
            left: 0rem;
        }
    
        .bloc-tabs {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -ms-flex-pack: distribute;
            justify-content: space-around;
            height: 2.5rem;
            width: 100%;
        }
    
        .tabs {
            padding: 2px;
            text-align: center;
            width: 10rem;
            color: $color-1;
            cursor: pointer;
            -webkit-box-sizing: content-box;
            box-sizing: content-box;
            position: relative;
            outline: none;
            font-size: 1rem;
        }
    
        .tabs:hover {
            font-size: 1rem;
            width: 10rem;
            border-radius: 50% 0% 0% 0% / 50% 0% 50% 0%;
            -webkit-transform: translateY(-10px);
            -ms-transform: translateY(-10px);
            transform: translateY(-10px);
            -webkit-transition: 0.4s ease;
            -o-transition: 0.4s ease;
            transition: 0.4s ease;
            -webkit-animation-duration: 0.4s ease;
            animation-duration: 0.4s ease;
    
        }
    
        .active-tabs {
            background: $color-3;
            border: 0.1rem solid $color-1;
            width: 4rem;
            font-size: 1.2rem;
            color: $color-2;
            border-radius: 50% 50% 0% 0% / 50% 50% 10% 10%;
            -webkit-transition: 0.4s ease;
            -o-transition: 0.4s ease;
            transition: 0.4s ease;
        }
    
        .active-tabs:hover {
            background: $transparent-3;
            border: 0.1rem solid $color-3;
            width: 4rem;
            font-size: 0.6rem;
            color: $white;
            border-radius: 50% 50% 50% 50% / 50% 50% 20% 20%;
            -webkit-transition: 0.4s ease;
            -o-transition: 0.4s ease;
            transition: 0.4s ease;
        }
    
        .active-tabs::before {
            content: "";
            display: block;
            position: absolute;
            top: -4px;
            left: 50%;
            -webkit-transform: translateX(-50%);
            -ms-transform: translateX(-50%);
            transform: translateX(-50%);
            width: calc(100% + 2px);
            height: 1px;
            border-radius: 50% 0% 50% 0% / 50% 0% 50% 0%;
        }
    
        .content-tabs {
            -webkit-box-flex: 1;
            -ms-flex-positive: 1;
            flex-grow: 1;
        }
    
        /*         END Nav Onglet        */
        /*         START Contenu onglet        */
    
        .content {
            border-left: 0.01rem solid $color-1;
            border-top: 0.01rem solid $color-1;
            padding: 20px;
            width: 100%;
            height: 100%;
            background: $transparent-1;
    
            /*Margin-right de quoi pourquoi /?\*/
            margin-right: 1rem;
            /* */
    
            display: none;
            color: $white;
            background-position: center bottom;
            background-size: 100% auto;
            background-repeat: no-repeat;
            position: relative;
            top: 0rem;
        }
    
        .Title-Onglet {
            position: relative;
            top: 0rem;
            font-size: 1.4rem;
            padding: 0px 0 5px 0px;
        }
    
        .trait1 {
            width: 250px;
            height: 6px;
            margin-bottom: 5px;
            color: $color-1;
            background: $color-1;
        }
    
        .trait2 {
            width: 150px;
            height: 4px;
            margin-bottom: 5px;
            color: $color-3;
            background: $color-3;
        }

        .trait1:hover {
            width: 90rem;
            -webkit-transition: 0.5s ease-in-out;
            -o-transition: 0.5s ease-in-out;
            transition: 0.5s ease-in-out;
        }

        .trait2:hover {
            width: 90rem;
            -webkit-transition: 0.5s ease-in-out;
            -o-transition: 0.5s ease-in-out;
            transition: 0.5s ease-in-out;
        }
    
        .active-content {
            display: block;
            background: $transparent-1;
        }
    
        /* START ONGLET PORTRAIT */
    
        .ImgPortait {
            float: left;
            width: 22rem;
            margin: 0 auto;
            padding: 1rem;
        }
    
        .pitchPortrait {
            position: relative;
            top: 2rem;
            text-align: left;
            font-size: 1.4rem;
            line-height: 3rem;
            padding: 1rem;
            width: 100%;
        }
    
        .pitchPortraitLast {
            position: relative;
            top: 2rem;
            text-align: center;
            font-size: 1.4rem;
            line-height: 3rem;
            padding: 1rem;
            width: auto;
        }
    
        .pitchBleu {
            color: $color-3;
            font-size: 1.6rem;
        }
    
        .pitchPortraitNb {
            margin-top: 2rem;
            color: #50bbfd;
            font-size: 1.2rem;
            line-height: 2rem;
            position: relative;
            top: 5rem;
            width:auto;
            margin: 0 auto;
        }
    
        .ContentLinkPortrait {
            position: relative;
            top: 2rem;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-orient: horizontal;
            -webkit-box-direction: normal;
            -ms-flex-direction: row;
            flex-direction: row;
            -webkit-box-pack: center;
            -ms-flex-pack: center;
            justify-content: center;
            line-height: 2rem;
        }
    
        .LinkPortrait {
            color: $color-3;
            margin: 1rem;
            font-size: 1.2rem;
        }
    
        .LinkPortrait:hover {
            color: $color-1;
            -webkit-transform: scale(1.1);
            -ms-transform: scale(1.1);
            transform: scale(1.1);
            -webkit-transition: 0.4s ease;
            -o-transition: 0.4s ease;
            transition: 0.4s ease;
            ;
        }
    
        .ThumbSiteSection {
            position: relative;
            top: 8rem;
        }
    
        .siteWeb {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            -ms-flex-pack: distribute;
            -webkit-box-orient: vertical;
            -webkit-box-direction: normal;
            -ms-flex-direction: column;
            flex-direction: column;
            position: relative;
            top: 5rem;
        }
    
        .ThumbContent {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-orient: vertical;
            -webkit-box-direction: normal;
            -ms-flex-direction: column;
            flex-direction: column;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            margin-bottom: 5rem;
        }
    
        .ThumbImgSite {
            width: 10rem;
        }
    
        .TitleThumb {
            position: relative;
            top: -12rem;
            font-size: 1.2rem;
            color: $color-3;
            margin-top: 2rem;
            margin-bottom: 1rem;
        }
    
        .ParaThumb {
            position: relative;
            top: -1rem;
            width: auto;
            text-align: left;
            line-height: 2rem;
            margin-bottom: 1rem;
            font-size: 1rem;
        }
    
        .Linkthumb {
            color: $color-3;
            font-size: 1.2rem;
        }
    
        .Linkthumb:hover {
            color: $color-1;
            -webkit-transform: scale(1.1);
            -ms-transform: scale(1.1);
            transform: scale(1.1);
            -webkit-transition: 0.4s ease;
            -o-transition: 0.4s ease;
            transition: 0.4s ease;
        }
    
        .contact-btn {
            position: relative;
            top: 5rem;
        }
    
        .Btn-Contact-Me {
            width: 18rem !important;
            position: relative;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-pack: center;
            -ms-flex-pack: center;
            justify-content: center;
            top: -2rem;
            left: 0rem;
            padding: 1.5em 3em;
            background: #a4cbf2;
            border: none;
            border-radius: 0.5rem;
            color: $color-2;
            font-size: 1rem;
            font-weight: 700;
            letter-spacing: 0.2rem;
            text-align: center;
            margin: 0 Auto;
            outline: none;
            cursor: pointer;
            -webkit-transition: 0.2s ease;
            -o-transition: 0.2s ease;
            transition: 0.2s ease;
            -webkit-box-shadow: -3px -3px 14px $color-3,
                -3px -3px 10px $color-1,
                3px 3px 8px rgba(255, 255, 255, .075),
                3px 3px 1px rgba(0, 0, 0, .15);
            box-shadow: -3px -3px 14px $color-3,
                -3px -3px 10px $color-1,
                3px 3px 8px rgba(255, 255, 255, .075),
                3px 3px 1px rgba(0, 0, 0, .15);
        }
    
        .Btn-Contact-Me:hover {
            color: $white;
            background: $color-3;
            transform: scale(1.1);
            -webkit-box-shadow: -2px -2px 6px $color-1,
                -2px -2px 4px $color-1,
                2px 2px 2px rgba(255, 255, 255, .05),
                2px 2px 4px rgba(0, 0, 0, .1);
            box-shadow: -2px -2px 6px $color-1,
                -2px -2px 4px $color-1,
                2px 2px 2px rgba(255, 255, 255, .05),
                2px 2px 4px rgba(0, 0, 0, .1);
            transition: 0.4s ease-in-out;
            -webkit-transition: .4s ease-in-out;
            -o-transition: .4s ease-in-out;
        }
    
        .Btn-Contact-Me:active {
            -webkit-box-shadow: inset -2px -2px 6px $color-1,
                inset -2px -2px 4px $color-1,
                inset 2px 2px 2px rgba(255, 255, 255, .075),
                inset 2px 2px 4px rgba(0, 0, 0, .15);
            box-shadow: inset -2px -2px 6px $color-1,
                inset -2px -2px 4px $color-1,
                inset 2px 2px 2px rgba(255, 255, 255, .075),
                inset 2px 2px 4px rgba(0, 0, 0, .15);
        }
    
        .Btn-Contact-Me-Main {
            position: relative;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-pack: center;
            -ms-flex-pack: center;
            justify-content: center;
            top: 7rem;
            width: 10rem;
            padding: 1.5em 1em;
            background: $color-1;
            border: none;
            border-radius: .5rem;
            color: $color-2;
            font-size: 1rem;
            font-weight: 700;
            letter-spacing: .2rem;
            text-align: center;
            margin: 0 Auto;
            outline: none;
            cursor: pointer;
            -webkit-transition: .2s ease;
            -o-transition: .2s ease;
            transition: .2s ease;
            -webkit-box-shadow: -3px -3px 14px $color-1,
                -3px -3px 10px $color-1,
                3px 3px 8px rgba(255, 255, 255, .075),
                3px 3px 10px rgba(0, 0, 0, .15);
            box-shadow: -3px -3px 14px $color-1,
                -3px -3px 10px $color-1,
                3px 3px 8px rgba(255, 255, 255, .075),
                3px 3px 10px rgba(0, 0, 0, .15);
        }
    
        .Btn-Contact-Me-Main:hover {
            color: $white;
            background: $color-3;
            transform: scale(1.1);
            -webkit-box-shadow: -2px -2px 6px $color-1,
                -2px -2px 4px $color-1,
                2px 2px 2px rgba(255, 255, 255, .05),
                2px 2px 4px rgba(0, 0, 0, .1);
            box-shadow: -2px -2px 6px $color-1,
                -2px -2px 4px $color-1,
                2px 2px 2px rgba(255, 255, 255, .05),
                2px 2px 4px rgba(0, 0, 0, .1);
            transition: 0.4s ease-in-out;
            -webkit-transition: .4s ease-in-out;
            -o-transition: .4s ease-in-out;
        }
    
        .Btn-Contact-Me-Main:active {
            -webkit-box-shadow: inset -2px -2px 6px $color-1,
                inset -2px -2px 4px $color-1,
                inset 2px 2px 2px rgba(255, 255, 255, .075),
                inset 2px 2px 4px rgba(0, 0, 0, .15);
            box-shadow: inset -2px -2px 6px $color-1,
                inset -2px -2px 4px $color-1,
                inset 2px 2px 2px rgba(255, 255, 255, .075),
                inset 2px 2px 4px rgba(0, 0, 0, .15);
        }
    
    
        /* END ONGLET PORTRAIT */
    
        /* START ONGLET ILLUSTRATIONS */
    
        .Software {
            position: relative;
            top: 4rem;
            list-style-type: none;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-orient: vertical;
            -webkit-box-direction: normal;
            -ms-flex-direction: column;
            flex-direction: column;
            text-align: center;
    
            li {
                margin: 1rem;
            }
        }
    
        .contact-btn-galerie {
            position: relative;
            top: 10rem;
        }
    
        .Btn-Contact-Me-Galerie {
            width: 10rem;
            position: relative;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-pack: center;
            -ms-flex-pack: center;
            justify-content: center;
            top: 0rem;
            left: 0rem;
            padding: 1.5em 1em;
            background: $color-3;
            border: none;
            border-radius: 0.5rem;
            color: $color-2;
            font-size: 1rem;
            font-weight: 700;
            letter-spacing: 0.2rem;
            text-align: center;
            margin: 0 Auto;
            outline: none;
            cursor: pointer;
            -webkit-transition: 0.2s ease;
            -o-transition: 0.2s ease;
            transition: 0.2s ease;
            -webkit-box-shadow: -3px -3px 14px $color-1,
                -3px -3px 10px $color-3,
                3px 3px 8px rgba(255, 255, 255, .075),
                3px 3px 1px rgba(0, 0, 0, .15);
            box-shadow: -3px -3px 14px $color-1,
                -3px -3px 10px $color-3,
                3px 3px 8px rgba(255, 255, 255, .075),
                3px 3px 1px rgba(0, 0, 0, .15);
        }
    
        .Btn-Contact-Me-Galerie:hover {
            color: $white;
            background: $color-1;
            transform: scale(1.1);
            -webkit-box-shadow: -2px -2px 6px $color-3,
                -2px -2px 4px $color-3,
                2px 2px 2px rgba(255, 255, 255, .05),
                2px 2px 4px rgba(0, 0, 0, .1);
            box-shadow: -2px -2px 6px $color-3,
                -2px -2px 4px $color-3,
                2px 2px 2px rgba(255, 255, 255, .05),
                2px 2px 4px rgba(0, 0, 0, .1);
            transition: 0.4s ease-in-out;
            -webkit-transition: .4s ease-in-out;
            -o-transition: .4s ease-in-out;
        }
    
        .Btn-Contact-Me-Galerie:active {
            -webkit-box-shadow: inset -2px -2px 6px $color-3,
                inset -2px -2px 4px $color-3,
                inset 2px 2px 2px rgba(255, 255, 255, .075),
                inset 2px 2px 4px rgba(0, 0, 0, .15);
            box-shadow: inset -2px -2px 6px $color-3,
                inset -2px -2px 4px $color-3,
                inset 2px 2px 2px rgba(255, 255, 255, .075),
                inset 2px 2px 4px rgba(0, 0, 0, .15);
        }
    
        .Btn-Galerie-Me-Main {
            position: relative;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-pack: center;
            -ms-flex-pack: center;
            justify-content: center;
            top: 0rem;
            width: 10rem;
            padding: 1.5em 1em;
            background: $color-1;
            border: none;
            border-radius: .5rem;
            color: $color-2;
            font-size: 1rem;
            font-weight: 700;
            letter-spacing: .2rem;
            text-align: center;
            margin: 0 Auto;
            outline: none;
            cursor: pointer;
            -webkit-transition: .2s ease;
            -o-transition: .2s ease;
            transition: .2s ease;
            -webkit-box-shadow: -3px -3px 14px $color-1,
                -3px -3px 10px $color-1,
                3px 3px 8px rgba(255, 255, 255, .075),
                3px 3px 10px rgba(0, 0, 0, .15);
            box-shadow: -6px -6px 14px $color-1,
                -3px -3px 10px $color-1,
                3px 3px 8px rgba(255, 255, 255, .075),
                3px 3px 10px rgba(0, 0, 0, .15);
        }
    
        .Btn-Galerie-Me-Main:hover {
            color: $white;
            background: $color-3;
            transform: scale(1.1);
            -webkit-box-shadow: -2px -2px 6px $color-1,
                -2px -2px 4px $color-1,
                2px 2px 2px rgba(255, 255, 255, .05),
                2px 2px 4px rgba(0, 0, 0, .1);
            box-shadow: -2px -2px 6px $color-1,
                -2px -2px 4px $color-1,
                2px 2px 2px rgba(255, 255, 255, .05),
                2px 2px 4px rgba(0, 0, 0, .1);
            transition: 0.4s ease-in-out;
            -webkit-transition: .4s ease-in-out;
            -o-transition: .4s ease-in-out;
        }
    
        .Btn-Galerie-Me-Main:active {
            -webkit-box-shadow: inset -2px -2px 6px $color-1,
                inset -2px -2px 4px $color-1,
                inset 2px 2px 2px rgba(255, 255, 255, .075),
                inset 2px 2px 4px rgba(0, 0, 0, .15);
            box-shadow: inset -2px -2px 6px $color-1,
                inset -2px -2px 4px $color-1,
                inset 2px 2px 2px rgba(255, 255, 255, .075),
                inset 2px 2px 4px rgba(0, 0, 0, .15);
        }
    
        /* END ONGLET ILLUSTRATIONS */
    
        /* START ONGLET VIDEOS */
    
        .WhatUseVideos {
            position: relative;
            top: 0rem;
        }
    
        .contact-btn-Videos {
            position: relative;
            top: 10rem;
        }
    
        .Btn-Contact-Me-Videos {
            width: 10rem;
            position: relative;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-pack: center;
            -ms-flex-pack: center;
            justify-content: center;
            top: 0rem;
            left: 0rem;
            padding: 1.5em 1em;
            background: $color-3;
            border: none;
            border-radius: 0.5rem;
            color: $color-2;
            font-size: 1rem;
            font-weight: 700;
            letter-spacing: 0.2rem;
            text-align: center;
            margin: 0 Auto;
            outline: none;
            cursor: pointer;
            -webkit-transition: 0.2s ease;
            -o-transition: 0.2s ease;
            transition: 0.2s ease;
            -webkit-box-shadow: -3px -3px 14px $color-1,
                -3px -3px 10px $color-3,
                3px 3px 8px rgba(255, 255, 255, .075),
                3px 3px 1px rgba(0, 0, 0, .15);
            box-shadow: -3px -3px 14px $color-1,
                -3px -3px 10px $color-3,
                3px 3px 8px rgba(255, 255, 255, .075),
                3px 3px 1px rgba(0, 0, 0, .15);
        }
    
        .Btn-Contact-Me-Videos:hover {
            color: $white;
            background: $color-1;
            transform: scale(1.1);
            -webkit-box-shadow: -2px -2px 6px $color-3,
                -2px -2px 4px $color-3,
                2px 2px 2px rgba(255, 255, 255, .05),
                2px 2px 4px rgba(0, 0, 0, .1);
            box-shadow: -2px -2px 6px $color-3,
                -2px -2px 4px $color-3,
                2px 2px 2px rgba(255, 255, 255, .05),
                2px 2px 4px rgba(0, 0, 0, .1);
            transition: 0.4s ease-in-out;
            -webkit-transition: .4s ease-in-out;
            -o-transition: .4s ease-in-out;
        }
    
        .Btn-Contact-Me-Videos:active {
            -webkit-box-shadow: inset -2px -2px 6px $color-3,
                inset -2px -2px 4px $color-3,
                inset 2px 2px 2px rgba(255, 255, 255, .075),
                inset 2px 2px 4px rgba(0, 0, 0, .15);
            box-shadow: inset -2px -2px 6px $color-3,
                inset -2px -2px 4px $color-3,
                inset 2px 2px 2px rgba(255, 255, 255, .075),
                inset 2px 2px 4px rgba(0, 0, 0, .15);
        }
    
        .Btn-Videos-Me-Main {
            position: relative;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-pack: center;
            -ms-flex-pack: center;
            justify-content: center;
            top: 0rem;
            width: 10rem;
            padding: 1.5em 1em;
            background: $color-1;
            border: none;
            border-radius: .5rem;
            color: $color-2;
            font-size: 1rem;
            font-weight: 700;
            letter-spacing: .2rem;
            text-align: center;
            margin: 0 Auto;
            outline: none;
            cursor: pointer;
            -webkit-transition: .2s ease;
            -o-transition: .2s ease;
            transition: .2s ease;
            -webkit-box-shadow: -3px -3px 14px $color-1,
                -3px -3px 10px $color-1,
                3px 3px 8px rgba(255, 255, 255, .075),
                3px 3px 10px rgba(0, 0, 0, .15);
            box-shadow: -3px -3px 14px $color-1,
                -3px -3px 10px $color-1,
                3px 3px 8px rgba(255, 255, 255, .075),
                3px 3px 10px rgba(0, 0, 0, .15);
        }
    
        .Btn-Videos-Me-Main:hover {
            color: $white;
            background: $color-3;
            transform: scale(1.1);
            -webkit-box-shadow: -2px -2px 6px $color-1,
                -2px -2px 4px $color-1,
                2px 2px 2px rgba(255, 255, 255, .05),
                2px 2px 4px rgba(0, 0, 0, .1);
            box-shadow: -2px -2px 6px $color-1,
                -2px -2px 4px $color-1,
                2px 2px 2px rgba(255, 255, 255, .05),
                2px 2px 4px rgba(0, 0, 0, .1);
            transition: 0.4s ease-in-out;
            -webkit-transition: .4s ease-in-out;
            -o-transition: .4s ease-in-out;
        }
    
        .Btn-Videos-Me-Main:active {
            -webkit-box-shadow: inset -2px -2px 6px $color-1,
                inset -2px -2px 4px $color-1,
                inset 2px 2px 2px rgba(255, 255, 255, .075),
                inset 2px 2px 4px rgba(0, 0, 0, .15);
            box-shadow: inset -2px -2px 6px $color-1,
                inset -2px -2px 4px $color-1,
                inset 2px 2px 2px rgba(255, 255, 255, .075),
                inset 2px 2px 4px rgba(0, 0, 0, .15);
        }
    
    
    
        /* END ONGLET VIDEOS */
    
        /* START ONGLET JAMS */
    
        .overlay__text {
            top: 1rem !important;
            height: 5rem;
        }
    
        .WhatUsejams {
            position: relative;
            top: 1rem;
        }
    
        .pitchSoft {
            position: relative;
            top: 0rem;
        }
    
        .Magix {
            color: $color-3  !important;
        }
    
        .Magix:hover {
            color: $color-1;
            -webkit-transform: scale(1.1);
            -ms-transform: scale(1.1);
            transform: scale(1.1);
            -webkit-transition: 0.4s ease;
            -o-transition: 0.4s ease;
            transition: 0.4s ease;
            ;
        }
    
        .contact-btn-Jams {
            position: relative;
            top: 10rem;
        }
    
        .Btn-Contact-Me-Jams {
            width: 10rem;
            position: relative;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-pack: center;
            -ms-flex-pack: center;
            justify-content: center;
            top: 0rem;
            left: 0rem;
            padding: 1.5em 1em;
            background: $color-3;
            border: none;
            border-radius: 0.5rem;
            color: $color-2;
            font-size: 1rem;
            font-weight: 700;
            letter-spacing: 0.2rem;
            text-align: center;
            margin: 0 Auto;
            outline: none;
            cursor: pointer;
            -webkit-transition: 0.2s ease;
            -o-transition: 0.2s ease;
            transition: 0.2s ease;
            -webkit-box-shadow: -3px -3px 14px $color-1,
                -3px -3px 10px $color-3,
                3px 3px 8px rgba(255, 255, 255, .075),
                3px 3px 1px rgba(0, 0, 0, .15);
            box-shadow: -3px -3px 14px $color-1,
                -3px -3px 10px $color-3,
                3px 3px 8px rgba(255, 255, 255, .075),
                3px 3px 1px rgba(0, 0, 0, .15);
        }
    
        .Btn-Contact-Me-Jams:hover {
            color: $white;
            background: $color-1;
            transform: scale(1.1);
            -webkit-box-shadow: -2px -2px 6px $color-3,
                -2px -2px 4px $color-3,
                2px 2px 2px rgba(255, 255, 255, .05),
                2px 2px 4px rgba(0, 0, 0, .1);
            box-shadow: -2px -2px 6px $color-3,
                -2px -2px 4px $color-3,
                2px 2px 2px rgba(255, 255, 255, .05),
                2px 2px 4px rgba(0, 0, 0, .1);
            transition: 0.4s ease-in-out;
            -webkit-transition: .4s ease-in-out;
            -o-transition: .4s ease-in-out;
        }
    
        .Btn-Contact-Me-Jams:active {
            -webkit-box-shadow: inset -2px -2px 6px $color-3,
                inset -2px -2px 4px $color-3,
                inset 2px 2px 2px rgba(255, 255, 255, .075),
                inset 2px 2px 4px rgba(0, 0, 0, .15);
            box-shadow: inset -2px -2px 6px $color-3,
                inset -2px -2px 4px $color-3,
                inset 2px 2px 2px rgba(255, 255, 255, .075),
                inset 2px 2px 4px rgba(0, 0, 0, .15);
        }
    
        .Btn-Jams-Me-Main {
            position: relative;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-pack: center;
            -ms-flex-pack: center;
            justify-content: center;
            top: 0rem;
            width: 10rem;
            padding: 1.5em 1em;
            background: $color-1;
            border: none;
            border-radius: .5rem;
            color: $color-2;
            font-size: 1rem;
            font-weight: 700;
            letter-spacing: .2rem;
            text-align: center;
            margin: 0 Auto;
            outline: none;
            cursor: pointer;
            -webkit-transition: .2s ease;
            -o-transition: .2s ease;
            transition: .2s ease;
            -webkit-box-shadow: -3px -3px 14px $color-1,
                -3px -3px 10px $color-1,
                3px 3px 8px rgba(255, 255, 255, .075),
                3px 3px 10px rgba(0, 0, 0, .15);
            box-shadow: -3px -3px 14px $color-1,
                -3px -3px 10px $color-1,
                3px 3px 8px rgba(255, 255, 255, .075),
                3px 3px 10px rgba(0, 0, 0, .15);
        }
    
        .Btn-Jams-Me-Main:hover {
            color: $white;
            background: $color-3;
            transform: scale(1.1);
            -webkit-box-shadow: -2px -2px 6px $color-1,
                -2px -2px 4px $color-1,
                2px 2px 2px rgba(255, 255, 255, .05),
                2px 2px 4px rgba(0, 0, 0, .1);
            box-shadow: -2px -2px 6px $color-1,
                -2px -2px 4px $color-1,
                2px 2px 2px rgba(255, 255, 255, .05),
                2px 2px 4px rgba(0, 0, 0, .1);
            transition: 0.4s ease-in-out;
            -webkit-transition: .4s ease-in-out;
            -o-transition: .4s ease-in-out;
        }
    
        .Btn-Jams-Me-Main:active {
            -webkit-box-shadow: inset -2px -2px 6px $color-1,
                inset -2px -2px 4px $color-1,
                inset 2px 2px 2px rgba(255, 255, 255, .075),
                inset 2px 2px 4px rgba(0, 0, 0, .15);
            box-shadow: inset -2px -2px 6px $color-1,
                inset -2px -2px 4px $color-1,
                inset 2px 2px 2px rgba(255, 255, 255, .075),
                inset 2px 2px 4px rgba(0, 0, 0, .15);
        }
    
        /* END ONGLET JAMS */

}

@media #{$BREAKPOINT-DESK} {

    /*         START Nav Onglet        */
        .container {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-orient: vertical;
            -webkit-box-direction: normal;
            -ms-flex-direction: column;
            flex-direction: column;
            position: relative;
            top: 0rem;
            left: 1rem;
            margin-top: 0.5rem;
            width: 96%;
            height: 100%;
            margin: 100px auto 0;
            word-break: break-all;
        }
    
        .bloc-tabs {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -ms-flex-pack: distribute;
            justify-content: space-around;
            height: 2.5rem;
            width: auto;
        }
    
        .tabs {
            padding: 2px;
            text-align: center;
            width: 20rem;
            color: $color-1;
            cursor: pointer;
            -webkit-box-sizing: content-box;
            box-sizing: content-box;
            position: relative;
            outline: none;
            font-size: 1rem;
        }
    
        .tabs:hover {
            font-size: 1rem;
            width: 20rem;
            border-radius: 50% 0% 0% 0% / 50% 0% 50% 0%;
            -webkit-transform: translateY(-10px);
            -ms-transform: translateY(-10px);
            transform: translateY(-10px);
            -webkit-transition: 0.4s ease;
            -o-transition: 0.4s ease;
            transition: 0.4s ease;
            -webkit-animation-duration: 0.4s ease;
            animation-duration: 0.4s ease;
    
        }
    
        .active-tabs {
            background: $color-3;
            border: 0.1rem solid $color-1;
            width: 4rem;
            font-size: 1.2rem;
            color: $color-2;
            border-radius: 50% 50% 0% 0% / 50% 50% 10% 10%;
            -webkit-transition: 0.4s ease;
            -o-transition: 0.4s ease;
            transition: 0.4s ease;
        }
    
        .active-tabs:hover {
            background: $transparent-3;
            border: 0.1rem solid $color-3;
            width: 4rem;
            font-size: 0.6rem;
            color: $white;
            border-radius: 50% 50% 50% 50% / 50% 50% 20% 20%;
            -webkit-transition: 0.4s ease;
            -o-transition: 0.4s ease;
            transition: 0.4s ease;
        }
    
        .active-tabs::before {
            content: "";
            display: block;
            position: absolute;
            top: -4px;
            left: 50%;
            -webkit-transform: translateX(-50%);
            -ms-transform: translateX(-50%);
            transform: translateX(-50%);
            width: calc(100% + 2px);
            height: 1px;
            border-radius: 50% 0% 50% 0% / 50% 0% 50% 0%;
        }
    
        .content-tabs {
            -webkit-box-flex: 1;
            -ms-flex-positive: 1;
            flex-grow: 1;
        }
    
        /*         END Nav Onglet        */
        /*         START Contenu onglet        */
    
        .content {
            border-left: 0.01rem solid $color-1;
            border-top: 0.01rem solid $color-1;
            padding: 20px;
            width: 100%;
            height: 100%;
            background: $transparent-1;
    
            /*Margin-right de quoi pourquoi /?\*/
            margin-right: 1rem;
            /* */
    
            display: none;
            color: $white;
            background-position: center bottom;
            background-size: 100% auto;
            background-repeat: no-repeat;
            position: relative;
            top: 0rem;
        }
    
        .Title-Onglet {
            position: relative;
            top: 0rem;
            font-size: 1.4rem;
            padding: 0px 0 5px 0px;
        }
    
        .trait1 {
            width: 300px;
            height: 8px;
            margin-bottom: 5px;
            color: $color-1;
            background: $color-1;
        }
    
        .trait2 {
            width: 200px;
            height: 6px;
            margin-bottom: 5px;
            color: $color-3;
            background: $color-3;
        }

        .trait1:hover {
            width: 90rem;
            -webkit-transition: 0.5s ease-in-out;
            -o-transition: 0.5s ease-in-out;
            transition: 0.5s ease-in-out;
        }

        .trait2:hover {
            width: 90rem;
            -webkit-transition: 0.5s ease-in-out;
            -o-transition: 0.5s ease-in-out;
            transition: 0.5s ease-in-out;
        }
    
        .active-content {
            display: block;
            background: $transparent-1;
        }
    
        /* START ONGLET PORTRAIT */
    
        .ImgPortait {
            float: left;
            width: 24rem;
            margin-top: 1rem;
            padding: 1rem;
        }
    
        .pitchPortrait {
            position: relative;
            top: 2rem;
            text-align: left;
            font-size: 1.4rem;
            line-height: 3rem;
            padding: 1rem;
            width: auto;
        }
    
        .pitchPortraitLast {
            position: relative;
            top: 2rem;
            text-align: left;
            font-size: 1.4rem;
            line-height: 3rem;
            padding: 1rem;
            text-align: center;
        }
    
        .pitchBleu {
            color: $color-3;
            font-size: 1.6rem;
        }
    
        .pitchPortraitNb {
            margin-top: 2rem;
            color: #50bbfd;
            font-size: 1.2rem;
            line-height: 2rem;
            position: relative;
            top: 5rem;
            width: auto;
            margin: 0 auto;
        }
    
        .ContentLinkPortrait {
            position: relative;
            top: 2rem;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-orient: horizontal;
            -webkit-box-direction: normal;
            -ms-flex-direction: row;
            flex-direction: row;
            -webkit-box-pack: center;
            -ms-flex-pack: center;
            justify-content: center;
            line-height: 2rem;
        }
    
        .LinkPortrait {
            color: $color-3;
            margin: 1rem;
            font-size: 1.2rem;
        }
    
        .LinkPortrait:hover {
            color: $color-1;
            -webkit-transform: scale(1.1);
            -ms-transform: scale(1.1);
            transform: scale(1.1);
            -webkit-transition: 0.4s ease;
            -o-transition: 0.4s ease;
            transition: 0.4s ease;
            ;
        }
    
        .ThumbSiteSection {
            position: relative;
            top: 8rem;
        }
    
        .siteWeb {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            -ms-flex-pack: distribute;
            -ms-flex-flow: wrap;
            flex-flow: wrap;
            justify-content: space-around;
            position: relative;
            top: 12rem;
            background: $color-2;
        }
    
        .ThumbContent {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-orient: vertical;
            -webkit-box-direction: normal;
            -ms-flex-direction: column;
            flex-direction: column;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            margin-bottom: 5rem;
        }
    
        .ThumbImgSite {
            width: 10rem;
        }
    
        .TitleThumb {
            position: relative;
            top: -12rem;
            font-size: 1.2rem;
            color: $color-3;
            margin-top: 2rem;
            margin-bottom: 1rem;
        }
    
        .ParaThumb {
            position: relative;
            top: -1rem;
            width: auto;
            text-align: left;
            line-height: 2rem;
            margin-bottom: 1rem;
            font-size: 1rem;
        }
    
        .Linkthumb {
            color: $color-3;
            font-size: 1.2rem;
        }
    
        .Linkthumb:hover {
            color: $color-1;
            -webkit-transform: scale(1.1);
            -ms-transform: scale(1.1);
            transform: scale(1.1);
            -webkit-transition: 0.4s ease;
            -o-transition: 0.4s ease;
            transition: 0.4s ease;
        }
    
        .contact-btn {
            position: relative;
            top: 5rem;
        }
    
        .Btn-Contact-Me {
            width: 18rem !important;
            position: relative;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-pack: center;
            -ms-flex-pack: center;
            justify-content: center;
            top: -2rem;
            left: 0rem;
            padding: 1.5em 3em;
            background: #a4cbf2;
            border: none;
            border-radius: 0.5rem;
            color: $color-2;
            font-size: 1rem;
            font-weight: 700;
            letter-spacing: 0.2rem;
            text-align: center;
            margin: 0 Auto;
            outline: none;
            cursor: pointer;
            -webkit-transition: 0.2s ease;
            -o-transition: 0.2s ease;
            transition: 0.2s ease;
            -webkit-box-shadow: -3px -3px 14px $color-3,
                -3px -3px 10px $color-1,
                3px 3px 8px rgba(255, 255, 255, .075),
                3px 3px 1px rgba(0, 0, 0, .15);
            box-shadow: -3px -3px 14px $color-3,
                -3px -3px 10px $color-1,
                3px 3px 8px rgba(255, 255, 255, .075),
                3px 3px 1px rgba(0, 0, 0, .15);
        }
    
        .Btn-Contact-Me:hover {
            color: $white;
            background: $color-3;
            transform: scale(1.1);
            -webkit-box-shadow: -2px -2px 6px $color-1,
                -2px -2px 4px $color-1,
                2px 2px 2px rgba(255, 255, 255, .05),
                2px 2px 4px rgba(0, 0, 0, .1);
            box-shadow: -2px -2px 6px $color-1,
                -2px -2px 4px $color-1,
                2px 2px 2px rgba(255, 255, 255, .05),
                2px 2px 4px rgba(0, 0, 0, .1);
            transition: 0.4s ease-in-out;
            -webkit-transition: .4s ease-in-out;
            -o-transition: .4s ease-in-out;
        }
    
        .Btn-Contact-Me:active {
            -webkit-box-shadow: inset -2px -2px 6px $color-1,
                inset -2px -2px 4px $color-1,
                inset 2px 2px 2px rgba(255, 255, 255, .075),
                inset 2px 2px 4px rgba(0, 0, 0, .15);
            box-shadow: inset -2px -2px 6px $color-1,
                inset -2px -2px 4px $color-1,
                inset 2px 2px 2px rgba(255, 255, 255, .075),
                inset 2px 2px 4px rgba(0, 0, 0, .15);
        }
    
        .Btn-Contact-Me-Main {
            position: relative;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-pack: center;
            -ms-flex-pack: center;
            justify-content: center;
            top: 7rem;
            width: 10rem;
            padding: 1.5em 1em;
            background: $color-1;
            border: none;
            border-radius: .5rem;
            color: $color-2;
            font-size: 1rem;
            font-weight: 700;
            letter-spacing: .2rem;
            text-align: center;
            margin: 0 Auto;
            outline: none;
            cursor: pointer;
            -webkit-transition: .2s ease;
            -o-transition: .2s ease;
            transition: .2s ease;
            -webkit-box-shadow: -3px -3px 14px $color-1,
                -3px -3px 10px $color-1,
                3px 3px 8px rgba(255, 255, 255, .075),
                3px 3px 10px rgba(0, 0, 0, .15);
            box-shadow: -3px -3px 14px $color-1,
                -3px -3px 10px $color-1,
                3px 3px 8px rgba(255, 255, 255, .075),
                3px 3px 10px rgba(0, 0, 0, .15);
        }
    
        .Btn-Contact-Me-Main:hover {
            color: $white;
            background: $color-3;
            transform: scale(1.1);
            -webkit-box-shadow: -2px -2px 6px $color-1,
                -2px -2px 4px $color-1,
                2px 2px 2px rgba(255, 255, 255, .05),
                2px 2px 4px rgba(0, 0, 0, .1);
            box-shadow: -2px -2px 6px $color-1,
                -2px -2px 4px $color-1,
                2px 2px 2px rgba(255, 255, 255, .05),
                2px 2px 4px rgba(0, 0, 0, .1);
            transition: 0.4s ease-in-out;
            -webkit-transition: .4s ease-in-out;
            -o-transition: .4s ease-in-out;
        }
    
        .Btn-Contact-Me-Main:active {
            -webkit-box-shadow: inset -2px -2px 6px $color-1,
                inset -2px -2px 4px $color-1,
                inset 2px 2px 2px rgba(255, 255, 255, .075),
                inset 2px 2px 4px rgba(0, 0, 0, .15);
            box-shadow: inset -2px -2px 6px $color-1,
                inset -2px -2px 4px $color-1,
                inset 2px 2px 2px rgba(255, 255, 255, .075),
                inset 2px 2px 4px rgba(0, 0, 0, .15);
        }
    
    
        /* END ONGLET PORTRAIT */
    
        /* START ONGLET ILLUSTRATIONS */
    
        .Software {
            position: relative;
            top: 4rem;
            list-style-type: none;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-orient: vertical;
            -webkit-box-direction: normal;
            -ms-flex-direction: column;
            flex-direction: column;
            text-align: center;
    
            li {
                margin: 1rem;
            }
        }
    
        .contact-btn-galerie {
            position: relative;
            top: 10rem;
        }
    
        .Btn-Contact-Me-Galerie {
            width: 10rem;
            position: relative;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-pack: center;
            -ms-flex-pack: center;
            justify-content: center;
            top: 0rem;
            left: 0rem;
            padding: 1.5em 1em;
            background: $color-3;
            border: none;
            border-radius: 0.5rem;
            color: $color-2;
            font-size: 1rem;
            font-weight: 700;
            letter-spacing: 0.2rem;
            text-align: center;
            margin: 0 Auto;
            outline: none;
            cursor: pointer;
            -webkit-transition: 0.2s ease;
            -o-transition: 0.2s ease;
            transition: 0.2s ease;
            -webkit-box-shadow: -3px -3px 14px $color-1,
                -3px -3px 10px $color-3,
                3px 3px 8px rgba(255, 255, 255, .075),
                3px 3px 1px rgba(0, 0, 0, .15);
            box-shadow: -3px -3px 14px $color-1,
                -3px -3px 10px $color-3,
                3px 3px 8px rgba(255, 255, 255, .075),
                3px 3px 1px rgba(0, 0, 0, .15);
        }
    
        .Btn-Contact-Me-Galerie:hover {
            color: $white;
            background: $color-1;
            transform: scale(1.1);
            -webkit-box-shadow: -2px -2px 6px $color-3,
                -2px -2px 4px $color-3,
                2px 2px 2px rgba(255, 255, 255, .05),
                2px 2px 4px rgba(0, 0, 0, .1);
            box-shadow: -2px -2px 6px $color-3,
                -2px -2px 4px $color-3,
                2px 2px 2px rgba(255, 255, 255, .05),
                2px 2px 4px rgba(0, 0, 0, .1);
            transition: 0.4s ease-in-out;
            -webkit-transition: .4s ease-in-out;
            -o-transition: .4s ease-in-out;
        }
    
        .Btn-Contact-Me-Galerie:active {
            -webkit-box-shadow: inset -2px -2px 6px $color-3,
                inset -2px -2px 4px $color-3,
                inset 2px 2px 2px rgba(255, 255, 255, .075),
                inset 2px 2px 4px rgba(0, 0, 0, .15);
            box-shadow: inset -2px -2px 6px $color-3,
                inset -2px -2px 4px $color-3,
                inset 2px 2px 2px rgba(255, 255, 255, .075),
                inset 2px 2px 4px rgba(0, 0, 0, .15);
        }
    
        .Btn-Galerie-Me-Main {
            position: relative;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-pack: center;
            -ms-flex-pack: center;
            justify-content: center;
            top: 0rem;
            width: 10rem;
            padding: 1.5em 1em;
            background: $color-1;
            border: none;
            border-radius: .5rem;
            color: $color-2;
            font-size: 1rem;
            font-weight: 700;
            letter-spacing: .2rem;
            text-align: center;
            margin: 0 Auto;
            outline: none;
            cursor: pointer;
            -webkit-transition: .2s ease;
            -o-transition: .2s ease;
            transition: .2s ease;
            -webkit-box-shadow: -3px -3px 14px $color-1,
                -3px -3px 10px $color-1,
                3px 3px 8px rgba(255, 255, 255, .075),
                3px 3px 10px rgba(0, 0, 0, .15);
            box-shadow: -3px -3px 14px $color-1,
                -3px -3px 10px $color-1,
                3px 3px 8px rgba(255, 255, 255, .075),
                3px 3px 10px rgba(0, 0, 0, .15);
        }
    
        .Btn-Galerie-Me-Main:hover {
            color: $white;
            background: $color-3;
            transform: scale(1.1);
            -webkit-box-shadow: -2px -2px 6px $color-1,
                -2px -2px 4px $color-1,
                2px 2px 2px rgba(255, 255, 255, .05),
                2px 2px 4px rgba(0, 0, 0, .1);
            box-shadow: -2px -2px 6px $color-1,
                -2px -2px 4px $color-1,
                2px 2px 2px rgba(255, 255, 255, .05),
                2px 2px 4px rgba(0, 0, 0, .1);
            transition: 0.4s ease-in-out;
            -webkit-transition: .4s ease-in-out;
            -o-transition: .4s ease-in-out;
        }
    
        .Btn-Galerie-Me-Main:active {
            -webkit-box-shadow: inset -2px -2px 6px $color-1,
                inset -2px -2px 4px $color-1,
                inset 2px 2px 2px rgba(255, 255, 255, .075),
                inset 2px 2px 4px rgba(0, 0, 0, .15);
            box-shadow: inset -2px -2px 6px $color-1,
                inset -2px -2px 4px $color-1,
                inset 2px 2px 2px rgba(255, 255, 255, .075),
                inset 2px 2px 4px rgba(0, 0, 0, .15);
        }
    
        /* END ONGLET ILLUSTRATIONS */
    
        /* START ONGLET VIDEOS */
    
        .WhatUseVideos {
            position: relative;
            top: 0rem;
        }
    
        .contact-btn-Videos {
            position: relative;
            top: 10rem;
        }
    
        .Btn-Contact-Me-Videos {
            width: 10rem;
            position: relative;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-pack: center;
            -ms-flex-pack: center;
            justify-content: center;
            top: 0rem;
            left: 0rem;
            padding: 1.5em 1em;
            background: $color-3;
            border: none;
            border-radius: 0.5rem;
            color: $color-2;
            font-size: 1rem;
            font-weight: 700;
            letter-spacing: 0.2rem;
            text-align: center;
            margin: 0 Auto;
            outline: none;
            cursor: pointer;
            -webkit-transition: 0.2s ease;
            -o-transition: 0.2s ease;
            transition: 0.2s ease;
            -webkit-box-shadow: -3px -3px 14px $color-1,
                -3px -3px 10px $color-3,
                3px 3px 8px rgba(255, 255, 255, .075),
                3px 3px 1px rgba(0, 0, 0, .15);
            box-shadow: -3px -3px 14px $color-1,
                -3px -3px 10px $color-3,
                3px 3px 8px rgba(255, 255, 255, .075),
                3px 3px 1px rgba(0, 0, 0, .15);
        }
    
        .Btn-Contact-Me-Videos:hover {
            color: $white;
            background: $color-1;
            transform: scale(1.1);
            -webkit-box-shadow: -2px -2px 6px $color-3,
                -2px -2px 4px $color-3,
                2px 2px 2px rgba(255, 255, 255, .05),
                2px 2px 4px rgba(0, 0, 0, .1);
            box-shadow: -2px -2px 6px $color-3,
                -2px -2px 4px $color-3,
                2px 2px 2px rgba(255, 255, 255, .05),
                2px 2px 4px rgba(0, 0, 0, .1);
            transition: 0.4s ease-in-out;
            -webkit-transition: .4s ease-in-out;
            -o-transition: .4s ease-in-out;
        }
    
        .Btn-Contact-Me-Videos:active {
            -webkit-box-shadow: inset -2px -2px 6px $color-3,
                inset -2px -2px 4px $color-3,
                inset 2px 2px 2px rgba(255, 255, 255, .075),
                inset 2px 2px 4px rgba(0, 0, 0, .15);
            box-shadow: inset -2px -2px 6px $color-3,
                inset -2px -2px 4px $color-3,
                inset 2px 2px 2px rgba(255, 255, 255, .075),
                inset 2px 2px 4px rgba(0, 0, 0, .15);
        }
    
        .Btn-Videos-Me-Main {
            position: relative;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-pack: center;
            -ms-flex-pack: center;
            justify-content: center;
            top: 0rem;
            width: 10rem;
            padding: 1.5em 1em;
            background: $color-1;
            border: none;
            border-radius: .5rem;
            color: $color-2;
            font-size: 1rem;
            font-weight: 700;
            letter-spacing: .2rem;
            text-align: center;
            margin: 0 Auto;
            outline: none;
            cursor: pointer;
            -webkit-transition: .2s ease;
            -o-transition: .2s ease;
            transition: .2s ease;
            -webkit-box-shadow: -3px -3px 14px $color-1,
                -3px -3px 10px $color-1,
                3px 3px 8px rgba(255, 255, 255, .075),
                3px 3px 10px rgba(0, 0, 0, .15);
            box-shadow: -3px -3px 14px $color-1,
                -3px -3px 10px $color-1,
                3px 3px 8px rgba(255, 255, 255, .075),
                3px 3px 10px rgba(0, 0, 0, .15);
        }
    
        .Btn-Videos-Me-Main:hover {
            color: $white;
            background: $color-3;
            transform: scale(1.1);
            -webkit-box-shadow: -2px -2px 6px $color-1,
                -2px -2px 4px $color-1,
                2px 2px 2px rgba(255, 255, 255, .05),
                2px 2px 4px rgba(0, 0, 0, .1);
            box-shadow: -2px -2px 6px $color-1,
                -2px -2px 4px $color-1,
                2px 2px 2px rgba(255, 255, 255, .05),
                2px 2px 4px rgba(0, 0, 0, .1);
            transition: 0.4s ease-in-out;
            -webkit-transition: .4s ease-in-out;
            -o-transition: .4s ease-in-out;
        }
    
        .Btn-Videos-Me-Main:active {
            -webkit-box-shadow: inset -2px -2px 6px $color-1,
                inset -2px -2px 4px $color-1,
                inset 2px 2px 2px rgba(255, 255, 255, .075),
                inset 2px 2px 4px rgba(0, 0, 0, .15);
            box-shadow: inset -2px -2px 6px $color-1,
                inset -2px -2px 4px $color-1,
                inset 2px 2px 2px rgba(255, 255, 255, .075),
                inset 2px 2px 4px rgba(0, 0, 0, .15);
        }
    
    
    
        /* END ONGLET VIDEOS */
    
        /* START ONGLET JAMS */
    
        .overlay__text {
            top: 1rem !important;
            height: 5rem;
        }
    
        .WhatUsejams {
            position: relative;
            top: 1rem;
        }
    
        .pitchSoft {
            position: relative;
            top: 0rem;
        }
    
        .Magix {
            color: $color-3  !important;
        }
    
        .Magix:hover {
            color: $color-1;
            -webkit-transform: scale(1.1);
            -ms-transform: scale(1.1);
            transform: scale(1.1);
            -webkit-transition: 0.4s ease;
            -o-transition: 0.4s ease;
            transition: 0.4s ease;
            ;
        }
    
        .contact-btn-Jams {
            position: relative;
            top: 10rem;
        }
    
        .Btn-Contact-Me-Jams {
            width: 10rem;
            position: relative;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-pack: center;
            -ms-flex-pack: center;
            justify-content: center;
            top: 0rem;
            left: 0rem;
            padding: 1.5em 1em;
            background: $color-3;
            border: none;
            border-radius: 0.5rem;
            color: $color-2;
            font-size: 1rem;
            font-weight: 700;
            letter-spacing: 0.2rem;
            text-align: center;
            margin: 0 Auto;
            outline: none;
            cursor: pointer;
            -webkit-transition: 0.2s ease;
            -o-transition: 0.2s ease;
            transition: 0.2s ease;
            -webkit-box-shadow: -3px -3px 14px $color-1,
                -3px -3px 10px $color-3,
                3px 3px 8px rgba(255, 255, 255, .075),
                3px 3px 1px rgba(0, 0, 0, .15);
            box-shadow: -3px -3px 14px $color-1,
                -3px -3px 10px $color-3,
                3px 3px 8px rgba(255, 255, 255, .075),
                3px 3px 1px rgba(0, 0, 0, .15);
        }
    
        .Btn-Contact-Me-Jams:hover {
            color: $white;
            background: $color-1;
            transform: scale(1.1);
            -webkit-box-shadow: -2px -2px 6px $color-3,
                -2px -2px 4px $color-3,
                2px 2px 2px rgba(255, 255, 255, .05),
                2px 2px 4px rgba(0, 0, 0, .1);
            box-shadow: -2px -2px 6px $color-3,
                -2px -2px 4px $color-3,
                2px 2px 2px rgba(255, 255, 255, .05),
                2px 2px 4px rgba(0, 0, 0, .1);
            transition: 0.4s ease-in-out;
            -webkit-transition: .4s ease-in-out;
            -o-transition: .4s ease-in-out;
        }
    
        .Btn-Contact-Me-Jams:active {
            -webkit-box-shadow: inset -2px -2px 6px $color-3,
                inset -2px -2px 4px $color-3,
                inset 2px 2px 2px rgba(255, 255, 255, .075),
                inset 2px 2px 4px rgba(0, 0, 0, .15);
            box-shadow: inset -2px -2px 6px $color-3,
                inset -2px -2px 4px $color-3,
                inset 2px 2px 2px rgba(255, 255, 255, .075),
                inset 2px 2px 4px rgba(0, 0, 0, .15);
        }
    
        .Btn-Jams-Me-Main {
            position: relative;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-pack: center;
            -ms-flex-pack: center;
            justify-content: center;
            top: 0rem;
            width: 10rem;
            padding: 1.5em 1em;
            background: $color-1;
            border: none;
            border-radius: .5rem;
            color: $color-2;
            font-size: 1rem;
            font-weight: 700;
            letter-spacing: .2rem;
            text-align: center;
            margin: 0 Auto;
            outline: none;
            cursor: pointer;
            -webkit-transition: .2s ease;
            -o-transition: .2s ease;
            transition: .2s ease;
            -webkit-box-shadow: -3px -3px 14px $color-1,
                -3px -3px 10px $color-1,
                3px 63x 8px rgba(255, 255, 255, .075),
                3px 3px 10px rgba(0, 0, 0, .15);
            box-shadow: -3px -3px 14px $color-1,
                -3px -3px 10px $color-1,
                3px 3px 8px rgba(255, 255, 255, .075),
                3px 3px 10px rgba(0, 0, 0, .15);
        }
    
        .Btn-Jams-Me-Main:hover {
            color: $white;
            background: $color-3;
            transform: scale(1.1);
            -webkit-box-shadow: -2px -2px 6px $color-1,
                -2px -2px 4px $color-1,
                2px 2px 2px rgba(255, 255, 255, .05),
                2px 2px 4px rgba(0, 0, 0, .1);
            box-shadow: -2px -2px 6px $color-1,
                -2px -2px 4px $color-1,
                2px 2px 2px rgba(255, 255, 255, .05),
                2px 2px 4px rgba(0, 0, 0, .1);
            transition: 0.4s ease-in-out;
            -webkit-transition: .4s ease-in-out;
            -o-transition: .4s ease-in-out;
        }
    
        .Btn-Jams-Me-Main:active {
            -webkit-box-shadow: inset -2px -2px 6px $color-1,
                inset -2px -2px 4px $color-1,
                inset 2px 2px 2px rgba(255, 255, 255, .075),
                inset 2px 2px 4px rgba(0, 0, 0, .15);
            box-shadow: inset -2px -2px 6px $color-1,
                inset -2px -2px 4px $color-1,
                inset 2px 2px 2px rgba(255, 255, 255, .075),
                inset 2px 2px 4px rgba(0, 0, 0, .15);
        }
    
        /* END ONGLET JAMS */

 }
