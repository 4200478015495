/*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v8.4.14,
* Autoprefixer: v10.4.7
* Browsers: last 4 version
*/

$BREAKPOINT-SMALL-MOBILE-PORT: "only screen and (orientation: portrait) and (min-width: 300px)";
$BREAKPOINT-SMART-MOBILE-PORT: "only screen and (orientation: portrait) and (min-width: 400px)";

$BREAKPOINT-TABLET-PORT: "only screen and (orientation: portrait) and (min-width: 600px)";

$BREAKPOINT-SMALL-MOBILE-LAND: "only screen and (orientation: landscape) and (min-width: 600px) and (max-height: 400px)";
$BREAKPOINT-SMART-MOBILE-LAND: "only screen and (orientation: landscape) and (min-width: 600px) and (max-height: 500px)";
$BREAKPOINT-LARGESMART-MOBILE-LAND: "only screen and (orientation: landscape) and (min-width: 800px) and (max-height: 500px)";

$BREAKPOINT-TABLET-LAND: "only screen and (orientation: landscape) and (min-width: 975px)";
$BREAKPOINT-LAPTOP: "only screen and (min-width: 1200px)";
$BREAKPOINT-DESK: "only screen and (min-width: 1600px)";


*{
    word-break: normal;
}


@media #{$BREAKPOINT-SMALL-MOBILE-PORT} {
        

    #firstSection {
        height: 100%;
        width: 100%;
        position: relative;
        top: 0rem;

    }

    #nextSection {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 100%;
        width: 100%;
        background: $transparent-1;
        top: 20rem;
    }


    .title {
        color: $color-3;
        text-align: center;
        position: relative;
        top: 10rem;
        font-size: 2.4rem;
    }



    .TitleHome1 {
        color: $color-3;
        position: relative;
        font-size: 1.5rem;
        margin-bottom: 2rem;
    }

    .TitleHome2 {
        color: $white;
        position: relative;
        font-size: 1rem;
        margin-bottom: 2rem;
    }
}

@media #{$BREAKPOINT-SMART-MOBILE-PORT} {


    #firstSection {
        height: 100%;
        width: 100%;
        position: relative;
        top: 0rem;

    }

    #nextSection {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 100%;
        width: 100%;
        background: $transparent-1;
        top: 20rem;
    }


    .title {
        color: $color-3;
        text-align: center;
        position: relative;
        top: 10rem;
        font-size: 3rem;
    }



    .TitleHome1 {
        color: $color-3;
        position: relative;
        font-size: 1.5rem;
        margin-bottom: 2rem;
    }

    .TitleHome2 {
        color: $white;
        position: relative;
        font-size: 1rem;
        margin-bottom: 2rem;
    }
}


@media #{$BREAKPOINT-SMALL-MOBILE-LAND} {
    
    .title {
        color: $color-3;
        text-align: center;
        position: relative;
        top: 10rem;
        font-size: 2.6rem;
        line-height: 4rem;
    }
    #firstSection {
        height: 100%;
        width: 100%;
        position: relative;
        top: 0rem;

    }

    #nextSection {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 100%;
        width: 100%;
        background: $transparent-1;
        top: 20rem;
    }
}

@media #{$BREAKPOINT-SMART-MOBILE-LAND} {

    .title {
        color: $color-3;
        text-align: center;
        position: relative;
        top: 10rem;
        font-size: 2.6rem;
        line-height: 4rem;
    }

    #firstSection {
        height: 100%;
        width: 100%;
        position: relative;
        top: 0rem;

    }

    #nextSection {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 100%;
        width: 100%;
        background: $transparent-1;
        top: 20rem;
    }
}

@media #{$BREAKPOINT-LARGESMART-MOBILE-LAND} {

    .title {
        color: $color-3;
        text-align: center;
        position: relative;
        top: 10rem;
        font-size: 2.6rem;
        line-height: 4rem;
    }

    #firstSection {
        height: 100%;
        width: 100%;
        position: relative;
        top: 0rem;

    }

    #nextSection {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 100%;
        width: 100%;
        background: $transparent-1;
        top: 20rem;
    }
}

@media #{$BREAKPOINT-TABLET-PORT} {

    .title {
        color: $color-3;
        text-align: center;
        position: relative;
        top: 12rem;
        font-size: 4rem;
        line-height: 4rem;
    }

    #firstSection {
        height: 100%;
        width: 100%;
        position: relative;
        top: 0rem;

    }

    #nextSection {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 100%;
        width: 100%;
        background: $transparent-1;
        top: 20rem;
    }
}

@media #{$BREAKPOINT-TABLET-LAND} {
    .title {
        color: $color-3;
        text-align: center;
        position: relative;
        top: 12rem;
        font-size: 4rem;
        line-height: 4rem;
    }

    #firstSection {
        height: 100%;
        width: 100%;
        position: relative;
        top: 0rem;

    }

    #nextSection {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 100%;
        width: 100%;
        background: $transparent-1;
        top: 20rem;
    }
}

@media #{$BREAKPOINT-LAPTOP} {
    .title {
        color: $color-3;
        text-align: center;
        position: relative;
        top: 12rem;
        font-size: 8rem;
        line-height: 4rem;
    }
}

@media #{$BREAKPOINT-DESK} {
    .title {
        color: $color-3;
        text-align: center;
        position: relative;
        top: 12rem;
        font-size: 8rem;
        line-height: 4rem;
    }
}