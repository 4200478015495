/*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v8.4.14,
* Autoprefixer: v10.4.7
* Browsers: last 4 version
*/

$BREAKPOINT-DESK: "only screen and (min-width: 1200px)";
$BREAKPOINT-TABLET-LAND: "only screen and (min-width: 975px)";
$BREAKPOINT-TABLET: "only screen and (min-width: 600px)";
$BREAKPOINT-MOBILE-LAND: "only screen and (min-width: 100px)";

@media #{$BREAKPOINT-MOBILE-LAND} {
.table_xp {
        border-bottom: ridge 0.4rem $color-3;
    }

    .grid_title_exp {
        color: $color-3;
        position: relative;
        top: 2rem;
        left: -4rem;
        text-align: center;
    }

    .cursus_exp {
        overflow: auto;
        top: 0rem;
        height: 30rem;
        position: relative;
    }

    .grid_item_exp {
        display: block;
        line-height: 2rem;
        position: relative;
        top: 4rem;
    }

    .grid_mission_exp {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        margin-left: 1rem;
        list-style-type: none;

    }

    .infos_xp {
        position: relative;
        top: 2rem;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-pack: distribute;
        justify-content: space-around;
        margin-bottom: 8rem;
    }

    .grid_date_exp {
        position: relative;
        top: 0rem;
        left: 0rem;
        text-align: left;
    }

    .grid_location_exp {
        position: relative;
        top: 0rem;
        left: 0rem;
        text-align: left;
    }
}
@media #{$BREAKPOINT-TABLET} {
.grid_title_exp {
        left: 0rem;
    }

    .missions_exp {
        margin: 2rem;
        list-style-type: none;
      list-style-type: none;
    }
}

@media #{$BREAKPOINT-DESK} {

.table_xp {
        width: 100%;
        border-bottom: ridge 0.4rem $color-3;
    }

    .cursus_exp {
        overflow: auto;
        height: 26rem;
        top: 22rem;
        color: $color-2;
        font-weight: 500;
        font-size: 1.5rem;
        background-color: $transparent-3;
    }

    .grid_title_exp {
        color: $color-3;
        position: relative;
        top: 2rem;
        left: 0rem;
        text-align: center;
    }

    .grid_item_exp {
        display: block;
        line-height: 2rem;
        position: relative;
        top: 4rem;
    }

    .grid_mission_exp {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;

    }

    .infos_xp {
        position: relative;
        top: 2rem;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-pack: distribute;
        justify-content: space-around;
        margin-bottom: 8rem;
    }

    .grid_date_exp {
        position: relative;
        top: 0rem;
        text-align: left;
    }

    .grid_location_exp {
        position: relative;
        top: 0rem;
        text-align: left;
    }

}