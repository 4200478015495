/*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v8.4.14,
* Autoprefixer: v10.4.7
* Browsers: last 4 version
*/

$BREAKPOINT-SMALL-MOBILE-PORT: "only screen and (orientation: portrait) and (min-width: 300px)";
$BREAKPOINT-SMART-MOBILE-PORT: "only screen and (orientation: portrait) and (min-width: 400px)";

$BREAKPOINT-TABLET-PORT: "only screen and (orientation: portrait) and (min-width: 600px)";

$BREAKPOINT-SMALL-MOBILE-LAND: "only screen and (orientation: landscape) and (min-width: 600px) and (max-height: 400px)";
$BREAKPOINT-SMART-MOBILE-LAND: "only screen and (orientation: landscape) and (min-width: 600px) and (max-height: 500px)";

$BREAKPOINT-TABLET-LAND: "only screen and (orientation: landscape) and (min-width: 975px)";
$BREAKPOINT-LAPTOP: "only screen and (min-width: 1200px)";
$BREAKPOINT-DESK: "only screen and (min-width: 1600px)";

body{
    overflow-x: hidden;
}

li{
    color: $white;
}

td{
    color: $white;
}

h2:hover {
    background: $transparent-1;
}

address:hover {
    background: $transparent-1;
}

.user-private:hover {
    background: $transparent-1;
}

.pitch:hover {
    background: $transparent-1;
}

.grid_title:hover {
    background: $transparent-1;
}

.grid_title_exp:hover {
    background: $transparent-1;
}

.grid_date_exp:hover {
    background: $transparent-1;
}

.grid_location_exp:hover {
    background: $transparent-1;
}

.skill-wallet:hover {
    background: $transparent-1;
}

.grid_date:hover {
    background: $transparent-1;
}

.grid_location:hover {
    background: $transparent-1;
}

.skill-title:hover {
    background: $transparent-1;
}


@media #{$BREAKPOINT-SMALL-MOBILE-PORT} {

    .interestDesktop {
        visibility: hidden;
    }

    .interestMobile {
        visibility: visible;
    }

    .grid-container {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-orient: vertical;
            -webkit-box-direction: normal;
            -ms-flex-direction: column;
            flex-direction: column;
            background-color: $transparent-2;
        }
    
        .sidebar {
            padding: 1.5rem;
            background-color: transparent-2;
            color: $white;
            text-align: center;
            padding: 1.5rem;
            height: 130%;
        }

        .mainCv {
            overflow-x: hidden;
        }
   
}
@media #{$BREAKPOINT-SMART-MOBILE-PORT} {

    .interestDesktop {
        visibility: hidden;
    }

    .interestMobile {
        visibility: visible;
    }

    .grid-container {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        background-color: $transparent-2;
    }

    .sidebar {
        padding: 1.5rem;
        background-color: transparent-2;
        color: $white;
        text-align: center;
        padding: 1.5rem;
        height: 130%;
    }

    .mainCv {
        overflow-x: hidden;
    }

}
@media #{$BREAKPOINT-SMALL-MOBILE-LAND} {

    .interestDesktop {
        visibility: hidden;
    }

    .interestMobile {
        visibility: visible;
    }

    .grid-container {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        background-color: $transparent-2;
    }

    .sidebar {
        padding: 1.5rem;
        background-color: transparent-2;
        color: $white;
        text-align: center;
        padding: 1.5rem;
        height: 130%;
    }

    .mainCv {
        overflow-x: hidden;
    }

}
@media #{$BREAKPOINT-SMART-MOBILE-LAND} {

    .interestDesktop {
        visibility: hidden;
    }

    .interestMobile {
        visibility: visible;
    }

    .grid-container {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        background-color: $transparent-2;
    }

    .sidebar {
        padding: 1.5rem;
        background-color: transparent-2;
        color: $white;
        text-align: center;
        padding: 1.5rem;
        height: 130%;
    }

    .mainCv {
        overflow-x: hidden;
    }

}
@media #{$BREAKPOINT-TABLET-PORT} {

    .interestDesktop {
        visibility: hidden;
    }

    .interestMobile {
        visibility: visible;
    }

    .grid-container {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        background-color: $transparent-2;
    }

    .sidebar {
        padding: 1.5rem;
        background-color: transparent-2;
        color: $white;
        text-align: center;
        padding: 1.5rem;
        height: 130%;
    }

    .mainCv {
        overflow-x: hidden;
    }

}

@media #{$BREAKPOINT-TABLET-LAND} {
    .interestDesktop {
            visibility: hidden;
        }
    .interestMobile {
        visibility: visible;
    }

    .grid-container {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        background-color: $transparent-2;
    }

    .sidebar {
        padding: 1.5rem;
        background-color: transparent-2;
        color: $white;
        text-align: center;
        padding: 1.5rem;
        height: 130%;
    }

    .mainCv {
        overflow-x: hidden;
    }

}
@media #{$BREAKPOINT-LAPTOP} {

    .interestMobile {
            visibility: hidden;
        }

    .interestDesktop {
        visibility: visible;
    }

    .grid-container {
        display: -ms-grid;
        display: grid;
        -ms-grid-columns: 1fr 3fr;
        grid-template-columns: 1fr 3fr;
    }

    .sidebar {
        padding: 1.5rem;
        background-color: $transparent-2;
        padding: 1.5rem;
        height: 100%;
    }

    .mainCv {
        background-color: $transparent-3;
        overflow-x: hidden;
    }
}
@media #{$BREAKPOINT-DESK} {

    .interestMobile{
        visibility: hidden !important;
    }

    .interestDesktop {
        visibility: visible;
    }

    .grid-container {
        display: -ms-grid;
        display: grid;
        -ms-grid-columns: 1fr 3fr;
        grid-template-columns: 1fr 3fr;
    }

    .sidebar {
        padding: 1.5rem;
        background-color: $transparent-2;
        padding: 1.5rem;
        height: 100%;
    }

    .mainCv {
        background-color: $transparent-3;
        overflow-x: hidden;
    }
}
