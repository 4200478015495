/*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v8.4.14,
* Autoprefixer: v10.4.7
* Browsers: last 4 version
*/

$BREAKPOINT-SMALL-MOBILE-PORT: "only screen and (orientation: portrait) and (min-width: 300px)";
$BREAKPOINT-SMART-MOBILE-PORT: "only screen and (orientation: portrait) and (min-width: 400px)";

$BREAKPOINT-TABLET-PORT: "only screen and (orientation: portrait) and (min-width: 600px)";

$BREAKPOINT-SMALL-MOBILE-LAND: "only screen and (orientation: landscape) and (min-width: 600px) and (max-height: 312px)";
$BREAKPOINT-SMART-MOBILE-LAND: "only screen and (orientation: landscape) and (min-width: 600px) and (max-height: 412px)";

$BREAKPOINT-TABLET-LAND: "only screen and (orientation: landscape) and (min-width: 975px)";
$BREAKPOINT-LAPTOP: "only screen and (min-width: 1200px)";
$BREAKPOINT-DESK: "only screen and (min-width: 1600px)";

@media #{$BREAKPOINT-SMALL-MOBILE-PORT} {

.JamsTitle {
    color: $color-3;
}

.jams {
    position: relative;
    top: 25rem;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-line-pack: center;
    align-content: center;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -ms-flex-pack: distribute;
    justify-content: space-around;
}

.jam {
    margin-bottom: 4rem;
}

.jam_title {
    position: relative;
    top: -28rem;
    color: $color-1;
}

.jam_title:hover {
    color: $color-3;
    transform: scale(0.8);
    background-color: $transparent-1;
    border-radius: 0.5rem;
    transition: 0.4s ease;
}

.jam_text {
    position: relative;
    top: -15rem;
    color: $white;
    text-align: center;
    font-size: 1.2rem;
    width: 10rem;
    margin: 0 auto;
}

.jam_text:hover {
    color: $color-3;
    line-height: 3rem;
    border-radius: 0.5rem;
    background: $transparent-1;
    transition: 0.4s ease;
}

.jam_pitch {
    position: relative;
    color: $white;
    top: 10rem;
    text-align: center;
    font-size: 1rem;
    width: 10rem;
    line-height: 2rem;
    margin: 0 auto;
}

.jam_pitch:hover {
    color: $color-3;
    background-color: $transparent-1;
}

//  iframe {
//     width: 30% !important;
//     height: 35% !important;
//     position: relative;
//     display: -webkit-box;
//     display: -ms-flexbox;
//     display: flex;
//     -ms-flex-line-pack: center;
//     align-content: center;
//     margin: 0 auto;
// }

.contact-btn-Page-Jams {
    position: relative;
    top: 8rem;
}

.Btn-Contact-Me-Page-Jams {

    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    top: 7rem;
    width: 10rem;
    padding: 1.5em 1em;
    background: $color-1;
    border: none;
    border-radius: .5rem;
    color: $color-2;
    font-size: 1rem;
    font-weight: 700;
    letter-spacing: .2rem;
    text-align: center;
    margin: 0 Auto;
    outline: none;
    cursor: pointer;
    -webkit-transition: .2s ease;
    -o-transition: .2s ease;
    transition: .2s ease;
    -webkit-box-shadow: -3px -3px 14px $color-1,
        -3px -3px 10px $color-1,
        3px 3px 8px rgba(255, 255, 255, .075),
        3px 3px 10px rgba(0, 0, 0, .15);
    box-shadow: -3px -3px 14px $color-1,
        -3px -3px 10px $color-1,
        3px 3px 8px rgba(255, 255, 255, .075),
        3px 3px 10px rgba(0, 0, 0, .15);
}

.Btn-Contact-Me-Page-Jams:hover {
    color: $white;
    background: $color-3;
    transform: scale(1.1);
    -webkit-box-shadow: -2px -2px 6px $color-1,
        -2px -2px 4px $color-1,
        2px 2px 2px rgba(255, 255, 255, .05),
        2px 2px 4px rgba(0, 0, 0, .1);
    box-shadow: -2px -2px 6px $color-1,
        -2px -2px 4px $color-1,
        2px 2px 2px rgba(255, 255, 255, .05),
        2px 2px 4px rgba(0, 0, 0, .1);
    transition: 0.4s ease-in-out;
    -webkit-transition: 0.4s ease-in-out;
    -o-transition: 0.4s ease-in-out;
}

.Btn-Contact-Me-Page-Jams:active {
    -webkit-box-shadow: inset -2px -2px 6px $color-1,
        inset -2px -2px 4px $color-1,
        inset 2px 2px 2px rgba(255, 255, 255, .075),
        inset 2px 2px 4px rgba(0, 0, 0, .15);
    box-shadow: inset -2px -2px 6px $color-1,
        inset -2px -2px 4px $color-1,
        inset 2px 2px 2px rgba(255, 255, 255, .075),
        inset 2px 2px 4px rgba(0, 0, 0, .15);
    transition: 0.4s ease-in-out;
    -webkit-transition: .4s ease-in-out;
    -o-transition: .4s ease-in-out;
}
.contact-btn-Page-Jams2 {
    position: relative;
    top: 5rem;
}

.Btn-Contact-Me-Page-Jams2 {

    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    top: 7rem;
    width: 10rem;
    padding: 1.5em 1em;
    background: $color-3;
    border: none;
    border-radius: .5rem;
    color: $color-2;
    font-size: 1rem;
    font-weight: 700;
    letter-spacing: .2rem;
    text-align: center;
    margin: 0 Auto;
    outline: none;
    cursor: pointer;
    -webkit-transition: .2s ease;
    -o-transition: .2s ease;
    transition: .2s ease;
    -webkit-box-shadow: -3px -3px 14px $color-1,
        -3px -3px 10px $color-1,
        3px 3px 8px rgba(255, 255, 255, .075),
        3px 3px 10px rgba(0, 0, 0, .15);
    box-shadow: -3px -3px 14px $color-1,
        -3px -3px 10px $color-1,
        3px 3px 8px rgba(255, 255, 255, .075),
        3px 3px 10px rgba(0, 0, 0, .15);
}

.Btn-Contact-Me-Page-Jams2:hover {
    color: $white;
    background: $color-1;
    transform: scale(1.1);
    -webkit-box-shadow: -2px -2px 6px $color-3,
        -2px -2px 4px $color-3,
        2px 2px 2px rgba(255, 255, 255, .05),
        2px 2px 4px rgba(0, 0, 0, .1);
    box-shadow: -2px -2px 6px $color-3,
        -2px -2px 4px $color-3,
        2px 2px 2px rgba(255, 255, 255, .05),
        2px 2px 4px rgba(0, 0, 0, .1);
    transition: 0.4s ease-in-out;
    -webkit-transition: 0.4s ease-in-out;
    -o-transition: 0.4s ease-in-out;
}

.Btn-Contact-Me-Page-Jams2:active {
    -webkit-box-shadow: inset -2px -2px 6px $color-3,
        inset -2px -2px 4px $color-3,
        inset 2px 2px 2px rgba(255, 255, 255, .075),
        inset 2px 2px 4px rgba(0, 0, 0, .15);
    box-shadow: inset -2px -2px 6px $color-3,
        inset -2px -2px 4px $color-3,
        inset 2px 2px 2px rgba(255, 255, 255, .075),
        inset 2px 2px 4px rgba(0, 0, 0, .15);
    transition: 0.4s ease-in-out;
    -webkit-transition: .4s ease-in-out;
    -o-transition: .4s ease-in-out;
}

}

@media #{$BREAKPOINT-SMART-MOBILE-PORT} {

    .JamsTitle {
        color: $color-3;
    }

    .jams {
        position: relative;
        top: 25rem;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-line-pack: center;
        align-content: center;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        -ms-flex-pack: distribute;
        justify-content: space-around;
    }

    .jam {
        margin-bottom: 4rem;
    }

    .jam_title {
        position: relative;
        top: -28rem;
        color: $color-1;
    }

    .jam_title:hover {
        color: $color-3;
        transform: scale(0.8);
        background-color: $transparent-1;
        border-radius: 0.5rem;
        transition: 0.4s ease;
    }

    .jam_text {
        position: relative;
        top: -15rem;
        color: $white;
        text-align: center;
        font-size: 1.2rem;
        width: 20rem;
        margin: 0 auto;
    }

    .jam_text:hover {
        color: $color-3;
        line-height: 3rem;
        border-radius: 0.5rem;
        background: $transparent-1;
        transition: 0.4s ease;
    }

    .jam_pitch {
        position: relative;
        color: $white;
        top: 10rem;
        text-align: center;
        font-size: 1rem;
        width: 20rem;
        line-height: 2rem;
        margin: 0 auto;
    }

    .jam_pitch:hover {
        color: $color-3;
        background-color: $transparent-1;
    }

    // iframe {
    //     width: 50% !important;
    //     height: 35% !important;
    //     position: relative;
    //     display: -webkit-box;
    //     display: -ms-flexbox;
    //     display: flex;
    //     -ms-flex-line-pack: center;
    //     align-content: center;
    //     margin: 0 auto;
    // }

    .contact-btn-Page-Jams {
        position: relative;
        top: 8rem;
    }

    .Btn-Contact-Me-Page-Jams {

        position: relative;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        top: 7rem;
        width: 10rem;
        padding: 1.5em 1em;
        background: $color-1;
        border: none;
        border-radius: .5rem;
        color: $color-2;
        font-size: 1rem;
        font-weight: 700;
        letter-spacing: .2rem;
        text-align: center;
        margin: 0 Auto;
        outline: none;
        cursor: pointer;
        -webkit-transition: .2s ease;
        -o-transition: .2s ease;
        transition: .2s ease;
        -webkit-box-shadow: -3px -3px 14px $color-1,
            -3px -3px 10px $color-1,
            3px 3px 8px rgba(255, 255, 255, .075),
            3px 3px 10px rgba(0, 0, 0, .15);
        box-shadow: -3px -3px 14px $color-1,
            -3px -3px 10px $color-1,
            3px 3px 8px rgba(255, 255, 255, .075),
            3px 3px 10px rgba(0, 0, 0, .15);
    }

    .Btn-Contact-Me-Page-Jams:hover {
        color: $white;
        background: $color-3;
        transform: scale(1.1);
        -webkit-box-shadow: -2px -2px 6px $color-1,
            -2px -2px 4px $color-1,
            2px 2px 2px rgba(255, 255, 255, .05),
            2px 2px 4px rgba(0, 0, 0, .1);
        box-shadow: -2px -2px 6px $color-1,
            -2px -2px 4px $color-1,
            2px 2px 2px rgba(255, 255, 255, .05),
            2px 2px 4px rgba(0, 0, 0, .1);
        transition: 0.4s ease-in-out;
        -webkit-transition: 0.4s ease-in-out;
        -o-transition: 0.4s ease-in-out;
    }

    .Btn-Contact-Me-Page-Jams:active {
        -webkit-box-shadow: inset -2px -2px 6px $color-1,
            inset -2px -2px 4px $color-1,
            inset 2px 2px 2px rgba(255, 255, 255, .075),
            inset 2px 2px 4px rgba(0, 0, 0, .15);
        box-shadow: inset -2px -2px 6px $color-1,
            inset -2px -2px 4px $color-1,
            inset 2px 2px 2px rgba(255, 255, 255, .075),
            inset 2px 2px 4px rgba(0, 0, 0, .15);
        transition: 0.4s ease-in-out;
        -webkit-transition: .4s ease-in-out;
        -o-transition: .4s ease-in-out;
    }

    .contact-btn-Page-Jams2 {
        position: relative;
        top: 5rem;
    }

    .Btn-Contact-Me-Page-Jams2 {

        position: relative;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        top: 7rem;
        width: 10rem;
        padding: 1.5em 1em;
        background: $color-3;
        border: none;
        border-radius: .5rem;
        color: $color-2;
        font-size: 1rem;
        font-weight: 700;
        letter-spacing: .2rem;
        text-align: center;
        margin: 0 Auto;
        outline: none;
        cursor: pointer;
        -webkit-transition: .2s ease;
        -o-transition: .2s ease;
        transition: .2s ease;
        -webkit-box-shadow: -3px -3px 14px $color-1,
            -3px -3px 10px $color-1,
            3px 3px 8px rgba(255, 255, 255, .075),
            3px 3px 10px rgba(0, 0, 0, .15);
        box-shadow: -3px -3px 14px $color-1,
            -3px -3px 10px $color-1,
            3px 3px 8px rgba(255, 255, 255, .075),
            3px 3px 10px rgba(0, 0, 0, .15);
    }

    .Btn-Contact-Me-Page-Jams2:hover {
        color: $white;
        background: $color-1;
        transform: scale(1.1);
        -webkit-box-shadow: -2px -2px 6px $color-3,
            -2px -2px 4px $color-3,
            2px 2px 2px rgba(255, 255, 255, .05),
            2px 2px 4px rgba(0, 0, 0, .1);
        box-shadow: -2px -2px 6px $color-3,
            -2px -2px 4px $color-3,
            2px 2px 2px rgba(255, 255, 255, .05),
            2px 2px 4px rgba(0, 0, 0, .1);
        transition: 0.4s ease-in-out;
        -webkit-transition: 0.4s ease-in-out;
        -o-transition: 0.4s ease-in-out;
    }

    .Btn-Contact-Me-Page-Jams2:active {
        -webkit-box-shadow: inset -2px -2px 6px $color-3,
            inset -2px -2px 4px $color-3,
            inset 2px 2px 2px rgba(255, 255, 255, .075),
            inset 2px 2px 4px rgba(0, 0, 0, .15);
        box-shadow: inset -2px -2px 6px $color-3,
            inset -2px -2px 4px $color-3,
            inset 2px 2px 2px rgba(255, 255, 255, .075),
            inset 2px 2px 4px rgba(0, 0, 0, .15);
        transition: 0.4s ease-in-out;
        -webkit-transition: .4s ease-in-out;
        -o-transition: .4s ease-in-out;
    }

}

@media #{$BREAKPOINT-SMART-MOBILE-LAND} {

    .JamsTitle {
        color: $color-3;
    }

    .jams {
        position: relative;
        top: 25rem;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-line-pack: center;
        align-content: center;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        -ms-flex-pack: distribute;
        justify-content: space-around;
    }

    .jam {
        margin-bottom: 4rem;
    }

    .jam_title {
        position: relative;
        top: -28rem;
        color: $color-1;
    }

    .jam_title:hover {
        color: $color-3;
        transform: scale(0.8);
        background-color: $transparent-1;
        border-radius: 0.5rem;
        transition: 0.4s ease;
    }

    .jam_text {
        position: relative;
        top: -15rem;
        color: $white;
        text-align: center;
        font-size: 1.2rem;
        width: 20rem;
        margin: 0 auto;
    }

    .jam_text:hover {
        color: $color-3;
        line-height: 3rem;
        border-radius: 0.5rem;
        background: $transparent-1;
        transition: 0.4s ease;
    }

    .jam_pitch {
        position: relative;
        color: $white;
        top: 10rem;
        text-align: center;
        font-size: 1rem;
        width: 20rem;
        line-height: 2rem;
        margin: 0 auto;
    }

    .jam_pitch:hover {
        color: $color-3;
        background-color: $transparent-1;
    }

    // iframe {
    //     width: 50% !important;
    //     height: 35% !important;
    //     position: relative;
    //     display: -webkit-box;
    //     display: -ms-flexbox;
    //     display: flex;
    //     -ms-flex-line-pack: center;
    //     align-content: center;
    //     margin: 0 auto;
    // }

    .contact-btn-Page-Jams {
        position: relative;
        top: 8rem;
    }

    .Btn-Contact-Me-Page-Jams {

        position: relative;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        top: 7rem;
        width: 10rem;
        padding: 1.5em 1em;
        background: $color-1;
        border: none;
        border-radius: .5rem;
        color: $color-2;
        font-size: 1rem;
        font-weight: 700;
        letter-spacing: .2rem;
        text-align: center;
        margin: 0 Auto;
        outline: none;
        cursor: pointer;
        -webkit-transition: .2s ease;
        -o-transition: .2s ease;
        transition: .2s ease;
        -webkit-box-shadow: -3px -3px 14px $color-1,
            -3px -3px 10px $color-1,
            3px 3px 8px rgba(255, 255, 255, .075),
            3px 3px 10px rgba(0, 0, 0, .15);
        box-shadow: -3px -3px 14px $color-1,
            -3px -3px 10px $color-1,
            3px 3px 8px rgba(255, 255, 255, .075),
            3px 3px 10px rgba(0, 0, 0, .15);
    }

    .Btn-Contact-Me-Page-Jams:hover {
        color: $white;
        background: $color-3;
        transform: scale(1.1);
        -webkit-box-shadow: -2px -2px 6px $color-1,
            -2px -2px 4px $color-1,
            2px 2px 2px rgba(255, 255, 255, .05),
            2px 2px 4px rgba(0, 0, 0, .1);
        box-shadow: -2px -2px 6px $color-1,
            -2px -2px 4px $color-1,
            2px 2px 2px rgba(255, 255, 255, .05),
            2px 2px 4px rgba(0, 0, 0, .1);
        transition: 0.4s ease-in-out;
        -webkit-transition: 0.4s ease-in-out;
        -o-transition: 0.4s ease-in-out;
    }

    .Btn-Contact-Me-Page-Jams:active {
        -webkit-box-shadow: inset -2px -2px 6px $color-1,
            inset -2px -2px 4px $color-1,
            inset 2px 2px 2px rgba(255, 255, 255, .075),
            inset 2px 2px 4px rgba(0, 0, 0, .15);
        box-shadow: inset -2px -2px 6px $color-1,
            inset -2px -2px 4px $color-1,
            inset 2px 2px 2px rgba(255, 255, 255, .075),
            inset 2px 2px 4px rgba(0, 0, 0, .15);
        transition: 0.4s ease-in-out;
        -webkit-transition: .4s ease-in-out;
        -o-transition: .4s ease-in-out;
    }

    .contact-btn-Page-Jams2 {
        position: relative;
        top: 5rem;
    }

    .Btn-Contact-Me-Page-Jams2 {

        position: relative;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        top: 7rem;
        width: 10rem;
        padding: 1.5em 1em;
        background: $color-3;
        border: none;
        border-radius: .5rem;
        color: $color-2;
        font-size: 1rem;
        font-weight: 700;
        letter-spacing: .2rem;
        text-align: center;
        margin: 0 Auto;
        outline: none;
        cursor: pointer;
        -webkit-transition: .2s ease;
        -o-transition: .2s ease;
        transition: .2s ease;
        -webkit-box-shadow: -3px -3px 14px $color-1,
            -3px -3px 10px $color-1,
            3px 3px 8px rgba(255, 255, 255, .075),
            3px 3px 10px rgba(0, 0, 0, .15);
        box-shadow: -3px -3px 14px $color-1,
            -3px -3px 10px $color-1,
            3px 3px 8px rgba(255, 255, 255, .075),
            3px 3px 10px rgba(0, 0, 0, .15);
    }

    .Btn-Contact-Me-Page-Jams2:hover {
        color: $white;
        background: $color-1;
        transform: scale(1.1);
        -webkit-box-shadow: -2px -2px 6px $color-3,
            -2px -2px 4px $color-3,
            2px 2px 2px rgba(255, 255, 255, .05),
            2px 2px 4px rgba(0, 0, 0, .1);
        box-shadow: -2px -2px 6px $color-3,
            -2px -2px 4px $color-3,
            2px 2px 2px rgba(255, 255, 255, .05),
            2px 2px 4px rgba(0, 0, 0, .1);
        transition: 0.4s ease-in-out;
        -webkit-transition: 0.4s ease-in-out;
        -o-transition: 0.4s ease-in-out;
    }

    .Btn-Contact-Me-Page-Jams2:active {
        -webkit-box-shadow: inset -2px -2px 6px $color-3,
            inset -2px -2px 4px $color-3,
            inset 2px 2px 2px rgba(255, 255, 255, .075),
            inset 2px 2px 4px rgba(0, 0, 0, .15);
        box-shadow: inset -2px -2px 6px $color-3,
            inset -2px -2px 4px $color-3,
            inset 2px 2px 2px rgba(255, 255, 255, .075),
            inset 2px 2px 4px rgba(0, 0, 0, .15);
        transition: 0.4s ease-in-out;
        -webkit-transition: .4s ease-in-out;
        -o-transition: .4s ease-in-out;
    }

}

@media #{$BREAKPOINT-SMALL-MOBILE-LAND} {

    .JamsTitle {
        color: $color-3;
    }

    .jams {
        position: relative;
        top: 25rem;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-line-pack: center;
        align-content: center;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        -ms-flex-pack: distribute;
        justify-content: space-around;
    }

    .jam {
        margin-bottom: 4rem;
    }

    .jam_title {
        position: relative;
        top: -28rem;
        color: $color-1;
    }

    .jam_title:hover {
        color: $color-3;
        transform: scale(0.8);
        background-color: $transparent-1;
        border-radius: 0.5rem;
        transition: 0.4s ease;
    }

    .jam_text {
        position: relative;
        top: -15rem;
        color: $white;
        text-align: center;
        font-size: 1.2rem;
        width: 20rem;
        margin: 0 auto;
    }

    .jam_text:hover {
        color: $color-3;
        line-height: 3rem;
        border-radius: 0.5rem;
        background: $transparent-1;
        transition: 0.4s ease;
    }

    .jam_pitch {
        position: relative;
        color: $white;
        top: 10rem;
        text-align: center;
        font-size: 1rem;
        width: 20rem;
        line-height: 2rem;
        margin: 0 auto;
    }

    .jam_pitch:hover {
        color: $color-3;
        background-color: $transparent-1;
    }

    // iframe {
    //     width: 50% !important;
    //     height: 35% !important;
    //     position: relative;
    //     display: -webkit-box;
    //     display: -ms-flexbox;
    //     display: flex;
    //     -ms-flex-line-pack: center;
    //     align-content: center;
    //     margin: 0 auto;
    // }

    .contact-btn-Page-Jams {
        position: relative;
        top: 8rem;
    }

    .Btn-Contact-Me-Page-Jams {

        position: relative;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        top: 7rem;
        width: 10rem;
        padding: 1.5em 1em;
        background: $color-1;
        border: none;
        border-radius: .5rem;
        color: $color-2;
        font-size: 1rem;
        font-weight: 700;
        letter-spacing: .2rem;
        text-align: center;
        margin: 0 Auto;
        outline: none;
        cursor: pointer;
        -webkit-transition: .2s ease;
        -o-transition: .2s ease;
        transition: .2s ease;
        -webkit-box-shadow: -3px -3px 14px $color-1,
            -3px -3px 10px $color-1,
            3px 3px 8px rgba(255, 255, 255, .075),
            3px 3px 10px rgba(0, 0, 0, .15);
        box-shadow: -3px -3px 14px $color-1,
            -3px -3px 10px $color-1,
            3px 3px 8px rgba(255, 255, 255, .075),
            3px 3px 10px rgba(0, 0, 0, .15);
    }

    .Btn-Contact-Me-Page-Jams:hover {
        color: $white;
        background: $color-3;
        transform: scale(1.1);
        -webkit-box-shadow: -2px -2px 6px $color-1,
            -2px -2px 4px $color-1,
            2px 2px 2px rgba(255, 255, 255, .05),
            2px 2px 4px rgba(0, 0, 0, .1);
        box-shadow: -2px -2px 6px $color-1,
            -2px -2px 4px $color-1,
            2px 2px 2px rgba(255, 255, 255, .05),
            2px 2px 4px rgba(0, 0, 0, .1);
        transition: 0.4s ease-in-out;
        -webkit-transition: 0.4s ease-in-out;
        -o-transition: 0.4s ease-in-out;
    }

    .Btn-Contact-Me-Page-Jams:active {
        -webkit-box-shadow: inset -2px -2px 6px $color-1,
            inset -2px -2px 4px $color-1,
            inset 2px 2px 2px rgba(255, 255, 255, .075),
            inset 2px 2px 4px rgba(0, 0, 0, .15);
        box-shadow: inset -2px -2px 6px $color-1,
            inset -2px -2px 4px $color-1,
            inset 2px 2px 2px rgba(255, 255, 255, .075),
            inset 2px 2px 4px rgba(0, 0, 0, .15);
        transition: 0.4s ease-in-out;
        -webkit-transition: .4s ease-in-out;
        -o-transition: .4s ease-in-out;
    }

    .contact-btn-Page-Jams2 {
        position: relative;
        top: 5rem;
    }

    .Btn-Contact-Me-Page-Jams2 {

        position: relative;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        top: 7rem;
        width: 10rem;
        padding: 1.5em 1em;
        background: $color-3;
        border: none;
        border-radius: .5rem;
        color: $color-2;
        font-size: 1rem;
        font-weight: 700;
        letter-spacing: .2rem;
        text-align: center;
        margin: 0 Auto;
        outline: none;
        cursor: pointer;
        -webkit-transition: .2s ease;
        -o-transition: .2s ease;
        transition: .2s ease;
        -webkit-box-shadow: -3px -3px 14px $color-1,
            -3px -3px 10px $color-1,
            3px 3px 8px rgba(255, 255, 255, .075),
            3px 3px 10px rgba(0, 0, 0, .15);
        box-shadow: -3px -3px 14px $color-1,
            -3px -3px 10px $color-1,
            3px 3px 8px rgba(255, 255, 255, .075),
            3px 3px 10px rgba(0, 0, 0, .15);
    }

    .Btn-Contact-Me-Page-Jams2:hover {
        color: $white;
        background: $color-1;
        transform: scale(1.1);
        -webkit-box-shadow: -2px -2px 6px $color-3,
            -2px -2px 4px $color-3,
            2px 2px 2px rgba(255, 255, 255, .05),
            2px 2px 4px rgba(0, 0, 0, .1);
        box-shadow: -2px -2px 6px $color-3,
            -2px -2px 4px $color-3,
            2px 2px 2px rgba(255, 255, 255, .05),
            2px 2px 4px rgba(0, 0, 0, .1);
        transition: 0.4s ease-in-out;
        -webkit-transition: 0.4s ease-in-out;
        -o-transition: 0.4s ease-in-out;
    }

    .Btn-Contact-Me-Page-Jams2:active {
        -webkit-box-shadow: inset -2px -2px 6px $color-3,
            inset -2px -2px 4px $color-3,
            inset 2px 2px 2px rgba(255, 255, 255, .075),
            inset 2px 2px 4px rgba(0, 0, 0, .15);
        box-shadow: inset -2px -2px 6px $color-3,
            inset -2px -2px 4px $color-3,
            inset 2px 2px 2px rgba(255, 255, 255, .075),
            inset 2px 2px 4px rgba(0, 0, 0, .15);
        transition: 0.4s ease-in-out;
        -webkit-transition: .4s ease-in-out;
        -o-transition: .4s ease-in-out;
    }

}

@media #{$BREAKPOINT-TABLET-PORT} {

    .JamsTitle {
        color: $color-3;
    }

    .jams {
        position: relative;
        top: 25rem;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-line-pack: center;
        align-content: center;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        -ms-flex-pack: distribute;
        justify-content: space-around;
    }

    .jam {
        margin-bottom: 2rem;
    }

    .jam_title {
        position: relative;
        top: -26rem;
        color: $color-1;
    }

    .jam_title:hover {
        color: $color-3;
        transform: scale(0.8);
        background-color: $transparent-1;
        border-radius: 0.5rem;
        transition: 0.4s ease;
        -webkit-transition: 0.4s ease;
        -o-transition: 0.4s ease;
    }

    .jam_text {
        position: relative;
        top: -15rem;
        color: $white;
        text-align: center;
        font-size: 1.2rem;
        width: 20rem;
        margin: 0 auto;
    }

    .jam_text:hover {
        color: $color-3;
        line-height: 3rem;
        border-radius: 0.5rem;
        background: $transparent-1;
        transition: 0.4s ease;
        -webkit-transition: 0.4s ease;
        -o-transition: 0.4s ease;

    }

    .jam_pitch {
        position: relative;
        color: $white;
        top: 10rem;
        text-align: center;
        font-size: 1rem;
        width: 20rem;
        line-height: 2rem;
        margin: 0 auto;
    }

    .jam_pitch:hover {
        color: $color-3;
        background-color: $transparent-1;
    }

    // iframe {
    //     width: 60% !important;
    //     height: 35% !important;
    //     position: relative;
    //     display: -webkit-box;
    //     display: -ms-flexbox;
    //     display: flex;
    //     -ms-flex-line-pack: center;
    //     align-content: center;
    //     margin: 0 auto;
    // }

    .contact-btn-Page-Jams {
        position: relative;
        top: 8rem;
    }

    .Btn-Contact-Me-Page-Jams {

        position: relative;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        top: 7rem;
        width: 10rem;
        padding: 1.5em 1em;
        background: $color-1;
        border: none;
        border-radius: .5rem;
        color: $color-2;
        font-size: 1rem;
        font-weight: 700;
        letter-spacing: .2rem;
        text-align: center;
        margin: 0 Auto;
        outline: none;
        cursor: pointer;
        -webkit-transition: .2s ease;
        -o-transition: .2s ease;
        transition: .2s ease;
        -webkit-box-shadow: -3px -3px 14px $color-3,
            -3px 3px 10px $color-3,
            3px 3px 8px rgba(255, 255, 255, .075),
            3px 3px 10px rgba(0, 0, 0, .15);
        box-shadow: -3px -3px 14px $color-3,
            -3px -3px 8px $color-3,
            3px 3px 8px rgba(255, 255, 255, .075),
            3px 3px 10px rgba(0, 0, 0, .15);
    }

    .Btn-Contact-Me-Page-Jams:hover {
        color: $white;
        background: $color-3;
        transform: scale(1.1);
        -webkit-box-shadow: -2px -2px 14px $color-1,
            2px -2px 10px $color-1,
        2px 2px 8px rgba(255, 255, 255, .075),
            2px 2px 10px rgba(0, 0, 0, .15);
        box-shadow: -2px -2px 14px $color-1,
            -2px -2px 8px $color-1,
            2px 2px 8px rgba(255, 255, 255, .075),
            2px 2px 10px rgba(0, 0, 0, .15);
        transition: 0.4s ease-in-out;
        -webkit-transition: .4s ease-in-out;
        -o-transition: .4s ease-in-out;
    }

    .Btn-Contact-Me-Page-Jams:active {
        -webkit-box-shadow: -2px -2px 14px $color-1,
                -2px -2px 10px $color-1,
                2px 2px 8px rgba(255, 255, 255, .075),
                2px 2px 10px rgba(0, 0, 0, .15);
            box-shadow: -2px -2px 14px $color-1,
                -2px -2px 8px $color-1,
                2px 2px 8px rgba(255, 255, 255, .075),
                2px 2px 10px rgba(0, 0, 0, .15);
            transition: 0.4s ease-in-out;
            -webkit-transition: .4s ease-in-out;
            -o-transition: .4s ease-in-out;
    }

    .contact-btn-Page-Jams2 {
        position: relative;
        top: -18rem;
    }

    .Btn-Contact-Me-Page-Jams2 {

        position: relative;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        top: 7rem;
        width: 10rem;
        padding: 1.5em 1em;
        background: $color-3;
        border: none;
        border-radius: .5rem;
        color: $color-2;
        font-size: 1rem;
        font-weight: 700;
        letter-spacing: .2rem;
        text-align: center;
        margin: 0 Auto;
        outline: none;
        cursor: pointer;
        -webkit-transition: .2s ease;
        -o-transition: .2s ease;
        transition: .2s ease;
        -webkit-box-shadow: -5px -5px 14px $color-1,
            5px 5px 10px $color-1,
            5px 5px 8px rgba(255, 255, 255, .075),
            5px 5px 10px rgba(0, 0, 0, .15);
        box-shadow: -5px -5px 14px $color-1,
            -5px -5px 10px $color-1,
            5px 5px 8px rgba(255, 255, 255, .075),
            5px 5px 10px rgba(0, 0, 0, .15);
    }

    .Btn-Contact-Me-Page-Jams2:hover {
        color: $white;
        background: $color-1;
        transform: scale(1.1);
        -webkit-box-shadow: -2px -2px 6px $color-3,
            -2px -2px 4px $color-3,
            2px 2px 2px rgba(255, 255, 255, .05),
            2px 2px 4px rgba(0, 0, 0, .1);
        box-shadow: -2px -2px 6px $color-3,
            -2px -2px 4px $color-3,
            2px 2px 2px rgba(255, 255, 255, .05),
            2px 2px 4px rgba(0, 0, 0, .1);
        transition: 0.4s ease-in-out;
        -webkit-transition: .4s ease-in-out;
        -o-transition: .4s ease-in-out;
    }

    .Btn-Contact-Me-Page-Jams2:active {
        -webkit-box-shadow: inset -2px -2px 6px $color-3,
            inset -2px -2px 4px $color-3,
            inset 2px 2px 2px rgba(255, 255, 255, .075),
            inset 2px 2px 4px rgba(0, 0, 0, .15);
        box-shadow: inset -2px -2px 6px $color-3,
            inset -2px -2px 4px $color-3,
            inset 2px 2px 2px rgba(255, 255, 255, .075),
            inset 2px 2px 4px rgba(0, 0, 0, .15);
        transition: 0.4s ease-in-out;
        -webkit-transition: .4s ease-in-out;
        -o-transition: .4s ease-in-out;
    }

}

@media #{$BREAKPOINT-TABLET-LAND} {

    .JamsTitle{
        color: $color-3;
    }

    .jams {
        position: relative;
        top: 25rem;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-line-pack: center;
        align-content: center;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        -ms-flex-pack: distribute;
        justify-content: space-around;
    }

    .jam {
        margin-bottom: 2rem;
    }

    .jam_title {
        position: relative;
        top: -26rem;
        color: $color-1;
    }

    .jam_title:hover {
        color: $color-3;
        transform: scale(0.8);
        background-color: $transparent-1;
        border-radius: 0.5rem;
        transition: 0.4s ease;
        -webkit-transition: 0.4s ease;
        -o-transition: 0.4s ease;
    }

    .jam_text {
        position: relative;
        top: -10rem;
        color: $white;
        text-align: center;
        font-size: 1.2rem;
        width: 40rem;
        margin: 0 auto;
    }

    .jam_text:hover {
        color: $color-3;
        line-height: 3rem;
        border-radius: 0.5rem;
        background: $transparent-1;
        transition: 0.4s ease;
        -webkit-transition: 0.4s ease;
        -o-transition: 0.4s ease;
    }

    .jam_pitch {
        position: relative;
        color: $white;
        top: 10rem;
        text-align: center;
        font-size: 1rem;
        width: 40rem;
        line-height: 2rem;
        margin: 0 auto;
    }

    .jam_pitch:hover {
        color: $color-3;
        background-color: $transparent-1;
    }

    // iframe {
    //     width: 80% !important;
    //     height: 60% !important;
    //     position: relative;
    //     display: -webkit-box;
    //     display: -ms-flexbox;
    //     display: flex;
    //     -ms-flex-line-pack: center;
    //     align-content: center;
    //     margin: 0 auto;
    // }

    .contact-btn-Page-Jams {
        position: relative;
        top: 8rem;
    }

    .Btn-Contact-Me-Page-Jams {

        position: relative;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        top: 7rem;
        width: 10rem;
        padding: 1.5em 1em;
        background: $color-1;
        border: none;
        border-radius: .5rem;
        color: $color-2;
        font-size: 1rem;
        font-weight: 700;
        letter-spacing: .2rem;
        text-align: center;
        margin: 0 Auto;
        outline: none;
        cursor: pointer;
        -webkit-transition: .2s ease;
        -o-transition: .2s ease;
        transition: .2s ease;
        -webkit-box-shadow: -3px -3px 14px $color-3,
            -3px 3px 10px $color-3,
            3px 3px 8px rgba(255, 255, 255, .075),
            3px 3px 10px rgba(0, 0, 0, .15);
        box-shadow: -3px -3px 14px $color-3,
            -3px -3px 8px $color-3,
            3px 3px 8px rgba(255, 255, 255, .075),
            3px 3px 10px rgba(0, 0, 0, .15);
    }

    .Btn-Contact-Me-Page-Jams:hover {
        color: $white;
        background: $color-3;
        transform: scale(1.1);
        -webkit-box-shadow: -2px -2px 14px $color-1,
            2px -2px 10px $color-1,
            2px 2px 8px rgba(255, 255, 255, .075),
            2px 2px 10px rgba(0, 0, 0, .15);
        box-shadow: -2px -2px 14px $color-1,
            -2px -2px 8px $color-1,
            2px 2px 8px rgba(255, 255, 255, .075),
            2px 2px 10px rgba(0, 0, 0, .15);
        transition: 0.4s ease-in-out;
        -webkit-transition: .4s ease-in-out;
        -o-transition: .4s ease-in-out;
    }

    .Btn-Contact-Me-Page-Jams:active {
        -webkit-box-shadow: -2px -2px 14px $color-1,
            -2px -2px 10px $color-1,
            2px 2px 8px rgba(255, 255, 255, .075),
            2px 2px 10px rgba(0, 0, 0, .15);
        box-shadow: -2px -2px 14px $color-1,
            -2px -2px 8px $color-1,
            2px 2px 8px rgba(255, 255, 255, .075),
            2px 2px 10px rgba(0, 0, 0, .15);
        transition: 0.4s ease-in-out;
        -webkit-transition: .4s ease-in-out;
        -o-transition: .4s ease-in-out;
    }

    .contact-btn-Page-Jams2 {
        position: relative;
        top: 10rem;
    }

    .Btn-Contact-Me-Page-Jams2 {

        position: relative;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        top: 10rem;
        width: 10rem;
        padding: 1.5em 1em;
        background: $color-3;
        border: none;
        border-radius: .5rem;
        color: $color-2;
        font-size: 1rem;
        font-weight: 700;
        letter-spacing: .2rem;
        text-align: center;
        margin: 0 Auto;
        outline: none;
        cursor: pointer;
        -webkit-transition: .2s ease;
        -o-transition: .2s ease;
        transition: .2s ease;
        -webkit-box-shadow: -5px -5px 14px $color-1,
            5px 5px 10px $color-1,
            5px 5px 8px rgba(255, 255, 255, .075),
            5px 5px 10px rgba(0, 0, 0, .15);
        box-shadow: -5px -5px 14px $color-1,
            -5px -5px 10px $color-1,
            5px 5px 8px rgba(255, 255, 255, .075),
            5px 5px 10px rgba(0, 0, 0, .15);
    }

    .Btn-Contact-Me-Page-Jams2:hover {
        color: $white;
        background: $color-1;
        transform: scale(1.1);
        -webkit-box-shadow: -2px -2px 6px $color-3,
            -2px -2px 4px $color-3,
            2px 2px 2px rgba(255, 255, 255, .05),
            2px 2px 4px rgba(0, 0, 0, .1);
        box-shadow: -2px -2px 6px $color-3,
            -2px -2px 4px $color-3,
            2px 2px 2px rgba(255, 255, 255, .05),
            2px 2px 4px rgba(0, 0, 0, .1);
        transition: 0.4s ease-in-out;
        -webkit-transition: .4s ease-in-out;
        -o-transition: .4s ease-in-out;
    }

    .Btn-Contact-Me-Page-Jams2:active {
        -webkit-box-shadow: inset -2px -2px 6px $color-3,
            inset -2px -2px 4px $color-3,
            inset 2px 2px 2px rgba(255, 255, 255, .075),
            inset 2px 2px 4px rgba(0, 0, 0, .15);
        box-shadow: inset -2px -2px 6px $color-3,
            inset -2px -2px 4px $color-3,
            inset 2px 2px 2px rgba(255, 255, 255, .075),
            inset 2px 2px 4px rgba(0, 0, 0, .15);
        transition: 0.4s ease-in-out;
        -webkit-transition: .4s ease-in-out;
        -o-transition: .4s ease-in-out;
    }

}

@media #{$BREAKPOINT-DESK} {

    .JamsTitle {
        color: $color-3;
    }

    .jams {
        position: relative;
        top: 25rem;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-line-pack: center;
        align-content: center;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        -ms-flex-pack: distribute;
        justify-content: space-around;
    }

    .jam {
        margin-bottom: 10rem;
    }

    .jam_title {
        position: relative;
        top: -26rem;
        color: $color-1;
    }

    .jam_title:hover {
        color: $color-3;
        transform: scale(0.8);
        background-color: $transparent-1;
        border-radius: 0.5rem;
        transition: 0.4s ease;
    }

    .jam_text {
        position: relative;
        top: -4rem;
        color: $white;
        text-align: center;
        font-size: 1.2rem;
        width: 20rem;
        margin: 0 auto;
    }

    .jam_text:hover {
        color: $color-3;
        line-height: 3rem;
        border-radius: 0.5rem;
        background: $transparent-1;
        transition: 0.4s ease;
        -webkit-transition: 0.4s ease;
        -o-transition: 0.4s ease;
    }

    .jam_pitch {
        position: relative;
        color: $white;
        top: 10rem;
        text-align: center;
        font-size: 1rem;
        width: 40rem;
        line-height: 2rem;
        margin: 0 auto;
    }

    .jam_pitch:hover {
        color: $color-3;
        background-color: $transparent-1;
        transition: 0.4s ease;
        -webkit-transition: 0.4s ease;
        -o-transition: 0.4s ease;
    }

    //  {
    //     width: 90% !important;
    //     height: 80% !important;
    //     position: relative;
    //     display: -webkit-box;
    //     display: -ms-flexbox;
    //     display: flex;
    //     -ms-flex-line-pack: center;
    //     aligiframen-content: center;
    //     margin: 0 auto;
    // }

    .contact-btn-Page-Jams {
        position: relative;
        top: 8rem;
    }

    .Btn-Contact-Me-Page-Jams {

        position: relative;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        top: 7rem;
        width: 10rem;
        padding: 1.5em 1em;
        background: $color-1;
        border: none;
        border-radius: .5rem;
        color: $color-2;
        font-size: 1rem;
        font-weight: 700;
        letter-spacing: .2rem;
        text-align: center;
        margin: 0 Auto;
        outline: none;
        cursor: pointer;
        -webkit-transition: .2s ease;
        -o-transition: .2s ease;
        transition: .2s ease;
        -webkit-box-shadow: -3px -3px 14px $color-3,
            -3px 3px 10px $color-3,
            3px 3px 8px rgba(255, 255, 255, .075),
            3px 3px 10px rgba(0, 0, 0, .15);
        box-shadow: -3px -3px 14px $color-3,
            -3px -3px 8px $color-3,
            3px 3px 8px rgba(255, 255, 255, .075),
            3px 3px 10px rgba(0, 0, 0, .15);
    }

    .Btn-Contact-Me-Page-Jams:hover {
        color: $white;
        background: $color-3;
        transform: scale(1.1);
        -webkit-box-shadow: -2px -2px 14px $color-1,
            2px -2px 10px $color-1,
            2px 2px 8px rgba(255, 255, 255, .075),
            2px 2px 10px rgba(0, 0, 0, .15);
        box-shadow: -2px -2px 14px $color-1,
            -2px -2px 8px $color-1,
            2px 2px 8px rgba(255, 255, 255, .075),
            2px 2px 10px rgba(0, 0, 0, .15);
        transition: 0.4s ease-in-out;
        -webkit-transition: .4s ease-in-out;
        -o-transition: .4s ease-in-out;
    }

    .Btn-Contact-Me-Page-Jams:active {
        -webkit-box-shadow: -2px -2px 14px $color-1,
            -2px -2px 10px $color-1,
            2px 2px 8px rgba(255, 255, 255, .075),
            2px 2px 10px rgba(0, 0, 0, .15);
        box-shadow: -2px -2px 14px $color-1,
            -2px -2px 8px $color-1,
            2px 2px 8px rgba(255, 255, 255, .075),
            2px 2px 10px rgba(0, 0, 0, .15);
        transition: 0.4s ease;
        -webkit-transition: .4s ease-in-out;
        -o-transition: .4s ease-in-out;
    }

    .contact-btn-Page-Jams2 {
        position: relative;
        top: 10rem;
    }

    .Btn-Contact-Me-Page-Jams2 {

        position: relative;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        top: 8rem;
        width: 10rem;
        padding: 1.5em 1em;
        background: $color-3;
        border: none;
        border-radius: .5rem;
        color: $color-2;
        font-size: 1rem;
        font-weight: 700;
        letter-spacing: .2rem;
        text-align: center;
        margin: 0 Auto;
        outline: none;
        cursor: pointer;
        -webkit-transition: .2s ease;
        -o-transition: .2s ease;
        transition: .2s ease;
        -webkit-box-shadow: -5px -5px 14px $color-1,
            5px 5px 10px $color-1,
            5px 5px 8px rgba(255, 255, 255, .075),
            5px 5px 10px rgba(0, 0, 0, .15);
        box-shadow: -5px -5px 14px $color-1,
            -5px -5px 10px $color-1,
            5px 5px 8px rgba(255, 255, 255, .075),
            5px 5px 10px rgba(0, 0, 0, .15);
    }

    .Btn-Contact-Me-Page-Jams2:hover {
        color: $white;
        background: $color-1;
        transform: scale(1.1);
        -webkit-box-shadow: -2px -2px 6px $color-3,
            -2px -2px 4px $color-3,
            2px 2px 2px rgba(255, 255, 255, .05),
            2px 2px 4px rgba(0, 0, 0, .1);
        box-shadow: -2px -2px 6px $color-3,
            -2px -2px 4px $color-3,
            2px 2px 2px rgba(255, 255, 255, .05),
            2px 2px 4px rgba(0, 0, 0, .1);
        transition: 0.4s ease-in-out;
        -webkit-transition: .4s ease-in-out;
        -o-transition: .4s ease-in-out;
    }

    .Btn-Contact-Me-Page-Jams2:active {
        -webkit-box-shadow: inset -2px -2px 6px $color-3,
            inset -2px -2px 4px $color-3,
            inset 2px 2px 2px rgba(255, 255, 255, .075),
            inset 2px 2px 4px rgba(0, 0, 0, .15);
        box-shadow: inset -2px -2px 6px $color-3,
            inset -2px -2px 4px $color-3,
            inset 2px 2px 2px rgba(255, 255, 255, .075),
            inset 2px 2px 4px rgba(0, 0, 0, .15);
        transition: 0.4s ease-in-out;
        -webkit-transition: .4s ease-in-out;
        -o-transition: .4s ease-in-out;
    }

}