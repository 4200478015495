/*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v8.4.14,
* Autoprefixer: v10.4.7
* Browsers: last 4 version
*/

$BREAKPOINT-MOBILE-LAND: "only screen and (min-width: 200px)";
$BREAKPOINT-TABLET: "only screen and (min-width: 600px)";
$BREAKPOINT-TABLET-LAND: "only screen and (min-width: 975px)";
$BREAKPOINT-DESK: "only screen and (min-width: 1280px)";

@media #{$BREAKPOINT-MOBILE-LAND} {
    .FootCont {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-orient: vertical;
            -webkit-box-direction: normal;
            -ms-flex-direction: column;
            flex-direction: column;
            -ms-flex-line-pack: distribute;
            align-content: space-around;
            position: fixed;
            bottom: 3rem;
            left: 75%;
            z-index: 10000;
        }

    .FootIco {
        width: 2rem;
        height: 2rem;
        margin-bottom: 1rem;

    }

    .FootIco:hover {
        -webkit-box-shadow: 0px 0px 50px 5px #50BBFD;
        box-shadow: 0px 0px 50px 5px #50BBFD;
        background-color: $transparent-2;
        -webkit-transform: translateX(-50%);
        -ms-transform: translateX(-50%);
        transform: translateX(-50%);
        -webkit-transition-duration: 0.4ms;
        -o-transition-duration: 0.4ms;
        transition-duration: 0.4ms;
        border-radius: 100%;
    }
}

@media #{$BREAKPOINT-TABLET} {
    .FootCont {
        bottom: 3rem;
        left: 85%;
    }
}

@media #{$BREAKPOINT-TABLET-LAND} {
    .FootCont {
        bottom: 3rem;
        left: 90%;
    }
}

@media #{$BREAKPOINT-DESK} {
    .FootCont {
        bottom: 10rem;
        left: 95%;
    }
}