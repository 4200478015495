
@font-face {
    font-family: "font-1";
    src: url(../assets/fonts/KdamThmorPro-Regular.ttf);
    font-family: "font-2";
    src: url(../assets/fonts/whola.ttf);
}

$color-1: #a4cbf2;
$color-2: rgb(1, 1, 1);
$color-3: #409FFF;  // #50bbfd;
$white: rgb(243, 243, 243);

$transparent: #00ff0000;
$transparent-1: #0b0b0ba2;
$transparent-2: #50bbfd32;
$transparent-3: #a4cbf23a;

$font-1: "font-1", sans-serif;
$font-2: "font-2", sans-serif;

*{
    box-sizing: border-box;
    font-family: "font-2";
    margin: 0px;
    padding: 0px;
}
body{
    background: url(../assets/img/TerreLuneBlender.png);
    background-attachment: fixed;
    background-position: center top;
    background-size: cover;
    background-repeat: no-repeat;
    height: 100vh;
}

::-webkit-scrollbar {
    width: 5px;
}

::-webkit-scrollbar-track {
    background: $color-1;
}

::-webkit-scrollbar-thumb {
    background: $color-3;
    border-radius: 50px;
}

h1{
    font-family: $font-2;
    text-align: center;
}

h2 {
    font-family: $font-2;
    text-align: center;
}

h3 {
    font-family: $font-2;
    text-align: center;
}
h4 {
    font-family: $font-2;
    text-align: center;
}
p {
    font-family: $font-2;
    white-space: pre-line;
}

.copyright {
    color: $white;
    position: fixed;
    bottom: 0;
    text-align: center;
    margin: 0 auto;
    left: 45%;
}






