/*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v8.4.14,
* Autoprefixer: v10.4.7
* Browsers: last 4 version
*/

$BREAKPOINT-SMALL-MOBILE-PORT: "only screen and (orientation: portrait) and (min-width: 200px)";
$BREAKPOINT-SMART-MOBILE-PORT: "only screen and (orientation: portrait) and (min-width: 400px)";


$BREAKPOINT-TABLET-PORT: "only screen and (orientation: portrait) and (min-width: 600px)";

$BREAKPOINT-SMALL-MOBILE-LAND: "only screen and (orientation: landscape) and (min-width: 600px) and (max-height: 400px)";
$BREAKPOINT-SMART-MOBILE-LAND: "only screen and (orientation: landscape) and (min-width: 600px) and (max-height: 500px)";

$BREAKPOINT-TABLET-LAND: "only screen and (orientation: landscape) and (min-width: 975px)";
$BREAKPOINT-LAPTOP: "only screen and (min-width: 1200px)";
$BREAKPOINT-DESK: "only screen and (min-width: 1600px)";

@media #{$BREAKPOINT-SMALL-MOBILE-PORT} {
    .interests-bg {
            position: relative;
            top: 2rem;
            background-color: transparent;
            width: 129%;
            height: 20rem;
            left: -3rem;
            overflow-x: hidden;
        }
    
        .interets {
            background-color: $transparent-3;
            position: relative;
            height: 15rem;
            top: 3rem;
        }
    
        .InterestTitle {
            position: relative;
            top: -2rem;
            left: 0rem;
        }
    
        .interet {
            position: relative;
            top: 1rem;
            left: 3rem;
            text-align: left;
            color: $color-2;
            font-weight: 600;
            line-height: 2rem;
            overflow: auto;
            height: 10rem;
        }
}

@media #{$BREAKPOINT-SMART-MOBILE-PORT} {
    .interests-bg {
        position: relative;
        top: 2rem;
        background-color: transparent;
        width: 120%;
        height: 20rem;
        left: -3rem;
        overflow-x: hidden;
    }

    .interets {
        background-color: $transparent-3;
        position: relative;
        height: 15rem;
        top: 3rem;
    }

    .InterestTitle {
        position: relative;
        top: -2rem;
        left: 0rem;
    }

    .interet {
        position: relative;
        top: 0rem;
        left: 6rem;
        text-align: left;
        color: $color-2;
        font-weight: 600;
        line-height: 2rem;
        overflow: auto;
        height: 10rem;
    }
}

@media #{$BREAKPOINT-SMART-MOBILE-LAND} {
    .interests-bg {
        position: relative;
        top: 2rem;
        background-color: transparent;
        width: 120%;
        height: 20rem;
        left: -3rem;
        overflow-x: hidden;
    }

    .interets {
        background-color: $transparent-3;
        position: relative;
        height: 15rem;
        top: 3rem;
    }

    .InterestTitle {
        position: relative;
        top: -2rem;
        left: 0rem;
    }

    .interet {
        position: relative;
        top: 0rem;
        left: 5rem;
        text-align: left;
        color: $color-2;
        font-weight: 600;
        line-height: 2rem;
        overflow: auto;
        height: 10rem;
    }
}

@media #{$BREAKPOINT-SMALL-MOBILE-LAND} {
    .interests-bg {
        position: relative;
        top: 2rem;
        background-color: transparent;
        width: 109%;
        height: 20rem;
        left: -3rem;
        overflow-x: hidden;
    }

    .interets {
        background-color: $transparent-3;
        position: relative;
        height: 15rem;
        top: 3rem;
    }

    .InterestTitle {
        position: relative;
        top: -2rem;
        left: 0rem;
    }

    .interet {
        position: relative;
        top: 0rem;
        left: 8rem;
        text-align: left;
        color: $color-2;
        font-weight: 600;
        line-height: 2rem;
        overflow: auto;
        height: 10rem;
    }
}

@media #{$BREAKPOINT-TABLET-PORT} {
    .interests-bg {
        position: relative;
        top: 3rem;
        background-color: transparent;
        width: 113%;
        height: 20rem;
        left: -3rem;
        overflow-x: hidden;
    }

    .interets {
        background-color: $transparent-3;
        position: relative;
        height: 15rem;
        top: 3rem;
    }

    .InterestTitle {
        position: relative;
        top: -2rem;
        left: 0rem;
    }

    .interet {
        position: relative;
        top: 0rem;
        left: 4rem;
        text-align: left;
        color: $color-2;
        font-weight: 600;
        line-height: 2rem;
        overflow: auto;
        height: 10rem;
    }
}

@media #{$BREAKPOINT-TABLET-LAND} {
    .interests-bg {
        position: relative;
        top: 20rem;
        background-color: $transparent-2;
        width: 108%;
        height: 20rem;
        left: -3rem;
        overflow-x: hidden;
    }

    .interets {
        background-color: $transparent-3;
        position: relative;
        height: 15rem;
        top: 3rem;
    }

    .InterestTitle {
        position: relative;
        top: -2rem;
        left: 0rem;
    }

    .interet {
        position: relative;
        top: 1rem;
        left: 3rem;
        text-align: left;
        color: $color-2;
        font-weight: 600;
        line-height: 2rem;
        overflow: auto;
        height: 10rem;
    }
}

@media #{$BREAKPOINT-LAPTOP} {

    .interests-bg {
        position: relative;
        top: -5rem;
        background-color: transparent;
        width: 118.5%;
        height: 20rem;
        left: -2rem;
    }

    .interets {
        background-color: $transparent-3;
        position: relative;
        height: 15rem;
        top: 3rem;
    }

    .InterestTitle {
        position: relative;
        top: -2rem;
        left: 0rem;
    }

    .interet {
        position: relative;
        top: 1rem;
        left: 2rem;
        text-align: left;
        color: $color-2;
        font-weight: 600;
        line-height: 2rem;
        overflow: auto;
        height: 8rem;
    }
}

@media #{$BREAKPOINT-DESK} {
    .interests-bg {
        position: relative;
        top: 2rem;
        background-color: transparent;
        width: 114%;
        height: 20rem;
        left: -2rem;
    }

    .interets {
        background-color: $transparent-3;
        position: relative;
        height: 15rem;
        top: 3rem;
    }

    .InterestTitle {
        position: relative;
        top: -2rem;
        left: 0rem;
    }

    .interet {
        position: relative;
        top: 1rem;
        left: 4rem;
        text-align: left;
        color: $color-2;
        font-weight: 600;
        line-height: 2rem;
        overflow: auto;
        height: 8rem;
    }
}


