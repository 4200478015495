/*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v8.4.14,
* Autoprefixer: v10.4.7
* Browsers: last 4 version
*/

.SliderOngletJams {
    cursor: grab;
    display: flex;
    overflow-x: scroll;
    overflow-y: hidden;
    scroll-snap-type: none;
    padding: 0rem;
    height: 660px;
    scroll-padding: 2rem;
    gap: 20px 0px;

}

.SliderOngletJams .overlay {
    scroll-snap-align: center;
}

.JamsLink {
    margin-left: 1rem;
    height: 100%;
    width: 50%;
}