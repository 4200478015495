/*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v8.4.14,
* Autoprefixer: v10.4.7
* Browsers: last 4 version
*/

$BREAKPOINT-SMALL-MOBILE-PORT: "only screen and (orientation: portrait) and (min-width: 300px)";
$BREAKPOINT-SMART-MOBILE-PORT: "only screen and (orientation: portrait) and (min-width: 400px)";

$BREAKPOINT-TABLET-PORT: "only screen and (orientation: portrait) and (min-width: 600px)";

$BREAKPOINT-SMALL-MOBILE-LAND: "only screen and (orientation: landscape) and (min-width: 600px) and (max-height: 400px)";
$BREAKPOINT-SMART-MOBILE-LAND: "only screen and (orientation: landscape) and (min-width: 600px) and (max-height: 500px)";

$BREAKPOINT-TABLET-LAND: "only screen and (orientation: landscape) and (min-width: 975px)";
$BREAKPOINT-LAPTOP: "only screen and (min-width: 1200px)";
$BREAKPOINT-DESK: "only screen and (min-width: 1600px)";


/*           Small Mobile Portrait Orientation           */

@media #{$BREAKPOINT-SMALL-MOBILE-PORT} {
    .logo-container {
        position: relative;
        top: 0.5rem;
        left: -0.6rem;
        background-color: $transparent-1;
        width: 9rem;
        height: 8rem;
        cursor: pointer;
        border-radius: 100% 100% 100% 50%/0% 100% 130% 80%;
        border-top: 0.4rem groove #50BBFD;
        border-left: 0.8rem groove #50BBFD;
        border-right: 0.2rem groove #50BBFD;
        -webkit-box-shadow: 0px 50px 50px -5px #50BBFD;
        box-shadow: 0px 20px 50px 5px #50BBFD;
        z-index: 997;
    }

    .logo-container:hover {
        transform: scale(1.1);
        -webkit-transition: all .2s ease-in-out;
        -o-transition: all .2s ease-in-out;
        transition: all .2s ease-in-out;
    }

    .logo {
        position: relative;
        top: 1rem;
        left: 0.6rem;
        width: 6rem;
        z-index: 1000;
    }

    .logo:hover {
        transform: scale(1.1) !important;
        top: 1rem;
        -webkit-transition: all .4s ease;
        -o-transition: all .4s ease;
        transition: all .4s ease;
    }
}

/*           Smartphone Portrait Orientation           */

@media #{$BREAKPOINT-SMART-MOBILE-PORT} {
    .logo-container {
    position: relative;
    top: 0rem;
    left: -0.6rem;
    background-color: $transparent-1;
    width: 9rem;
    height: 8rem;
    cursor: pointer;
    border-radius: 100% 100% 100% 50%/0% 100% 130% 80%;
    border-top: 0.4rem groove #50BBFD;
    border-left: 0.8rem groove #50BBFD;
    border-right: 0.2rem groove #50BBFD;
    -webkit-box-shadow: 0px 50px 50px -5px #50BBFD;
    box-shadow: 0px 20px 50px 5px #50BBFD;
    z-index: 997;
    }

    .logo-container:hover {
        transform: scale(1.1);
        -webkit-transition: all .2s ease-in-out;
        -o-transition: all .2s ease-in-out;
        transition: all .2s ease-in-out;
    }

    .logo {
        position: relative;
        top: 1rem;
        left: 0.6rem;
        width: 6rem;
        z-index: 1000;
    }

    .logo:hover {
        transform: scale(1.1) !important;
        top: 1rem;
        -webkit-transition: all .4s ease;
        -o-transition: all .4s ease;
        transition: all .4s ease;
    }
}

/*           Mobile Smartphone Landscape Orientation           */

@media #{$BREAKPOINT-SMART-MOBILE-LAND} {

    .logo-container {
            position: relative;
            top: 0rem;
            left: -0.6rem;
            background-color: $transparent-1;
            width: 10rem;
            height: 9rem;
            cursor: pointer;
            border-radius: 100% 100% 100% 50%/0% 100% 130% 80%;
            border-top: 0.4rem groove #50BBFD;
            border-left: 1rem groove #50BBFD;
            border-right: 0.2rem groove #50BBFD;
            -webkit-box-shadow: 0px 50px 50px -5px #50BBFD;
            box-shadow: 0px 20px 50px 5px #50BBFD;
            z-index: 997;
        }

        .logo-container:hover {
            transform: scale(1.1) !important;
            -webkit-transition: 0.4s ease-out;
            -o-transition: 0.4s ease-out;
            transition: 0.4s ease-out;
        }
    
        .logo {
            position: relative;
            top: 1.5rem;
            left: 0.8rem;
            width: 6rem;
            z-index: 1000;
        }
    
        .logo:hover {
            top: 1rem;
            transform: scale(1.1) !important;
            -webkit-transition: 0.4s ease-out;
            -o-transition: 0.4s ease-out;
            transition: 0.4s ease-out;
        }

}

/*           Small Mobile Landscape Orientation           */

@media #{$BREAKPOINT-SMALL-MOBILE-LAND} {

    .logo-container {
        position: relative;
        top: 0rem;
        left: -0.6rem;
        background-color: $transparent-1;
        width: 8.5rem;
        height: 7.5rem;
        cursor: pointer;
        border-radius: 100% 100% 100% 50%/0% 100% 130% 80%;
        border-top: 0.4rem groove #50BBFD;
        border-left: 1rem groove #50BBFD;
        border-right: 0.2rem groove #50BBFD;
        -webkit-box-shadow: 0px 50px 50px -5px #50BBFD;
        box-shadow: 0px 20px 50px 5px #50BBFD;
        z-index: 997;
    }

    .logo-container:hover {
        transform: scale(1.1) !important;
        -webkit-transition: 0.4s ease-out;
        -o-transition: 0.4s ease-out;
        transition: 0.4s ease-out;
    }

    .logo {
        position: relative;
        top: 1rem;
        left: 0.5rem;
        width: 5.5rem;
        z-index: 1000;
    }

    .logo:hover {
        top: 1rem;
        transform: scale(1.1) !important;
        -webkit-transition: 0.4s ease-out;
        -o-transition: 0.4s ease-out;
        transition: 0.4s ease-out;
    }

}

/*           Tablet Portrait Orientation           */

@media #{$BREAKPOINT-TABLET-PORT} {
    .logo-container {
        position: relative;
        top: 0.5rem;
        left: -0.6rem;
        background-color: $transparent-1;
        width: 11rem;
        height: 10rem;
        cursor: pointer;
        border-radius: 100% 100% 100% 50%/0% 100% 130% 80%;
        border-top: 0.4rem groove #50BBFD;
        border-left: 0.8rem groove #50BBFD;
        border-right: 0.2rem groove #50BBFD;
        -webkit-box-shadow: 0px 50px 50px -5px #50BBFD;
        box-shadow: 0px 20px 50px 5px #50BBFD;
        z-index: 997;
    }

    .logo-container:hover {
        transform: scale(1.1);
        -webkit-transition: all .2s ease-in-out;
        -o-transition: all .2s ease-in-out;
        transition: all .2s ease-in-out;
    }

    .logo {
        position: relative ;
        top: 1rem;
        left: 0.6rem;
        width: 8rem;
        z-index: 1000;
    }

    .logo:hover {
        transform: scale(1.1) !important;
        top: 1rem;
        -webkit-transition: all .4s ease;
        -o-transition: all .4s ease;
        transition: all .4s ease;
    }
}

/*           Tablet Landscape Orientation           */

@media #{$BREAKPOINT-TABLET-LAND} {
    .logo-container {
        position: relative;
        top: 0rem;
        left: 0rem;
        background-color: $transparent-1;
        width: 9rem;
        height: 8rem;
        cursor: pointer;
        border-radius: 100% 100% 100% 50%/0% 100% 130% 80%;
        border-top: 0.4rem groove #50BBFD;
        border-left: 0.8rem groove #50BBFD;
        border-right: 0.2rem groove #50BBFD;
        -webkit-box-shadow: 0px 50px 50px -5px #50BBFD;
        box-shadow: 0px 20px 50px 5px #50BBFD;
        z-index: 997;
    }

    .logo-container:hover {
        transform: scale(1.1);
        -webkit-transition: all .2s ease-in-out;
        -o-transition: all .2s ease-in-out;
        transition: all .2s ease-in-out;
    }

    .logo {
        position: relative;
        top: 2rem;
        left: 0.6rem;
        width: 6rem;
        z-index: 1000;
    }

    .logo:hover {
        transform: scale(1.1) !important;
        top: 1rem;
        -webkit-transition: all .4s ease;
        -o-transition: all .4s ease;
        transition: all .4s ease;
    }
}

/*           Laptop         */

@media #{$BREAKPOINT-LAPTOP} {
    .logo-container {
        position: relative;
        top: 0rem;
        left: 0rem;
        background-color: $transparent-1;
        width: 11.5rem;
        height: 10rem;
        cursor: pointer;
        border-radius: 100% 100% 100% 50%/0% 100% 130% 80%;
        border-top: 0.4rem groove #50BBFD;
        border-left: 0.8rem groove #50BBFD;
        border-right: 0.2rem groove #50BBFD;
        -webkit-box-shadow: 0px 50px 50px -5px #50BBFD;
        box-shadow: 0px 20px 50px 5px #50BBFD;
        z-index: 997;
    }

    .logo {
        position: relative;
        top: 1rem;
        left: 0.8rem;
        width: 8rem;
        z-index: 1000;
    }

    .logo:hover {
        width: 8.2rem;
        -webkit-transition: all .2s ease-in-out;
        -o-transition: all .2s ease-in-out;
        transition: all .2s ease-in-out;
    }
}

/*           Desk         */

@media #{$BREAKPOINT-DESK} {
    .logo-container {
        position: relative;
        top: 0rem;
        left: 0rem;
        background-color: $transparent-1;
        width: 11.5rem;
        height: 10rem;
        cursor: pointer;
        border-radius: 100% 100% 100% 50%/0% 100% 130% 80%;
        border-top: 0.4rem groove #50BBFD;
        border-left: 0.8rem groove #50BBFD;
        border-right: 0.2rem groove #50BBFD;
        -webkit-box-shadow: 0px 50px 50px -5px #50BBFD;
        box-shadow: 0px 20px 50px 5px #50BBFD;
        z-index: 997;
    }

    .logo {
        position: relative;
        top: 1rem;
        left: 0.8rem;
        width: 8rem;
        z-index: 1000;
    }

    .logo:hover {
        width: 8.2rem;
        -webkit-transition: all .2s ease-in-out;
        -o-transition: all .2s ease-in-out;
        transition: all .2s ease-in-out;
    }
}